import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {LanguageService} from "./demo/service/language.service";
import {AuthenticationService} from './layout/service/app.auth.service';
import {environment as env} from '../environments/environment';
import {SwPush} from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})


export class AppComponent implements OnInit {

    isAuth0Loading$ = this.auth.authService.isLoading$;
    private VAPID_PUBLIC_KEY: string = "BHf7aj7i-XIjwSd8Z2ny8AJ5NCxLna1Z6pOkRVz8pruyHIpY5j82O5SOwc2M-BkRcdjBYNKDXerts5tkldAOtZ8";

    constructor(
        private primengConfig: PrimeNGConfig,
        languageService: LanguageService,
        public auth: AuthenticationService,
        private swPush: SwPush
        //private interceptor:AuthInterceptor
    ) {
        languageService.init();
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.auth.authService.getAccessTokenSilently().subscribe(token => {

            localStorage.setItem('accessToken', token);

            // validates if the auth0 user is also registered in the backend
            this.auth.handleAuthCallback();

            const {fetch: originalFetch} = window;
            window.fetch = async (...args) => {
                let [resource, config] = args;
                if (env.httpInterceptor.allowedList.some(pattern => new RegExp(pattern).test(resource.toString()))) {
                    config = {...config, headers: {...config?.headers, Authorization: 'Bearer ' + token}};
                }

                const response = await originalFetch(resource, config);
                return response;
            }
        })
        this.subscribeToNotifications();
    }

    subscribeToNotifications() {
        this.swPush.messages.subscribe(message => {
            console.log(message)
        })
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
            .then(sub => {
                console.log(sub)
            })
            .catch(err => console.error("Could not subscribe to notifications", err));
    }

}
