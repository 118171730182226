/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppointmentCreator } from './appointmentCreator';


export interface AppointmentDto { 
    appointmentId: string;
    modelId: string;
    type: string;
    statusEnum: AppointmentDto.StatusEnumEnum;
    status: string;
    typeEnum: AppointmentDto.TypeEnumEnum;
    title: string;
    description: string;
    from: string;
    to: string;
    createdAt: string;
    projectId?: string;
    creator?: AppointmentCreator;
    answeredAt?: string;
    agencyId?: string;
}
export namespace AppointmentDto {
    export type StatusEnumEnum = 'REQUESTED' | 'MAYBE' | 'CONFIRMED' | 'CANCELED';
    export const StatusEnumEnum = {
        Requested: 'REQUESTED' as StatusEnumEnum,
        Maybe: 'MAYBE' as StatusEnumEnum,
        Confirmed: 'CONFIRMED' as StatusEnumEnum,
        Canceled: 'CANCELED' as StatusEnumEnum
    };
    export type TypeEnumEnum = 'FIRST_OPTION' | 'SECOND_OPTION' | 'THIRD_OPTION' | 'OTHER_FIRST_OPTION' | 'OTHER_SECOND_OPTION' | 'OTHER_THIRD_OPTION' | 'PRODUCTION' | 'OTHER_PRODUCTION' | 'FIRST_FOREIGN_OPTION' | 'SECOND_FOREIGN_OPTION' | 'THIRD_FOREIGN_OPTION' | 'ARRIVAL_DEPARTURE' | 'SICK' | 'EXTERNAL_BOOKED' | 'HOLIDAY' | 'DIFFERENT' | 'PREGNANCY' | 'REQUEST_TIMEFRAME' | 'MODEL_DECLINED' | 'STAY_ABROAD';
    export const TypeEnumEnum = {
        FirstOption: 'FIRST_OPTION' as TypeEnumEnum,
        SecondOption: 'SECOND_OPTION' as TypeEnumEnum,
        ThirdOption: 'THIRD_OPTION' as TypeEnumEnum,
        OtherFirstOption: 'OTHER_FIRST_OPTION' as TypeEnumEnum,
        OtherSecondOption: 'OTHER_SECOND_OPTION' as TypeEnumEnum,
        OtherThirdOption: 'OTHER_THIRD_OPTION' as TypeEnumEnum,
        Production: 'PRODUCTION' as TypeEnumEnum,
        OtherProduction: 'OTHER_PRODUCTION' as TypeEnumEnum,
        FirstForeignOption: 'FIRST_FOREIGN_OPTION' as TypeEnumEnum,
        SecondForeignOption: 'SECOND_FOREIGN_OPTION' as TypeEnumEnum,
        ThirdForeignOption: 'THIRD_FOREIGN_OPTION' as TypeEnumEnum,
        ArrivalDeparture: 'ARRIVAL_DEPARTURE' as TypeEnumEnum,
        Sick: 'SICK' as TypeEnumEnum,
        ExternalBooked: 'EXTERNAL_BOOKED' as TypeEnumEnum,
        Holiday: 'HOLIDAY' as TypeEnumEnum,
        Different: 'DIFFERENT' as TypeEnumEnum,
        Pregnancy: 'PREGNANCY' as TypeEnumEnum,
        RequestTimeframe: 'REQUEST_TIMEFRAME' as TypeEnumEnum,
        ModelDeclined: 'MODEL_DECLINED' as TypeEnumEnum,
        StayAbroad: 'STAY_ABROAD' as TypeEnumEnum
    };
}


