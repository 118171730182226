import { Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditLocationActions } from '../actions/location.actions';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';

export class ProjectEditLocationHandlers {
  constructor(private projectControllerService: ProjectControllerService) {}

  @Action(ProjectEditLocationActions.LoadLocations)
  loadProjectLocationsAndMotifs(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.LoadLocations
  ) {
    return this.projectControllerService.getProjectLocations(action.projectId).pipe(
      tap(response => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            locations: response.locations || [],
            motifs: response.motifs || []
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.ChangeLocation)
  changeLocation(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.ChangeLocation
  ) {
    return this.projectControllerService.changeLocation(action.projectId, action.location).pipe(
      tap(updatedLocation => {
        const state = ctx.getState();
        const updatedLocations = state.projectLocations?.locations?.map(location => 
          location.locationId === updatedLocation.locationId ? updatedLocation : location
        ) || [];

        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            locations: updatedLocations
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.AddLocation)
  addLocation(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.AddLocation
  ) {
    return this.projectControllerService.createProjectLocation(action.projectId, {
      locationName: action.locationName
    }).pipe(
      tap(newLocation => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            locations: [
              ...(state.projectLocations?.locations || []),
              {
                locationId: newLocation.locationId,
                locationName: newLocation.locationName
              }
            ]
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.DeleteLocation)
  deleteLocation(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.DeleteLocation
  ) {
    return this.projectControllerService.deleteProjectLocation(action.projectId, action.locationId).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            locations: state.projectLocations?.locations?.filter(
              location => location.locationId !== action.locationId
            ) || []
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.ChangeMotif)
  changeMotif(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.ChangeMotif
  ) {
    return this.projectControllerService.changeMotif(action.projectId, action.motif).pipe(
      tap(updatedMotif => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            motifs: state.projectLocations?.motifs?.map(motif => 
              motif.motifId === updatedMotif.motifId ? updatedMotif : motif
            ) || []
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.AddMotif)
  addMotif(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.AddMotif
  ) {
    return this.projectControllerService.createProjectMotif(action.projectId, {
      motifName: action.name,
      locationName: action.locationName
    }).pipe(
      tap(newMotif => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            motifs: [
              ...(state.projectLocations?.motifs || []),
              {
                motifId: newMotif.motifId,
                motifName: newMotif.motifName
              }
            ]
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.DeleteMotif)
  deleteMotif(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.DeleteMotif
  ) {
    return this.projectControllerService.deleteProjectMotif(action.projectId, action.motifId).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          projectLocations: {
            ...state.projectLocations,
            motifs: state.projectLocations?.motifs?.filter(
              motif => motif.motifId !== action.motifId
            ) || []
          }
        });
      })
    );
  }

  @Action(ProjectEditLocationActions.SelectLocation)    
  selectLocation(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.SelectLocation
  ) {
    ctx.patchState({
      selectedLocation: action.location
    });
  }

  @Action(ProjectEditLocationActions.SelectMotif)
  selectMotif(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditLocationActions.SelectMotif
  ) {
    ctx.patchState({
      selectedMotif: action.motif
    });
  }
}
