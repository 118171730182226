import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'urlLink'
})
export class UrlLinkPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string): SafeHtml {
    if (!text) return text;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let replacedText = text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    replacedText = replacedText.replace(/\n/g, '<br>');

    return this.sanitizer.bypassSecurityTrustHtml(replacedText);
  }
}