/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectTotalModelFees
 */
export interface ProjectTotalModelFees {
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    totalModelFees?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    totalFlatCharge?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    totalTaxableTravelCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    totalNonTaxableTravelCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    totalExtraCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    agentFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    serviceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    subTotal2?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    vat?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    agentFeePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTotalModelFees
     */
    serviceFeePercentage?: number;
}

/**
 * Check if a given object implements the ProjectTotalModelFees interface.
 */
export function instanceOfProjectTotalModelFees(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectTotalModelFeesFromJSON(json: any): ProjectTotalModelFees {
    return ProjectTotalModelFeesFromJSONTyped(json, false);
}

export function ProjectTotalModelFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTotalModelFees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalModelFees': !exists(json, 'totalModelFees') ? undefined : json['totalModelFees'],
        'totalFlatCharge': !exists(json, 'totalFlatCharge') ? undefined : json['totalFlatCharge'],
        'totalTaxableTravelCosts': !exists(json, 'totalTaxableTravelCosts') ? undefined : json['totalTaxableTravelCosts'],
        'totalNonTaxableTravelCosts': !exists(json, 'totalNonTaxableTravelCosts') ? undefined : json['totalNonTaxableTravelCosts'],
        'totalExtraCosts': !exists(json, 'totalExtraCosts') ? undefined : json['totalExtraCosts'],
        'agentFee': !exists(json, 'agentFee') ? undefined : json['agentFee'],
        'serviceFee': !exists(json, 'serviceFee') ? undefined : json['serviceFee'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'subTotal2': !exists(json, 'subTotal2') ? undefined : json['subTotal2'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'agentFeePercentage': !exists(json, 'agentFeePercentage') ? undefined : json['agentFeePercentage'],
        'serviceFeePercentage': !exists(json, 'serviceFeePercentage') ? undefined : json['serviceFeePercentage'],
    };
}

export function ProjectTotalModelFeesToJSON(value?: ProjectTotalModelFees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalModelFees': value.totalModelFees,
        'totalFlatCharge': value.totalFlatCharge,
        'totalTaxableTravelCosts': value.totalTaxableTravelCosts,
        'totalNonTaxableTravelCosts': value.totalNonTaxableTravelCosts,
        'totalExtraCosts': value.totalExtraCosts,
        'agentFee': value.agentFee,
        'serviceFee': value.serviceFee,
        'subTotal': value.subTotal,
        'subTotal2': value.subTotal2,
        'vat': value.vat,
        'total': value.total,
        'agentFeePercentage': value.agentFeePercentage,
        'serviceFeePercentage': value.serviceFeePercentage,
    };
}

