/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConversationInfo } from './ConversationInfo';
import {
    ConversationInfoFromJSON,
    ConversationInfoFromJSONTyped,
    ConversationInfoToJSON,
} from './ConversationInfo';
import type { ProjectDataForConversations } from './ProjectDataForConversations';
import {
    ProjectDataForConversationsFromJSON,
    ProjectDataForConversationsFromJSONTyped,
    ProjectDataForConversationsToJSON,
} from './ProjectDataForConversations';

/**
 * 
 * @export
 * @interface ConversationsResponseDto
 */
export interface ConversationsResponseDto {
    /**
     * 
     * @type {Array<ConversationInfo>}
     * @memberof ConversationsResponseDto
     */
    directConversations?: Array<ConversationInfo>;
    /**
     * 
     * @type {Array<ProjectDataForConversations>}
     * @memberof ConversationsResponseDto
     */
    projectConversations?: Array<ProjectDataForConversations>;
}

/**
 * Check if a given object implements the ConversationsResponseDto interface.
 */
export function instanceOfConversationsResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationsResponseDtoFromJSON(json: any): ConversationsResponseDto {
    return ConversationsResponseDtoFromJSONTyped(json, false);
}

export function ConversationsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directConversations': !exists(json, 'directConversations') ? undefined : ((json['directConversations'] as Array<any>).map(ConversationInfoFromJSON)),
        'projectConversations': !exists(json, 'projectConversations') ? undefined : ((json['projectConversations'] as Array<any>).map(ProjectDataForConversationsFromJSON)),
    };
}

export function ConversationsResponseDtoToJSON(value?: ConversationsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directConversations': value.directConversations === undefined ? undefined : ((value.directConversations as Array<any>).map(ConversationInfoToJSON)),
        'projectConversations': value.projectConversations === undefined ? undefined : ((value.projectConversations as Array<any>).map(ProjectDataForConversationsToJSON)),
    };
}

