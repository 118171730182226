import { Injectable } from '@angular/core';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { Action, StateContext } from '@ngxs/store';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditAdditionalParticipantsActions } from '../actions/additional-participants.actions';
import { ProjectAdditionalParticipantFormValuesDto } from 'libs/api-fetch/src/models/ProjectAdditionalParticipantFormValuesDto';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProjectEditAdditionalParticipantsHandlers {
    constructor(
        private projectControllerService: ProjectControllerService
    ) {}

    @Action(ProjectEditAdditionalParticipantsActions.LoadAdditionalParticipants)
    loadAdditionalParticipants(
        ctx: StateContext<ProjectEditStateModel>
    ) {
        return this.projectControllerService.getProjectAdditionalParticipants(
            ctx.getState().id?.id!!
        ).pipe(
            tap((additionalParticipants) => {
            ctx.patchState({
                additionalParticipantList: additionalParticipants
                });
            })
        );
    }

    @Action(ProjectEditAdditionalParticipantsActions.AddAdditionalParticipant)
    addAdditionalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.AddAdditionalParticipant
    ) {
        return this.projectControllerService.addAdditionalParticipant(
            action.payload.projectId,
            action.payload.participant
        ).pipe(
            tap((response: ProjectAdditionalParticipantFormValuesDto) => {
                ctx.setState((state: ProjectEditStateModel) => ({
                    ...state,
                    additionalParticipantList: [
                        ...(state.additionalParticipantList || []),
                        response
                    ]
                }));
            })
        );
    }

    // updateAdditionalParticipant(
    //     ctx: StateContext<ProjectEditStateModel>,
    //     action: ProjectEditAdditionalParticipantsActions.UpdateAdditionalParticipant
    // ) {
    //     const state = ctx.getState();

    //     ctx.patchState({
    //         additionalParticipantList: state.additionalParticipantList.map(p => 
    //             p.id === action.participantId ? action.participant : p
    //         )
    //     });

    //     this.projectControllerService.upd(
    //         state.id?.id!!,
    //         action.participantId,
    //         action.participant
    //     ).subscribe();
    // }

    @Action(ProjectEditAdditionalParticipantsActions.ChangeAdminRights)
    changeAdminRights(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.ChangeAdminRights
    ) {
        return this.projectControllerService.changeAdminRightsAdditionalParticipant(
            ctx.getState().id?.id!!,
            {
                employeeId: action.employeeId
            }
        ).pipe(
            tap(() => {
                const state = ctx.getState();
                const participants = [...state.additionalParticipantList || []];
                const participantIndex = participants.findIndex(p => p.employeeId === action.employeeId);
                
                if (participantIndex !== -1) {
                    participants[participantIndex] = {
                        ...participants[participantIndex],
                        hasAdminRights: !participants[participantIndex].hasAdminRights
                    };
                    
                    ctx.patchState({
                        additionalParticipantList: participants
                    });
                }
            })
        );
    }

    @Action(ProjectEditAdditionalParticipantsActions.DeleteAdditionalParticipant)
    deleteAdditionalParticipant(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditAdditionalParticipantsActions.DeleteAdditionalParticipant
    ) {
        return this.projectControllerService.deleteAdditionalParticipant(
            action.projectId,
            {
                employeeId: action.employeeId
            }
        ).pipe(
            tap(() => {
                const state = ctx.getState();
                ctx.patchState({
                    additionalParticipantList: state.additionalParticipantList?.filter(
                        participant => participant.employeeId !== action.employeeId
                    )
                });
            })
        );
    }
} 