import { StateContext } from '@ngxs/store';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { ProjectEditExternalParticipantsActions } from '../actions/external-participants.actions';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class ProjectEditExternalParticipantsHandlers {
  constructor(
    private projectControllerService: ProjectControllerService
  ) {}

  loadExternalParticipants(
    ctx: StateContext<ProjectEditStateModel>
  ) {
    return this.projectControllerService.getProjectExternalParticipants(ctx.getState().id?.id!!).pipe(
      tap(participants => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          externalParticipantList: participants
        });
      })
    );
  }

  addExternalParticipant(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditExternalParticipantsActions.AddExternalParticipant
  ) {
    return this.projectControllerService.addExternalParticipant(
      ctx.getState().id?.id!!,
      action.participant
    ).pipe(
      tap(() => {
        // Nach erfolgreichem Hinzufügen die Liste neu laden
        return ctx.dispatch(new ProjectEditExternalParticipantsActions.LoadExternalParticipants());
      })
    );
  }

  deleteExternalParticipant(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditExternalParticipantsActions.DeleteExternalParticipant
  ) {
    return this.projectControllerService.deleteExternalParticipant(
      ctx.getState().id?.id!!,
      {
        employeeId: action.participantId
      }
    ).pipe(
      tap(() => {
        // Nach erfolgreichem Löschen die Liste neu laden
        return ctx.dispatch(new ProjectEditExternalParticipantsActions.LoadExternalParticipants());
      })
    );
  }

  changeAdminRights(
    ctx: StateContext<ProjectEditStateModel>,
    action: ProjectEditExternalParticipantsActions.ChangeAdminRights
  ) {
    return this.projectControllerService.changeAdminRightsExternalParticipant(
      ctx.getState().id?.id!!,
      {
        employeeId: action.participantId
      }
    ).pipe(
      tap(() => {
        // Nach erfolgreicher Änderung die Liste neu laden
        return ctx.dispatch(new ProjectEditExternalParticipantsActions.LoadExternalParticipants());
      })
    );
  }
}