/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerEmployeeNamesForProjectsDto
 */
export interface CustomerEmployeeNamesForProjectsDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    oauthId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerEmployeeNamesForProjectsDto
     */
    email?: string;
}

/**
 * Check if a given object implements the CustomerEmployeeNamesForProjectsDto interface.
 */
export function instanceOfCustomerEmployeeNamesForProjectsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerEmployeeNamesForProjectsDtoFromJSON(json: any): CustomerEmployeeNamesForProjectsDto {
    return CustomerEmployeeNamesForProjectsDtoFromJSONTyped(json, false);
}

export function CustomerEmployeeNamesForProjectsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEmployeeNamesForProjectsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function CustomerEmployeeNamesForProjectsDtoToJSON(value?: CustomerEmployeeNamesForProjectsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'mobile': value.mobile,
        'oauthId': value.oauthId,
        'email': value.email,
    };
}

