import {
    ProjectCreatorFormValuesDto,
    FinalCustomerFormValuesDto,
    ProjectRoleFormValuesDto
} from '@brody-bookings/api-v2';
import { ProjectNameFormValuesDto } from 'libs/api-typescript-angular/src/model/projectNameFormValuesDto';
import { ProjectIdFormValuesDto } from 'libs/api-typescript-angular/src/model/projectIdFormValuesDto';
import { ProductionDetailsFormValuesDto } from 'libs/api-typescript-angular/src/model/productionDetailsFormValuesDto';
import { ProjectRolesFormValuesDto } from 'libs/api-typescript-angular/src/model/projectRolesFormValuesDto';
import { ProjectRolesWithModelsDto } from 'libs/api-typescript-angular/src/model/projectRolesWithModelsDto';
import { ProjectRoleForSelectionDto } from 'libs/api-typescript-angular/src/model/projectRoleForSelectionDto';
import { UsageTypesFormValuesDto } from 'libs/api-typescript-angular/src/model/usageTypesFormValuesDto';
import { ExecutorFormValuesDto } from 'libs/api-typescript-angular/src/model/executorFormValuesDto';
import { ProjectStatusFormValuesDto } from 'libs/api-typescript-angular/src/model/projectStatusFormValuesDto';
import { ProjectAdditionalParticipantFormValuesDto } from 'libs/api-typescript-angular/src/model/projectAdditionalParticipantFormValuesDto';
import { ProjectLocationsAndMotifsFormValuesDto } from 'libs/api-typescript-angular/src/model/projectLocationsAndMotifsFormValuesDto';
import { ProjectLocationFormValuesDto } from 'libs/api-typescript-angular/src/model/projectLocationFormValuesDto';
import { ProjectMotifFormValuesDto } from 'libs/api-typescript-angular/src/model/projectMotifFormValuesDto';
import { BookingModelsFormValuesDto } from 'libs/api-typescript-angular/src/model/bookingModelsFormValuesDto';
import { BookingProducerFormValuesDto } from 'libs/api-typescript-angular/src/model/bookingProducerFormValuesDto';
import { ProjectBillingFormValuesDto } from 'libs/api-fetch/src/models/ProjectBillingFormValuesDto';
import { ExternalParticipantFormValuesDto } from 'libs/api-typescript-angular/src/model/externalParticipantFormValuesDto';
import { ModelMasterDataSearchItemsDto } from 'libs/api-typescript-angular/src/model/modelMasterDataSearchItemsDto';
import { ModelMasterDataSearchItemDto } from 'libs/api-typescript-angular/src/model/modelMasterDataSearchItemDto';
import { ProjectProducerFormValueDto } from 'libs/api-typescript-angular/src/model/projectProducerFormValueDto';
import { BudgetFormValuesDto } from 'libs/api-typescript-angular/src/model/budgetFormValuesDto';
import { ProjectCastingFormValuesDto } from 'libs/api-typescript-angular/src/model/projectCastingFormValuesDto';
export interface ProjectEditStateModel {
    loading: boolean;
    language: string | undefined;
    id: ProjectIdFormValuesDto | undefined;
    name: ProjectNameFormValuesDto | undefined;
    status: ProjectStatusFormValuesDto | undefined;
    creator: ProjectCreatorFormValuesDto | undefined;
    producer: ProjectProducerFormValueDto | undefined;
    createdAt: string | undefined;
    lastEditedAt: string | Date | undefined;
    lastWatchedBy: string | undefined;
    lastEditor: string | undefined;
    finalCustomer: FinalCustomerFormValuesDto | undefined;
    productionDetails: ProductionDetailsFormValuesDto | undefined;
    budget: BudgetFormValuesDto | undefined;
    usageType: UsageTypesFormValuesDto | undefined;
    executor: ExecutorFormValuesDto | undefined;
    buyOut: string | undefined;
    casting: ProjectCastingFormValuesDto | undefined;
    additionalParticipantList: ProjectAdditionalParticipantFormValuesDto[] | undefined;
    externalParticipantList: ExternalParticipantFormValuesDto[] | undefined;
    roles: ProjectRolesFormValuesDto | undefined;
    selectedRole: ProjectRoleFormValuesDto | undefined;
    selectedRoleInProductionDetails: ProjectRoleForSelectionDto | undefined;
    rolesAsOptions: ProjectRoleForSelectionDto[] | undefined;
    projectRolesWithModels: ProjectRolesWithModelsDto | undefined;
    projectLocations: ProjectLocationsAndMotifsFormValuesDto | undefined;
    selectedLocation: ProjectLocationFormValuesDto | undefined;
    selectedMotif: ProjectMotifFormValuesDto | undefined;
    bookingProducer?: BookingProducerFormValuesDto;
    bookingModels?: BookingModelsFormValuesDto;
    billing?: ProjectBillingFormValuesDto;
    modelSearch: {
        allModels: ModelMasterDataSearchItemDto[] | undefined,
        loading: boolean
    }
}

export const initialState: ProjectEditStateModel = {
    loading: false,
    language: undefined,
    id: undefined,
    name: undefined,
    status: undefined,
    creator: undefined,
    producer: undefined,
    createdAt: undefined,
    lastEditedAt: undefined,
    lastWatchedBy: undefined,
    lastEditor: undefined,
    finalCustomer: undefined,
    productionDetails: undefined,
    budget: undefined,
    usageType: undefined,
    executor: undefined,
    buyOut: undefined,
    casting: undefined,
    additionalParticipantList: undefined,
    externalParticipantList: undefined,
    roles: undefined,
    rolesAsOptions: undefined,
    selectedRole: undefined,
    selectedRoleInProductionDetails: undefined,
    projectRolesWithModels: undefined,
    projectLocations: undefined,
    selectedLocation: undefined,
    selectedMotif: undefined,
    bookingProducer: undefined,
    bookingModels: undefined,
    billing: undefined,
    modelSearch: {
        allModels: undefined,
        loading: false
    }
};
