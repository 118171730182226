/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    senderId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    senderName: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    videoUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    timeStamp: Date;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    formattedTimeStamp: string;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messageId" in value;
    isInstance = isInstance && "senderId" in value;
    isInstance = isInstance && "senderName" in value;
    isInstance = isInstance && "timeStamp" in value;
    isInstance = isInstance && "formattedTimeStamp" in value;

    return isInstance;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': json['messageId'],
        'senderId': json['senderId'],
        'senderName': json['senderName'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'timeStamp': (new Date(json['timeStamp'])),
        'formattedTimeStamp': json['formattedTimeStamp'],
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageId': value.messageId,
        'senderId': value.senderId,
        'senderName': value.senderName,
        'message': value.message,
        'imageUrl': value.imageUrl,
        'videoUrl': value.videoUrl,
        'timeStamp': (value.timeStamp.toISOString()),
        'formattedTimeStamp': value.formattedTimeStamp,
    };
}

