import { Injectable } from '@angular/core';
import { StateContext } from '@ngxs/store';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditRolesActions } from '../actions/roles.actions';
import { ProjectRoleForSelectionDto } from 'libs/api-fetch/src/models/ProjectRoleForSelectionDto';
import { ProjectQueryControllerService } from 'libs/api-typescript-angular/src/api/projectQueryController.service';
import { ProjectRoleFormValuesDto } from 'libs/api-typescript-angular/src/model/projectRoleFormValuesDto';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';

import { tap } from 'rxjs/operators';
import { ProjectEditModelActions } from '../actions/model.actions';
import { stat } from 'fs';

@Injectable()
export class ProjectEditRolesHandlers {
    constructor(
        private projectQueryService: ProjectQueryControllerService,
        private projectControllerService: ProjectControllerService
    ) { }

    setSelectedRole(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.SetSelectedRole
    ) {
        ctx.patchState({
            selectedRole: action.projectRoleFormValuesDto
        });
    }   

    setSelectedRoleInProductionDetails(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.SetSelectedRoleInProductionDetails
    ) {
        ctx.patchState({
            selectedRoleInProductionDetails: action.projectRoleForSelectionDto
        });

        const state = ctx.getState();
        const projectRoleWithModels = state.projectRolesWithModels?.projectRoleWithModels?.find(role => role.roleId === state.selectedRoleInProductionDetails?.projectRoleId);
        const shouldLoadModels = state.selectedRoleInProductionDetails?.projectRoleId && 
            (!projectRoleWithModels || projectRoleWithModels.projectModels?.length === 0);
            
        if (shouldLoadModels) {
            return ctx.dispatch(new ProjectEditModelActions.LoadProjectModels(
                state.id?.id!!, 
                state.selectedRoleInProductionDetails?.projectRoleId!!
            ));
        }
        return undefined;
    }   

    loadRolesAsOptions(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.LoadRolesAsOptions
    ) {
        return this.projectQueryService.getProjectRolesForSelection(action.projectId).pipe(
            tap((roles: ProjectRoleForSelectionDto[]) => {
                ctx.patchState({
                    rolesAsOptions: roles
                });
            })
        );
    }

    changeRole(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.ChangeRole
    ) {
        const language = ctx.getState().language;
        return this.projectControllerService.updateRole(language!!, action.projectRoleFormValuesDto).pipe(
            tap((updatedRole: ProjectRoleFormValuesDto) => {
                const state = ctx.getState();
                let buyoutTypeChanged = false;
                if (state.roles?.roles) {
                    const prevRole = state.roles.roles.find(role => role.projectRoleId === updatedRole.projectRoleId);
                    const prevBuyoutType = prevRole?.buyout?.buyOutType;
                    const nextBuyoutType = updatedRole.buyout?.buyOutType;
                    if (prevBuyoutType !== nextBuyoutType) {
                        buyoutTypeChanged = true;
                    }

                    const updatedRoles = state.roles.roles.map(role => {
                        return role.projectRoleId === updatedRole.projectRoleId ? updatedRole : role;
                    });
                    ctx.patchState({
                        roles: {
                            ...state.roles,
                            roles: updatedRoles
                        }
                    });

                    if (buyoutTypeChanged) {
                        ctx.dispatch(new ProjectEditModelActions.ChangeProjectModelBuyout(state.id?.id!!, action.projectRoleFormValuesDto.projectRoleId, updatedRole.buyout?.buyOutType!!, updatedRole.buyout?.buyOutValue || 0));
                    }
                }
            })
        );
    }

    getProjectRoleFormValues(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.GetProjectRoleFormValues
    ) {
        const language = ctx.getState().language;
        console.log('getProjectRoleFormValues', action);
        return this.projectControllerService.getProjectRoleFormValues(
            action.projectId,
            action.projectRoleId,
            language!!
        ).pipe(
            tap((roleFormValues: ProjectRoleFormValuesDto) => {
                const state = ctx.getState();
                console.log('roleFormValues', roleFormValues);
                
                if (state.roles?.roles) {
                    const updatedRoles = state.roles.roles.filter(
                        role => role.projectRoleId !== roleFormValues.projectRoleId
                    );
                    ctx.patchState({
                        roles: {
                            ...state.roles,
                            roles: [...updatedRoles, roleFormValues]
                        }
                    });
                }
            })
        );
    }

    addProjectRole(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.AddProjectRole
    ) {
        const language = ctx.getState().language;
        return this.projectControllerService.addRole(action.projectId, {
            name: action.name
        }).pipe(
            tap((newRole) => {
                console.log('newRole', newRole);
                return ctx.dispatch(new ProjectEditRolesActions.GetProjectRoleFormValues(
                    action.projectId,
                    newRole.projectRoleId
                ));
            })
        );
    }

    deleteProjectRole(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.DeleteProjectRole
    ) {
        return this.projectControllerService.deleteProjectRole(action.projectId, action.projectRoleId).pipe(
            tap(() => {
                const state = ctx.getState();
                if (state.roles?.roles) {
                    ctx.patchState({
                        roles: {
                            ...state.roles,
                            roles: state.roles.roles.filter(role => role.projectRoleId !== action.projectRoleId)
                        }
                    });

                    // Update rolesAsOptions if it exists
                    if (state.rolesAsOptions) {
                        ctx.patchState({
                            rolesAsOptions: state.rolesAsOptions.filter(role => role.projectRoleId !== action.projectRoleId)
                        });
                    }
                }
            })
        );
    }

    changeRoleDetailsInProductionDetails(
        ctx: StateContext<ProjectEditStateModel>,
        action: ProjectEditRolesActions.ChangeRoleDetailsInProductionDetails
    ) {
        return this.projectControllerService.changeRoleDetails(action.projectId, action.payload).pipe(
            tap(() => {
                console.log('role details changed');
            })
        );
    }       
}
