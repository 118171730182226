/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelProductionDetailsEventFormValuesDto } from './ModelProductionDetailsEventFormValuesDto';
import {
    ModelProductionDetailsEventFormValuesDtoFromJSON,
    ModelProductionDetailsEventFormValuesDtoFromJSONTyped,
    ModelProductionDetailsEventFormValuesDtoToJSON,
} from './ModelProductionDetailsEventFormValuesDto';

/**
 * 
 * @export
 * @interface ProductionDayEventsDto
 */
export interface ProductionDayEventsDto {
    /**
     * 
     * @type {Date}
     * @memberof ProductionDayEventsDto
     */
    selectedDate?: Date;
    /**
     * 
     * @type {Array<ModelProductionDetailsEventFormValuesDto>}
     * @memberof ProductionDayEventsDto
     */
    modelProductionDetailsEvents?: Array<ModelProductionDetailsEventFormValuesDto>;
}

/**
 * Check if a given object implements the ProductionDayEventsDto interface.
 */
export function instanceOfProductionDayEventsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductionDayEventsDtoFromJSON(json: any): ProductionDayEventsDto {
    return ProductionDayEventsDtoFromJSONTyped(json, false);
}

export function ProductionDayEventsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductionDayEventsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedDate': !exists(json, 'selectedDate') ? undefined : (new Date(json['selectedDate'])),
        'modelProductionDetailsEvents': !exists(json, 'modelProductionDetailsEvents') ? undefined : ((json['modelProductionDetailsEvents'] as Array<any>).map(ModelProductionDetailsEventFormValuesDtoFromJSON)),
    };
}

export function ProductionDayEventsDtoToJSON(value?: ProductionDayEventsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedDate': value.selectedDate === undefined ? undefined : (value.selectedDate.toISOString().substr(0,10)),
        'modelProductionDetailsEvents': value.modelProductionDetailsEvents === undefined ? undefined : ((value.modelProductionDetailsEvents as Array<any>).map(ModelProductionDetailsEventFormValuesDtoToJSON)),
    };
}

