/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelCurrentSelection } from './ProjectModelCurrentSelection';
import {
    ProjectModelCurrentSelectionFromJSON,
    ProjectModelCurrentSelectionFromJSONTyped,
    ProjectModelCurrentSelectionToJSON,
} from './ProjectModelCurrentSelection';
import type { ProjectModelOfRoleNotSelectedYet } from './ProjectModelOfRoleNotSelectedYet';
import {
    ProjectModelOfRoleNotSelectedYetFromJSON,
    ProjectModelOfRoleNotSelectedYetFromJSONTyped,
    ProjectModelOfRoleNotSelectedYetToJSON,
} from './ProjectModelOfRoleNotSelectedYet';

/**
 * 
 * @export
 * @interface AllActiveProjectsFromCustomerResponseDto
 */
export interface AllActiveProjectsFromCustomerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AllActiveProjectsFromCustomerResponseDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AllActiveProjectsFromCustomerResponseDto
     */
    projectName?: string;
    /**
     * 
     * @type {{ [key: string]: Array<ProjectModelCurrentSelection>; }}
     * @memberof AllActiveProjectsFromCustomerResponseDto
     */
    currentSelectionGroupedByRoleName?: { [key: string]: Array<ProjectModelCurrentSelection>; };
    /**
     * 
     * @type {{ [key: string]: Array<ProjectModelOfRoleNotSelectedYet>; }}
     * @memberof AllActiveProjectsFromCustomerResponseDto
     */
    notSelectedGroupedByRoleName?: { [key: string]: Array<ProjectModelOfRoleNotSelectedYet>; };
    /**
     * 
     * @type {boolean}
     * @memberof AllActiveProjectsFromCustomerResponseDto
     */
    hasAdminRights?: boolean;
}

/**
 * Check if a given object implements the AllActiveProjectsFromCustomerResponseDto interface.
 */
export function instanceOfAllActiveProjectsFromCustomerResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AllActiveProjectsFromCustomerResponseDtoFromJSON(json: any): AllActiveProjectsFromCustomerResponseDto {
    return AllActiveProjectsFromCustomerResponseDtoFromJSONTyped(json, false);
}

export function AllActiveProjectsFromCustomerResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllActiveProjectsFromCustomerResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'currentSelectionGroupedByRoleName': !exists(json, 'currentSelectionGroupedByRoleName') ? undefined : json['currentSelectionGroupedByRoleName'],
        'notSelectedGroupedByRoleName': !exists(json, 'notSelectedGroupedByRoleName') ? undefined : json['notSelectedGroupedByRoleName'],
        'hasAdminRights': !exists(json, 'hasAdminRights') ? undefined : json['hasAdminRights'],
    };
}

export function AllActiveProjectsFromCustomerResponseDtoToJSON(value?: AllActiveProjectsFromCustomerResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'projectName': value.projectName,
        'currentSelectionGroupedByRoleName': value.currentSelectionGroupedByRoleName,
        'notSelectedGroupedByRoleName': value.notSelectedGroupedByRoleName,
        'hasAdminRights': value.hasAdminRights,
    };
}

