/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentChangeRequestDto
 */
export interface AppointmentChangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentChangeRequestDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentChangeRequestDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentChangeRequestDto
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentChangeRequestDto
     */
    from: Date;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentChangeRequestDto
     */
    to: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentChangeRequestDto
     */
    agencyId?: string;
}

/**
 * Check if a given object implements the AppointmentChangeRequestDto interface.
 */
export function instanceOfAppointmentChangeRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;

    return isInstance;
}

export function AppointmentChangeRequestDtoFromJSON(json: any): AppointmentChangeRequestDto {
    return AppointmentChangeRequestDtoFromJSONTyped(json, false);
}

export function AppointmentChangeRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentChangeRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'title': json['title'],
        'description': json['description'],
        'from': (new Date(json['from'])),
        'to': (new Date(json['to'])),
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
    };
}

export function AppointmentChangeRequestDtoToJSON(value?: AppointmentChangeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'title': value.title,
        'description': value.description,
        'from': (value.from.toISOString()),
        'to': (value.to.toISOString()),
        'agencyId': value.agencyId,
    };
}

