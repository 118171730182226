import { StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditProducerActions } from '../actions/producer.actions';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { finalize, tap } from 'rxjs/operators';
import { ProjectFormService } from '../../../service/project.form.service';
import { ProjectEditBaseActions } from '../actions/base.actions';

@Injectable()
export class ProjectEditProducerHandlers {
    constructor(
        private projectControllerService: ProjectControllerService,
        private projectFormService: ProjectFormService
    ) {}

    updateProducer(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProducerActions.UpdateProducer) {
        const previousState = ctx.getState();

        if (JSON.stringify(previousState.producer) !== JSON.stringify(action.producer)) {
            return this.projectControllerService.changeProjectProducer(
                previousState.id?.id!!,
                {
                    ...previousState.producer,
                    ...action.producer
                }
            ).pipe(
                tap(() => {
                    ctx.patchState({
                        producer: {
                            ...previousState.producer,
                            ...action.producer
                        }
                    });
                }),
                finalize(() => {
                    if (previousState.producer?.customerId !== action.producer.customerId) {
                        ctx.dispatch(new ProjectEditBaseActions.LoadProject(previousState.id?.id!!));
                    }
                })
            ).subscribe();
        }
        return Promise.resolve();
    }

    static updateProducerSelectedEmployee(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditProducerActions.UpdateProducerSelectedEmployee) {
        const state = ctx.getState();
        if (state.producer?.selectedEmployee !== action.employeeName) {
            ctx.patchState({
                producer: {
                    ...state.producer,
                    selectedEmployee: action.employeeName
                }
            });
        }
    }
}