import { Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProjectEditStateModel } from '../project-edit.model';
import { ProjectEditAppointmentActions } from '../actions/appointment.actions';
import { tap, catchError, map } from 'rxjs/operators';
import { ModelMasterDataControllerService } from 'libs/api-typescript-angular/src/api/modelMasterDataController.service';
import { GetAppointmentsRequestDto } from 'libs/api-typescript-angular/src/model/getAppointmentsRequestDto';
import { ProjectRoleWithModels } from 'libs/api-typescript-angular/src/model/projectRoleWithModels';
import { ErrorHandlingService } from '../../../service/errorHandling.service';
import { throwError } from 'rxjs';
import { AppointmentDto } from 'libs/api-typescript-angular/src/model/models';
import { ProjectControllerService } from 'libs/api-typescript-angular/src/api/projectController.service';
import { forkJoin } from 'rxjs';


@Injectable()
export class ProjectEditAppointmentHandlers {
    constructor(
        private modelMasterDataControllerService: ModelMasterDataControllerService,
        private errorHandlingService: ErrorHandlingService,
        private projectControllerService: ProjectControllerService
    ) { }

    @Action(ProjectEditAppointmentActions.LoadAppointments)
    loadAppointments(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.LoadAppointments) {
        const previousState = ctx.getState();
        const role = previousState.projectRolesWithModels?.projectRoleWithModels?.find((role: ProjectRoleWithModels) => role.roleId === action.roleId);
        
        if (!role?.projectModels?.length) return;

        const body: GetAppointmentsRequestDto = {
            from: action.from.toISOString(),
            to: action.to.toISOString(),
            language: previousState.language,
            requestTimeframesDto: {
                roleId: action.roleId
            }
        };

        // Combine all requests into an array of observables
        const requests = role.projectModels
            .filter(model => !model.appointments?.find(app => app.modelId === model.modelId))
            .map(model => 
                this.modelMasterDataControllerService.getModelAppointments(model.modelId!, body)
                    .pipe(
                        map(appointments => ({
                            modelId: model.modelId!,
                            appointments: appointments.appointments.map(app => {
                                if (app.typeEnum === AppointmentDto.TypeEnumEnum.FirstOption && app.projectId !== action.projectId) {
                                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherFirstOption };
                                }
                                if (app.typeEnum === AppointmentDto.TypeEnumEnum.SecondOption && app.projectId !== action.projectId) {
                                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherSecondOption };
                                }
                                if (app.typeEnum === AppointmentDto.TypeEnumEnum.ThirdOption && app.projectId !== action.projectId) {
                                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherThirdOption };
                                }
                                if (app.typeEnum === AppointmentDto.TypeEnumEnum.Production && app.projectId !== action.projectId) {
                                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherProduction };
                                }
                                return app;
                            })
                        }))
                    )
            );

        // Use forkJoin to wait for all requests
        return forkJoin(requests).pipe(
            tap(results => {
                const projectRoleWithModels = ctx.getState().projectRolesWithModels?.projectRoleWithModels?.map(projectRole => {
                    if (projectRole.roleId === action.roleId) {
                        return {
                            ...projectRole,
                            projectModels: projectRole.projectModels?.map(model => {
                                const result = results.find(r => r.modelId === model.modelId);
                                if (result) {
                                    return {
                                        ...model,
                                        appointments: result.appointments
                                    };
                                }
                                return model;
                            })
                        };
                    }
                    return projectRole;
                }) ?? [];

                ctx.patchState({
                    projectRolesWithModels: {
                        ...ctx.getState().projectRolesWithModels,
                        projectRoleWithModels
                    }
                });
            })
        );
    }

    @Action(ProjectEditAppointmentActions.LoadAppointmentsOfModel)
    loadAppointmentsOfModel(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.LoadAppointmentsOfModel) {
        const language = ctx.getState().language;

        const body: GetAppointmentsRequestDto = {
            from: action.from.toISOString(),
            to: action.to.toISOString(),
            language,
            requestTimeframesDto: {
                roleId: action.roleId
            }
        };

        this.modelMasterDataControllerService.getModelAppointments(action.modelId!, body).subscribe((appointments) => {


            const transformedAppointments = appointments.appointments.map(app => {
                if (app.typeEnum === AppointmentDto.TypeEnumEnum.FirstOption && app.projectId !== action.projectId) {
                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherFirstOption };
                }
                if (app.typeEnum === AppointmentDto.TypeEnumEnum.SecondOption && app.projectId !== action.projectId) {
                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherSecondOption };
                }
                if (app.typeEnum === AppointmentDto.TypeEnumEnum.ThirdOption && app.projectId !== action.projectId) {
                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherThirdOption };
                }
                if (app.typeEnum === AppointmentDto.TypeEnumEnum.Production && app.projectId !== action.projectId) {
                    return { ...app, typeEnum: AppointmentDto.TypeEnumEnum.OtherProduction };
                }
                return app;
            });


            const projectRoleWithModels: ProjectRoleWithModels[] = ctx.getState().projectRolesWithModels?.projectRoleWithModels?.map((projectRoleWithModels) => {
                if (projectRoleWithModels.roleId === action.roleId) {
                    return {
                        ...projectRoleWithModels,
                        projectModels: projectRoleWithModels.projectModels?.map((_model) => {
                            if (_model.modelId === action.modelId) {
                                return {
                                    ..._model,
                                    appointments: transformedAppointments
                                };
                            }
                            return _model;
                        })
                    };
                }
                return projectRoleWithModels;
            }) ?? [];

            //update state
            ctx.patchState({
                projectRolesWithModels: {
                    ...ctx.getState().projectRolesWithModels,
                    projectRoleWithModels: projectRoleWithModels
                }
            });
        });
    }

    @Action(ProjectEditAppointmentActions.ChangeAppointment)
    changeAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.ChangeAppointment) {
        return this.modelMasterDataControllerService.changeAppointment(
            action.modelId,
            action.appointmentId,
            ctx.getState().language!!,
            action.appointment
        ).pipe(
            tap((updatedAppointment) => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model => {
                                    if (model.modelId === action.modelId) {
                                        return {
                                            ...model,
                                            appointments: model.appointments?.map(app =>
                                                app.appointmentId === action.appointmentId
                                                    ? updatedAppointment
                                                    : app
                                            )
                                        };
                                    }
                                    return model;
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditAppointmentActions.AddProjectRelatedAppointment)
    addProjectRelatedAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddProjectRelatedAppointment) {
        const language = ctx.getState().language;
        return this.modelMasterDataControllerService.createProjectRelatedAppointment(
            language!!,
            action.projectId,
            action.appointment,
            'body'
        ).pipe(
            tap((newAppointment) => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        return {
                            ...role,
                            projectModels: role.projectModels?.map(model => {
                                if (model.modelId === action.appointment.modelId) {
                                    return {
                                        ...model,
                                        appointments: [...(model.appointments || []), newAppointment]
                                    };
                                }
                                return model;
                            })
                        };
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            }),
            catchError((error) => {
                this.errorHandlingService.handleError(error);
                return throwError(() => error);
            })
        );
    }

    @Action(ProjectEditAppointmentActions.DeleteAppointment)
    deleteAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.DeleteAppointment) {
        return this.modelMasterDataControllerService.deleteAppointment({
            modelId: action.modelId,
            appointmentId: action.appointmentId
        }, 'body').pipe(
            tap(() => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                projectModels: role.projectModels?.map(model => {
                                    if (model.modelId === action.modelId) {
                                        return {
                                            ...model,
                                            appointments: model.appointments?.filter(
                                                app => app.appointmentId !== action.appointmentId
                                            )
                                        };
                                    }
                                    return model;
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            })
        );
    }

    @Action(ProjectEditAppointmentActions.AddDeclinedAppointment)
    addDeclinedAppointment(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddDeclinedAppointment) {
        const language = ctx.getState().language;
        return this.modelMasterDataControllerService.createProjectRelatedAppointment(
            language!!,
            action.projectId,
            action.appointment,
            'body'
        ).pipe(
            tap((newAppointment) => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => ({
                        ...role,
                        projectModels: role.projectModels?.map(model => {
                            if (model.modelId === action.appointment.modelId) {
                                return {
                                    ...model,
                                    appointments: [...(model.appointments || []), newAppointment]
                                };
                            }
                            return model;
                        })
                    }));

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            }),
            catchError((error) => {
                this.errorHandlingService.handleError(error);
                return throwError(() => error);
            })
        );
    }

    @Action(ProjectEditAppointmentActions.AddRequestTimeframe)
    addRequestTimeframe(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.AddRequestTimeframe) {
        const language = ctx.getState().language;
        return this.modelMasterDataControllerService.createProjectRelatedAppointment(
            language!!,
            action.projectId,
            action.appointment,
            'body'
        ).pipe(
            tap((newAppointment) => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        return {
                            ...role,
                            projectModels: role.projectModels?.map(model => {
                                if (model.modelId === action.appointment.modelId) {
                                    return {
                                        ...model,
                                        appointments: [...(model.appointments || []), newAppointment]
                                    };
                                }
                                return model;
                            })
                        };
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            }),
            catchError((error) => {
                this.errorHandlingService.handleError(error);
                return throwError(() => error);
            })
        );
    }

    @Action(ProjectEditAppointmentActions.CreateRoleRequestTimeFrame)
    createRoleRequestTimeFrame(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.CreateRoleRequestTimeFrame) {
        const language = ctx.getState().language;
        return this.projectControllerService.createRequestTimeframeAppointment(
            language!!,
            action.projectId,
            action.roleId,
            action.appointment,
            'body'
        ).pipe(
            tap(() => {
                const dates = [];
                dates.push(action.appointment.date);

                for (let i = 1; i < action.appointment.wdh; i++) {
                    const _baseDate = new Date(action.appointment.date);
                    const nextDate = _baseDate.setDate(_baseDate.getDate() + i);
                    dates.push(new Date(nextDate).toISOString());
                }

                const appointments = dates.map(date => ({
                    title: '',
                    description: '',
                    from: date,
                    to: date,
                    typeEnum: AppointmentDto.TypeEnumEnum.RequestTimeframe,
                    type: "Anfragezeitraum",
                    statusEnum: AppointmentDto.StatusEnumEnum.Confirmed,
                    appointmentId: '',
                    createdAt: new Date().toISOString(),
                    status: 'Bestaetigt',
                    modelId: ''
                }));

                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.roleId) {
                            return {
                                ...role,
                                requestDates: [...(role.requestDates || []), ...appointments.map(app => app.from)],
                                projectModels: role.projectModels?.map(model => {
                                    return {
                                        ...model,
                                        appointments: [...(model.appointments || []), ...appointments]
                                    };
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            }),
            catchError((error) => {
                this.errorHandlingService.handleError(error);
                return throwError(() => error);
            })
        );
    }

    @Action(ProjectEditAppointmentActions.DeleteRoleRequestTimeFrame)
    deleteRoleRequestTimeFrame(ctx: StateContext<ProjectEditStateModel>, action: ProjectEditAppointmentActions.DeleteRoleRequestTimeFrame) {
        return this.projectControllerService.deleteRequestTimeframeAppointment(
            action.command
        ).pipe(
            tap(() => {
                const state = ctx.getState();
                const projectRolesWithModels = state.projectRolesWithModels;

                if (projectRolesWithModels?.projectRoleWithModels) {
                    const updatedRoleWithModels = projectRolesWithModels.projectRoleWithModels.map(role => {
                        if (role.roleId === action.command.roleId) {
                            return {
                                ...role,
                                requestDates: role.requestDates?.filter(rd => {
                                    const appDate = new Date(rd);
                                    appDate.setHours(12);
                                    const date = new Date(action.command.date);
                                    date.setHours(12);
                                    return appDate.getTime() !== date.getTime();
                                }),
                                projectModels: role.projectModels?.map(model => {
                                    return {
                                        ...model,
                                        appointments: model.appointments?.filter(app => {
                                            const appDate = new Date(app.from);
                                            appDate.setHours(12);
                                            const date = new Date(action.command.date);
                                            date.setHours(12);
                                            return appDate.getTime() !== date.getTime();
                                        })
                                    };
                                })
                            };
                        }
                        return role;
                    });

                    ctx.patchState({
                        projectRolesWithModels: {
                            ...projectRolesWithModels,
                            projectRoleWithModels: updatedRoleWithModels
                        }
                    });
                }
            }),
            catchError((error) => {
                this.errorHandlingService.handleError(error);
                return throwError(() => error);
            })
        );
    }
}