import {Injectable} from "@angular/core";
// @ts-ignore
import {
    ModelMasterDataControllerApi,
    ModelMasterDataTableItemDto,
    ModelMasterDataTableItemsDto
} from '@brody-bookings/api'
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  // New BehaviorSubject for selectedModelUuid
  private selectedModelUuidSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private selectedModelUuid$ = this.selectedModelUuidSubject.asObservable();

  // New method to update selectedModelUuid
  setSelectedModelUuid(uuid: string | null) {
    this.selectedModelUuidSubject.next(uuid);
  }

  // Getter for the selectedModelUuid$ observable
  getSelectedModelUuid(): Observable<string | null> {
    return this.selectedModelUuid$;
  }

    private modelsTableSubject: BehaviorSubject<ModelMasterDataTableItemsDtoWithPagingInformation> = new BehaviorSubject<ModelMasterDataTableItemsDtoWithPagingInformation>({
        modelMasterDataTableItems: [],
        pagingInformation: {
            totalPages: 0,
            pageNumber: 1,
            pageSize: 20
        }
    })
    private modelsTable$ = this.modelsTableSubject.asObservable()


    constructor(
        private modelMasterDataControllerApi: ModelMasterDataControllerApi,
    ) {
    }


    getModelsForTable() {
        this.modelMasterDataControllerApi.getModels().then((modelMasterDataTableItemsDto: ModelMasterDataTableItemsDto) => {
            const {modelMasterDataTableItems} = modelMasterDataTableItemsDto;
            this.modelsTableSubject.next({
                modelMasterDataTableItems,
                pagingInformation: {
                    totalPages: modelMasterDataTableItemsDto.modelMasterDataTableItems.length,
                    pageNumber: 1,
                    pageSize: 20
                }
            })
        })
        return this.modelsTable$;
    }


    deleteModel(id: string) {
        this.modelMasterDataControllerApi.deleteModel({id}).then(() => {
            const currentState = this.modelsTableSubject.getValue();
            this.modelsTableSubject.next({
                ...currentState,
                modelMasterDataTableItems: currentState.modelMasterDataTableItems.filter((tableItem: ModelMasterDataTableItemDto) => {
                    return tableItem.modelId !== id
                })
            });
        })
    }
}

export type ModelMasterDataTableItemsDtoWithPagingInformation = ModelMasterDataTableItemsDto & {
    pagingInformation: {
        totalPages: number,
        pageNumber: number,
        pageSize: number
    }
}
