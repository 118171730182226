import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { LanguageService } from '../demo/service/language.service';
import { AuthenticationService } from "./service/app.auth.service"
import { interval, Subscription } from 'rxjs';
import {
    ConversationWidgetComponent
} from '../demo/components/shared/widgets/conversation/conversation.widget.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConversationService } from '../demo/service/conversation.service';
import { MessageNotificationService } from '../demo/service/message-notification.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    name = '';
    oauthId = '';
    subscriptions: Subscription[] = []
    numberOfNewMessages: string | undefined = undefined;
    userLoaded = false;

    private messageCheckInterval: Subscription | null = null;

    constructor(
        public layoutService: LayoutService,
        private languageService: LanguageService,
        private dialogService: DialogService,
        public auth: AuthenticationService,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private conversationService: ConversationService,
        private messageNotificationService: MessageNotificationService
    ) {
        this.subscriptions.push(this.auth.frontendUser$.subscribe((user) => {
            if (user?.userId) {
                this.oauthId = user.userId;
                this.userLoaded = true;
            }
            if (user?.userFirstName && user?.userLastName) {
                this.name = user.userFirstName + " " + user.userLastName
            }
        }))
    
        this.subscriptions.push(this.messageNotificationService.newMessagesCount$.subscribe(
            count => {
                this.loadNewMessagesCount();
            }
        ));

        this.subscriptions.push(this.messageNotificationService.checkInBackground$.subscribe(
            checkInBackground => {
                if (checkInBackground) {
                    this.startCheckingNewMessages();
                } else {
                    this.stopCheckingNewMessages();
                }
            }
        ));
    }

    ngOnInit(): void {
        // Removed model search initialization
        //this.loadNewMessagesCount();
    }

    loadNewMessagesCount() {
        if (this.userLoaded) {
            this.conversationService.getNumberOfNewMessages(this.oauthId).then((numberOfNewMessages) => {
                this.numberOfNewMessages = numberOfNewMessages.toString();
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.messageCheckInterval) {
            this.messageCheckInterval.unsubscribe();
        }
        this.stopCheckingNewMessages();
    }

    startCheckingNewMessages() {
        setTimeout(() => {
            this.messageCheckInterval = interval(60000).subscribe(() => {
                this.loadNewMessagesCount();
            });
        }, 1000);
    }

    stopCheckingNewMessages() {
        if (this.messageCheckInterval) {
            this.messageCheckInterval.unsubscribe();
        }
    }

    toGerman($event: MouseEvent) {
        $event.preventDefault();
        this.languageService.setLanguage('de')
    }

    toEnglish($event: MouseEvent) {
        $event.preventDefault();
        this.languageService.setLanguage('en')
    }

    handleLogout($event: MouseEvent): void {
        $event.preventDefault();
        this.auth.handleLogout();
    }

    openMessages() {
        // this.numberOfNewMessages = undefined;

        // Use combineLatest to observe multiple breakpoints
        const breakpointSubscription = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe(result => {
            let dialogWidth = '90vw';

            if (result.breakpoints[Breakpoints.XLarge]) {
                dialogWidth = '50vw';
            } else if (result.breakpoints[Breakpoints.Large]) {
                dialogWidth = '60vw';
            } else if (result.breakpoints[Breakpoints.Medium]) {
                dialogWidth = '70vw';
            }

            const ref = this.dialogService.open(ConversationWidgetComponent, {
                header: "Nachrichten",
                contentStyle: { overflow: 'hidden' },
                width: dialogWidth,
                baseZIndex: 10000,
                modal: false,
                maximizable: true,
                draggable: true,
                resizable: true
            });

            // Unsubscribe from the breakpoint observer when the dialog is closed
            ref.onClose.subscribe(() => {
                breakpointSubscription.unsubscribe();
            });
        });
    }
}
