/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantDataForConversation } from './ParticipantDataForConversation';
import {
    ParticipantDataForConversationFromJSON,
    ParticipantDataForConversationFromJSONTyped,
    ParticipantDataForConversationToJSON,
} from './ParticipantDataForConversation';
import type { RoleDataForConversation } from './RoleDataForConversation';
import {
    RoleDataForConversationFromJSON,
    RoleDataForConversationFromJSONTyped,
    RoleDataForConversationToJSON,
} from './RoleDataForConversation';

/**
 * 
 * @export
 * @interface ProjectDataForConversations
 */
export interface ProjectDataForConversations {
    /**
     * 
     * @type {string}
     * @memberof ProjectDataForConversations
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataForConversations
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataForConversations
     */
    producerOauthId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataForConversations
     */
    producerName?: string;
    /**
     * 
     * @type {Array<RoleDataForConversation>}
     * @memberof ProjectDataForConversations
     */
    roles?: Array<RoleDataForConversation>;
    /**
     * 
     * @type {Array<ParticipantDataForConversation>}
     * @memberof ProjectDataForConversations
     */
    additionalParticipants?: Array<ParticipantDataForConversation>;
}

/**
 * Check if a given object implements the ProjectDataForConversations interface.
 */
export function instanceOfProjectDataForConversations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectDataForConversationsFromJSON(json: any): ProjectDataForConversations {
    return ProjectDataForConversationsFromJSONTyped(json, false);
}

export function ProjectDataForConversationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDataForConversations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'producerOauthId': !exists(json, 'producerOauthId') ? undefined : json['producerOauthId'],
        'producerName': !exists(json, 'producerName') ? undefined : json['producerName'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleDataForConversationFromJSON)),
        'additionalParticipants': !exists(json, 'additionalParticipants') ? undefined : ((json['additionalParticipants'] as Array<any>).map(ParticipantDataForConversationFromJSON)),
    };
}

export function ProjectDataForConversationsToJSON(value?: ProjectDataForConversations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'name': value.name,
        'producerOauthId': value.producerOauthId,
        'producerName': value.producerName,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleDataForConversationToJSON)),
        'additionalParticipants': value.additionalParticipants === undefined ? undefined : ((value.additionalParticipants as Array<any>).map(ParticipantDataForConversationToJSON)),
    };
}

