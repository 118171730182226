/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectAppointmentCreateRequestDto
 */
export interface ProjectAppointmentCreateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    wdh?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    projectRoleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAppointmentCreateRequestDto
     */
    agencyId?: string;
}

/**
 * Check if a given object implements the ProjectAppointmentCreateRequestDto interface.
 */
export function instanceOfProjectAppointmentCreateRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function ProjectAppointmentCreateRequestDtoFromJSON(json: any): ProjectAppointmentCreateRequestDto {
    return ProjectAppointmentCreateRequestDtoFromJSONTyped(json, false);
}

export function ProjectAppointmentCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAppointmentCreateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'language': json['language'],
        'date': (new Date(json['date'])),
        'wdh': !exists(json, 'wdh') ? undefined : json['wdh'],
        'projectRoleId': !exists(json, 'projectRoleId') ? undefined : json['projectRoleId'],
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
    };
}

export function ProjectAppointmentCreateRequestDtoToJSON(value?: ProjectAppointmentCreateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'language': value.language,
        'date': (value.date.toISOString().substr(0,10)),
        'wdh': value.wdh,
        'projectRoleId': value.projectRoleId,
        'agencyId': value.agencyId,
    };
}

