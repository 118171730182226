/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccountInformation
 */
export interface BankAccountInformation {
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    bankIdentificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    accountHolder: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInformation
     */
    bic?: string;
}

/**
 * Check if a given object implements the BankAccountInformation interface.
 */
export function instanceOfBankAccountInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankIdentificationNumber" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "accountHolder" in value;
    isInstance = isInstance && "accountNumber" in value;

    return isInstance;
}

export function BankAccountInformationFromJSON(json: any): BankAccountInformation {
    return BankAccountInformationFromJSONTyped(json, false);
}

export function BankAccountInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankIdentificationNumber': json['bankIdentificationNumber'],
        'bankName': json['bankName'],
        'country': json['country'],
        'accountHolder': json['accountHolder'],
        'accountNumber': json['accountNumber'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
    };
}

export function BankAccountInformationToJSON(value?: BankAccountInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankIdentificationNumber': value.bankIdentificationNumber,
        'bankName': value.bankName,
        'country': value.country,
        'accountHolder': value.accountHolder,
        'accountNumber': value.accountNumber,
        'iban': value.iban,
        'bic': value.bic,
    };
}

