/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectModelFormValuesDto } from './ProjectModelFormValuesDto';
import {
    ProjectModelFormValuesDtoFromJSON,
    ProjectModelFormValuesDtoFromJSONTyped,
    ProjectModelFormValuesDtoToJSON,
} from './ProjectModelFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectRoleWithModels
 */
export interface ProjectRoleWithModels {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleWithModels
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoleWithModels
     */
    roleName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRoleWithModels
     */
    requestDates?: Array<string>;
    /**
     * 
     * @type {Array<ProjectModelFormValuesDto>}
     * @memberof ProjectRoleWithModels
     */
    projectModels?: Array<ProjectModelFormValuesDto>;
}

/**
 * Check if a given object implements the ProjectRoleWithModels interface.
 */
export function instanceOfProjectRoleWithModels(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectRoleWithModelsFromJSON(json: any): ProjectRoleWithModels {
    return ProjectRoleWithModelsFromJSONTyped(json, false);
}

export function ProjectRoleWithModelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleWithModels {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'requestDates': !exists(json, 'requestDates') ? undefined : json['requestDates'],
        'projectModels': !exists(json, 'projectModels') ? undefined : ((json['projectModels'] as Array<any>).map(ProjectModelFormValuesDtoFromJSON)),
    };
}

export function ProjectRoleWithModelsToJSON(value?: ProjectRoleWithModels | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'roleName': value.roleName,
        'requestDates': value.requestDates,
        'projectModels': value.projectModels === undefined ? undefined : ((value.projectModels as Array<any>).map(ProjectModelFormValuesDtoToJSON)),
    };
}

