/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';
import type { AppointmentDto } from './AppointmentDto';
import {
    AppointmentDtoFromJSON,
    AppointmentDtoFromJSONTyped,
    AppointmentDtoToJSON,
} from './AppointmentDto';
import type { BookingModelFormValuesDto } from './BookingModelFormValuesDto';
import {
    BookingModelFormValuesDtoFromJSON,
    BookingModelFormValuesDtoFromJSONTyped,
    BookingModelFormValuesDtoToJSON,
} from './BookingModelFormValuesDto';
import type { DetailsCastingFormValuesDto } from './DetailsCastingFormValuesDto';
import {
    DetailsCastingFormValuesDtoFromJSON,
    DetailsCastingFormValuesDtoFromJSONTyped,
    DetailsCastingFormValuesDtoToJSON,
} from './DetailsCastingFormValuesDto';
import type { ModelProductionDetailsFormValuesDto } from './ModelProductionDetailsFormValuesDto';
import {
    ModelProductionDetailsFormValuesDtoFromJSON,
    ModelProductionDetailsFormValuesDtoFromJSONTyped,
    ModelProductionDetailsFormValuesDtoToJSON,
} from './ModelProductionDetailsFormValuesDto';
import type { ProductionDayEventsDto } from './ProductionDayEventsDto';
import {
    ProductionDayEventsDtoFromJSON,
    ProductionDayEventsDtoFromJSONTyped,
    ProductionDayEventsDtoToJSON,
} from './ProductionDayEventsDto';
import type { ProjectModelBillingFormValuesDto } from './ProjectModelBillingFormValuesDto';
import {
    ProjectModelBillingFormValuesDtoFromJSON,
    ProjectModelBillingFormValuesDtoFromJSONTyped,
    ProjectModelBillingFormValuesDtoToJSON,
} from './ProjectModelBillingFormValuesDto';
import type { RoleDetailsFormValuesDto } from './RoleDetailsFormValuesDto';
import {
    RoleDetailsFormValuesDtoFromJSON,
    RoleDetailsFormValuesDtoFromJSONTyped,
    RoleDetailsFormValuesDtoToJSON,
} from './RoleDetailsFormValuesDto';
import type { TravelCostListFormValuesDto } from './TravelCostListFormValuesDto';
import {
    TravelCostListFormValuesDtoFromJSON,
    TravelCostListFormValuesDtoFromJSONTyped,
    TravelCostListFormValuesDtoToJSON,
} from './TravelCostListFormValuesDto';

/**
 * 
 * @export
 * @interface ProjectModelFormValuesDto
 */
export interface ProjectModelFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    projectModelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     * @deprecated
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectModelFormValuesDto
     */
    statusOptions?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelFormValuesDto
     */
    dailyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelFormValuesDto
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    buyoutType?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectModelFormValuesDto
     */
    buyoutValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    externalNote?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    roleName?: string;
    /**
     * 
     * @type {ModelProductionDetailsFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    modelProductionDetailsFormValuesDto?: ModelProductionDetailsFormValuesDto;
    /**
     * 
     * @type {BookingModelFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    bookingModelFormValuesDto?: BookingModelFormValuesDto;
    /**
     * 
     * @type {RoleDetailsFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    roleDetailsFormValuesDto?: RoleDetailsFormValuesDto;
    /**
     * 
     * @type {DetailsCastingFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    detailsCastingFormValuesDto?: DetailsCastingFormValuesDto;
    /**
     * 
     * @type {ProjectModelBillingFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    projectModelBillingFormValuesDto?: ProjectModelBillingFormValuesDto;
    /**
     * 
     * @type {TravelCostListFormValuesDto}
     * @memberof ProjectModelFormValuesDto
     */
    travelCostFormValuesDto?: TravelCostListFormValuesDto;
    /**
     * 
     * @type {AdminValue}
     * @memberof ProjectModelFormValuesDto
     */
    selectedStatus?: AdminValue;
    /**
     * 
     * @type {Array<AppointmentDto>}
     * @memberof ProjectModelFormValuesDto
     */
    appointments?: Array<AppointmentDto>;
    /**
     * 
     * @type {ProductionDayEventsDto}
     * @memberof ProjectModelFormValuesDto
     */
    productionDayEvents?: ProductionDayEventsDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectModelFormValuesDto
     */
    isNewlyAdded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectModelFormValuesDto
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the ProjectModelFormValuesDto interface.
 */
export function instanceOfProjectModelFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectModelFormValuesDtoFromJSON(json: any): ProjectModelFormValuesDto {
    return ProjectModelFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectModelFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectModelFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectModelId': !exists(json, 'projectModelId') ? undefined : json['projectModelId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusOptions': !exists(json, 'statusOptions') ? undefined : json['statusOptions'],
        'dailyRate': !exists(json, 'dailyRate') ? undefined : json['dailyRate'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'buyoutType': !exists(json, 'buyoutType') ? undefined : json['buyoutType'],
        'buyoutValue': !exists(json, 'buyoutValue') ? undefined : json['buyoutValue'],
        'externalNote': !exists(json, 'externalNote') ? undefined : json['externalNote'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'modelProductionDetailsFormValuesDto': !exists(json, 'modelProductionDetailsFormValuesDto') ? undefined : ModelProductionDetailsFormValuesDtoFromJSON(json['modelProductionDetailsFormValuesDto']),
        'bookingModelFormValuesDto': !exists(json, 'bookingModelFormValuesDto') ? undefined : BookingModelFormValuesDtoFromJSON(json['bookingModelFormValuesDto']),
        'roleDetailsFormValuesDto': !exists(json, 'roleDetailsFormValuesDto') ? undefined : RoleDetailsFormValuesDtoFromJSON(json['roleDetailsFormValuesDto']),
        'detailsCastingFormValuesDto': !exists(json, 'detailsCastingFormValuesDto') ? undefined : DetailsCastingFormValuesDtoFromJSON(json['detailsCastingFormValuesDto']),
        'projectModelBillingFormValuesDto': !exists(json, 'projectModelBillingFormValuesDto') ? undefined : ProjectModelBillingFormValuesDtoFromJSON(json['projectModelBillingFormValuesDto']),
        'travelCostFormValuesDto': !exists(json, 'travelCostFormValuesDto') ? undefined : TravelCostListFormValuesDtoFromJSON(json['travelCostFormValuesDto']),
        'selectedStatus': !exists(json, 'selectedStatus') ? undefined : AdminValueFromJSON(json['selectedStatus']),
        'appointments': !exists(json, 'appointments') ? undefined : ((json['appointments'] as Array<any>).map(AppointmentDtoFromJSON)),
        'productionDayEvents': !exists(json, 'productionDayEvents') ? undefined : ProductionDayEventsDtoFromJSON(json['productionDayEvents']),
        'isNewlyAdded': !exists(json, 'isNewlyAdded') ? undefined : json['isNewlyAdded'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ProjectModelFormValuesDtoToJSON(value?: ProjectModelFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectModelId': value.projectModelId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'fullName': value.fullName,
        'modelId': value.modelId,
        'image': value.image,
        'mobile': value.mobile,
        'phone': value.phone,
        'email': value.email,
        'status': value.status,
        'statusOptions': value.statusOptions,
        'dailyRate': value.dailyRate,
        'fee': value.fee,
        'note': value.note,
        'buyoutType': value.buyoutType,
        'buyoutValue': value.buyoutValue,
        'externalNote': value.externalNote,
        'roleId': value.roleId,
        'roleName': value.roleName,
        'modelProductionDetailsFormValuesDto': ModelProductionDetailsFormValuesDtoToJSON(value.modelProductionDetailsFormValuesDto),
        'bookingModelFormValuesDto': BookingModelFormValuesDtoToJSON(value.bookingModelFormValuesDto),
        'roleDetailsFormValuesDto': RoleDetailsFormValuesDtoToJSON(value.roleDetailsFormValuesDto),
        'detailsCastingFormValuesDto': DetailsCastingFormValuesDtoToJSON(value.detailsCastingFormValuesDto),
        'projectModelBillingFormValuesDto': ProjectModelBillingFormValuesDtoToJSON(value.projectModelBillingFormValuesDto),
        'travelCostFormValuesDto': TravelCostListFormValuesDtoToJSON(value.travelCostFormValuesDto),
        'selectedStatus': AdminValueToJSON(value.selectedStatus),
        'appointments': value.appointments === undefined ? undefined : ((value.appointments as Array<any>).map(AppointmentDtoToJSON)),
        'productionDayEvents': ProductionDayEventsDtoToJSON(value.productionDayEvents),
        'isNewlyAdded': value.isNewlyAdded,
        'oauthId': value.oauthId,
    };
}

