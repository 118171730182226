/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectAdditionalParticipantFormValuesDto
 */
export interface ProjectAdditionalParticipantFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    customerSuffix?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    employeeFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    employeeLastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    hasAdminRights?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    employeeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAdditionalParticipantFormValuesDto
     */
    oauthId?: string;
}

/**
 * Check if a given object implements the ProjectAdditionalParticipantFormValuesDto interface.
 */
export function instanceOfProjectAdditionalParticipantFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectAdditionalParticipantFormValuesDtoFromJSON(json: any): ProjectAdditionalParticipantFormValuesDto {
    return ProjectAdditionalParticipantFormValuesDtoFromJSONTyped(json, false);
}

export function ProjectAdditionalParticipantFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAdditionalParticipantFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerSuffix': !exists(json, 'customerSuffix') ? undefined : json['customerSuffix'],
        'employeeId': !exists(json, 'employeeId') ? undefined : json['employeeId'],
        'employeeFirstName': !exists(json, 'employeeFirstName') ? undefined : json['employeeFirstName'],
        'employeeLastName': !exists(json, 'employeeLastName') ? undefined : json['employeeLastName'],
        'hasAdminRights': !exists(json, 'hasAdminRights') ? undefined : json['hasAdminRights'],
        'employeeEmail': !exists(json, 'employeeEmail') ? undefined : json['employeeEmail'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'oauthId': !exists(json, 'oauthId') ? undefined : json['oauthId'],
    };
}

export function ProjectAdditionalParticipantFormValuesDtoToJSON(value?: ProjectAdditionalParticipantFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'customerSuffix': value.customerSuffix,
        'employeeId': value.employeeId,
        'employeeFirstName': value.employeeFirstName,
        'employeeLastName': value.employeeLastName,
        'hasAdminRights': value.hasAdminRights,
        'employeeEmail': value.employeeEmail,
        'username': value.username,
        'oauthId': value.oauthId,
    };
}

