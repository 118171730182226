/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PortfolioImage } from './PortfolioImage';
import {
    PortfolioImageFromJSON,
    PortfolioImageFromJSONTyped,
    PortfolioImageToJSON,
} from './PortfolioImage';
import type { PortfolioVideo } from './PortfolioVideo';
import {
    PortfolioVideoFromJSON,
    PortfolioVideoFromJSONTyped,
    PortfolioVideoToJSON,
} from './PortfolioVideo';

/**
 * 
 * @export
 * @interface ModelPortfolioDto
 */
export interface ModelPortfolioDto {
    /**
     * 
     * @type {string}
     * @memberof ModelPortfolioDto
     */
    modelId: string;
    /**
     * 
     * @type {Array<PortfolioImage>}
     * @memberof ModelPortfolioDto
     */
    masterImages: Array<PortfolioImage>;
    /**
     * 
     * @type {Array<PortfolioImage>}
     * @memberof ModelPortfolioDto
     */
    polaroidImages: Array<PortfolioImage>;
    /**
     * 
     * @type {Array<PortfolioImage>}
     * @memberof ModelPortfolioDto
     */
    specialImages: Array<PortfolioImage>;
    /**
     * 
     * @type {Array<PortfolioImage>}
     * @memberof ModelPortfolioDto
     */
    vitaImages: Array<PortfolioImage>;
    /**
     * 
     * @type {Array<PortfolioVideo>}
     * @memberof ModelPortfolioDto
     */
    videos: Array<PortfolioVideo>;
}

/**
 * Check if a given object implements the ModelPortfolioDto interface.
 */
export function instanceOfModelPortfolioDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "masterImages" in value;
    isInstance = isInstance && "polaroidImages" in value;
    isInstance = isInstance && "specialImages" in value;
    isInstance = isInstance && "vitaImages" in value;
    isInstance = isInstance && "videos" in value;

    return isInstance;
}

export function ModelPortfolioDtoFromJSON(json: any): ModelPortfolioDto {
    return ModelPortfolioDtoFromJSONTyped(json, false);
}

export function ModelPortfolioDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelPortfolioDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'masterImages': ((json['masterImages'] as Array<any>).map(PortfolioImageFromJSON)),
        'polaroidImages': ((json['polaroidImages'] as Array<any>).map(PortfolioImageFromJSON)),
        'specialImages': ((json['specialImages'] as Array<any>).map(PortfolioImageFromJSON)),
        'vitaImages': ((json['vitaImages'] as Array<any>).map(PortfolioImageFromJSON)),
        'videos': ((json['videos'] as Array<any>).map(PortfolioVideoFromJSON)),
    };
}

export function ModelPortfolioDtoToJSON(value?: ModelPortfolioDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'masterImages': ((value.masterImages as Array<any>).map(PortfolioImageToJSON)),
        'polaroidImages': ((value.polaroidImages as Array<any>).map(PortfolioImageToJSON)),
        'specialImages': ((value.specialImages as Array<any>).map(PortfolioImageToJSON)),
        'vitaImages': ((value.vitaImages as Array<any>).map(PortfolioImageToJSON)),
        'videos': ((value.videos as Array<any>).map(PortfolioVideoToJSON)),
    };
}

