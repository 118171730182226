import { ProjectLocationFormValuesDto } from "libs/api-fetch/src/models/ProjectLocationFormValuesDto";
import { ProjectMotifFormValuesDto } from "libs/api-fetch/src/models/ProjectMotifFormValuesDto";

export namespace ProjectEditLocationActions {
    export class LoadLocations {
        static readonly type = '[Project Edit Locations] Load Locations';
        constructor(public projectId: string) { }
    }

    export class ChangeLocation {
        static readonly type = '[Project Edit Locations] Change Location';
        constructor(public projectId: string, public location: ProjectLocationFormValuesDto) { }
    }

    export class AddLocation {
        static readonly type = '[Project Edit Locations] Add Location';
        constructor(public projectId: string, public locationName: string) { }
    }

    export class DeleteLocation {
        static readonly type = '[Project Edit Locations] Delete Location';
        constructor(public projectId: string, public locationId: string) { }
    }

    export class ChangeMotif {
        static readonly type = '[Project Edit Locations] Change Motif';
        constructor(public projectId: string, public motif: ProjectMotifFormValuesDto) { }
    }

    export class AddMotif {
        static readonly type = '[Project Edit Locations] Add Motif';
        constructor(public projectId: string, public name: string, public locationName: string) { }
    }

    export class DeleteMotif {
        static readonly type = '[Project Edit Locations] Delete Motif';
        constructor(public projectId: string, public motifId: string) { }
    }

    export class SelectLocation {
        static readonly type = '[Project Edit Locations] Select Location';
        constructor(public projectId: string, public location: ProjectLocationFormValuesDto) { }
    }

    export class SelectMotif {
        static readonly type = '[Project Edit Locations] Select Motif';
        constructor(public projectId: string, public motif: ProjectMotifFormValuesDto) { }
    }
}   