import { ProjectEditStateModel } from '../project-edit.model';

export namespace ProjectEditBaseActions {

    export class SetLoading {
        static readonly type = '[ProjectEdit] Set Loading';
        constructor(public loading: boolean) { }
    }

    export class UpdateLanguage {
        static readonly type = '[ProjectEdit] Update Language';
        constructor(public language: string) { }
    }

    export class UpdateState {
        static readonly type = '[ProjectEdit] Update State';
        constructor(public payload: Partial<ProjectEditStateModel>) { }
    }

    export class ResetState {
        static readonly type = '[ProjectEdit] Reset State';
    }

    export class LoadProject {
        static readonly type = '[ProjectEdit] Load Project';
        constructor(public projectId: string) { }
    }

}