/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Resource,
} from '../models';
import {
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface AddSpecialsRequest {
    resource: Resource;
}

export interface DeleteSpecialsRequest {
    id: number;
}

export interface GetAllSpecialsRequest {
    sortBy?: string;
}

export interface GetSpecialsByLanguageRequest {
    language: string;
}

export interface ReorderSpecialsRequest {
    resource: Array<Resource>;
}

/**
 * 
 */
export class SpecialsControllerApi extends runtime.BaseAPI {

    /**
     */
    async addSpecialsRaw(requestParameters: AddSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling addSpecials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/specials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     */
    async addSpecials(requestParameters: AddSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.addSpecialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSpecialsRaw(requestParameters: DeleteSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSpecials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/specials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSpecials(requestParameters: DeleteSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSpecialsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAllSpecialsRaw(requestParameters: GetAllSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Resource>>> {
        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/specials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     */
    async getAllSpecials(requestParameters: GetAllSpecialsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Resource>> {
        const response = await this.getAllSpecialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSpecialsByLanguageRaw(requestParameters: GetSpecialsByLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getSpecialsByLanguage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/admin/specials/{language}/names`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getSpecialsByLanguage(requestParameters: GetSpecialsByLanguageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getSpecialsByLanguageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reorderSpecialsRaw(requestParameters: ReorderSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling reorderSpecials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/specials/reorder`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.resource.map(ResourceToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reorderSpecials(requestParameters: ReorderSpecialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reorderSpecialsRaw(requestParameters, initOverrides);
    }

}
