/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    tiktok?: string;
}

/**
 * Check if a given object implements the Contact interface.
 */
export function instanceOfContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mobile" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile': json['mobile'],
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'tiktok': !exists(json, 'tiktok') ? undefined : json['tiktok'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile': value.mobile,
        'email': value.email,
        'phone': value.phone,
        'website': value.website,
        'instagram': value.instagram,
        'fax': value.fax,
        'tiktok': value.tiktok,
    };
}

