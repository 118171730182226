/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantDataForConversation } from './ParticipantDataForConversation';
import {
    ParticipantDataForConversationFromJSON,
    ParticipantDataForConversationFromJSONTyped,
    ParticipantDataForConversationToJSON,
} from './ParticipantDataForConversation';

/**
 * 
 * @export
 * @interface RoleDataForConversation
 */
export interface RoleDataForConversation {
    /**
     * 
     * @type {string}
     * @memberof RoleDataForConversation
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDataForConversation
     */
    name?: string;
    /**
     * 
     * @type {Array<ParticipantDataForConversation>}
     * @memberof RoleDataForConversation
     */
    participants?: Array<ParticipantDataForConversation>;
    /**
     * 
     * @type {number}
     * @memberof RoleDataForConversation
     */
    numberOfNewMessages?: number;
}

/**
 * Check if a given object implements the RoleDataForConversation interface.
 */
export function instanceOfRoleDataForConversation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RoleDataForConversationFromJSON(json: any): RoleDataForConversation {
    return RoleDataForConversationFromJSONTyped(json, false);
}

export function RoleDataForConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDataForConversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(ParticipantDataForConversationFromJSON)),
        'numberOfNewMessages': !exists(json, 'numberOfNewMessages') ? undefined : json['numberOfNewMessages'],
    };
}

export function RoleDataForConversationToJSON(value?: RoleDataForConversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'name': value.name,
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(ParticipantDataForConversationToJSON)),
        'numberOfNewMessages': value.numberOfNewMessages,
    };
}

