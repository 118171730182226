/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConversationId } from './ConversationId';
import {
    ConversationIdFromJSON,
    ConversationIdFromJSONTyped,
    ConversationIdToJSON,
} from './ConversationId';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';
import type { MessageTracking } from './MessageTracking';
import {
    MessageTrackingFromJSON,
    MessageTrackingFromJSONTyped,
    MessageTrackingToJSON,
} from './MessageTracking';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {ConversationId}
     * @memberof Conversation
     */
    conversationId: ConversationId;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    conversationTitle: string;
    /**
     * 
     * @type {boolean}
     * @memberof Conversation
     */
    isGroupChat: boolean;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    roleId: string;
    /**
     * 
     * @type {Array<Participant>}
     * @memberof Conversation
     */
    participants: Array<Participant>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Conversation
     */
    messages: Array<Message>;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    lastEditedAt: Date;
    /**
     * 
     * @type {Array<MessageTracking>}
     * @memberof Conversation
     */
    messageTrackingList: Array<MessageTracking>;
}

/**
 * Check if a given object implements the Conversation interface.
 */
export function instanceOfConversation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "conversationId" in value;
    isInstance = isInstance && "conversationTitle" in value;
    isInstance = isInstance && "isGroupChat" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "roleId" in value;
    isInstance = isInstance && "participants" in value;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "lastEditedAt" in value;
    isInstance = isInstance && "messageTrackingList" in value;

    return isInstance;
}

export function ConversationFromJSON(json: any): Conversation {
    return ConversationFromJSONTyped(json, false);
}

export function ConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Conversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationId': ConversationIdFromJSON(json['conversationId']),
        'conversationTitle': json['conversationTitle'],
        'isGroupChat': json['isGroupChat'],
        'projectId': json['projectId'],
        'roleId': json['roleId'],
        'participants': ((json['participants'] as Array<any>).map(ParticipantFromJSON)),
        'messages': ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'lastEditedAt': (new Date(json['lastEditedAt'])),
        'messageTrackingList': ((json['messageTrackingList'] as Array<any>).map(MessageTrackingFromJSON)),
    };
}

export function ConversationToJSON(value?: Conversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationId': ConversationIdToJSON(value.conversationId),
        'conversationTitle': value.conversationTitle,
        'isGroupChat': value.isGroupChat,
        'projectId': value.projectId,
        'roleId': value.roleId,
        'participants': ((value.participants as Array<any>).map(ParticipantToJSON)),
        'messages': ((value.messages as Array<any>).map(MessageToJSON)),
        'lastEditedAt': (value.lastEditedAt.toISOString()),
        'messageTrackingList': ((value.messageTrackingList as Array<any>).map(MessageTrackingToJSON)),
    };
}

