/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAdditionalParticipantRequestDto,
  AddExternalParticipantRequestDto,
  AddModelToProjectRoleResponseDto,
  AddModelToSelectionCommand,
  AddNewBuyOutPerModelRequest,
  AddProductionDetailsEventCommand,
  AddRoleRequestDto,
  AllActiveProjectsFromCustomerResponseDto,
  BookingProducerFormValuesDto,
  BudgetFormValuesDto,
  BuyOutPerModelFormValuesDto,
  BuyOutPerModelListFormValuesDto,
  ChangeModelBookingInformationRequestDto,
  ChangeProjectModelRequestDto,
  ChangeRoleDetailsRequestDto,
  ChangeUsageTypeRequestDto,
  CreateProjectLocationRequestDto,
  CreateProjectMotifRequestDto,
  CustomerAndEmployeesListForProjectsDto,
  CustomerEmployeeListForProject,
  CustomerNameListForProject,
  DeleteProductionDetailsEventCommand,
  DeleteProjectRoleRequestDatesCommand,
  DemoPdfRequestDto,
  DetailsCastingFormValuesDtoList,
  EmployeeIdDto,
  ExecutorFormValuesDto,
  ExternalParticipantFormValuesDto,
  ExtraCostsListFormValuesDto,
  FilenameDto,
  FinalCustomerFormValuesDto,
  GetProductionDetailsEventsCommand,
  GetProjectStatusResponse,
  ModelProductionDetailsEventFormValuesDto,
  ProductionDetailsFormValuesDto,
  Project,
  ProjectAdditionalParticipant,
  ProjectAdditionalParticipantFormValuesDto,
  ProjectAppointmentCreateRequestDto,
  ProjectBillingAddresseeFormValuesDto,
  ProjectBillingFormValuesDto,
  ProjectBillingInvoiceFormValuesDto,
  ProjectBillingSumFormValuesDto,
  ProjectBuyOutFromValuesDto,
  ProjectCreateRequestDto,
  ProjectFormValuesDto,
  ProjectLocation,
  ProjectLocationFormValuesDto,
  ProjectLocationsAndMotifsFormValuesDto,
  ProjectModelBillingFormValuesDto,
  ProjectModelCurrentSelection,
  ProjectModelFormValuesDto,
  ProjectModelListFormValuesDto,
  ProjectModelProductionDetailsEvent,
  ProjectMotif,
  ProjectMotifFormValuesDto,
  ProjectNameFormValuesDto,
  ProjectNamesForMessengerDto,
  ProjectParticipantDataForMessenger,
  ProjectParticipantDataForMessengerGrouped,
  ProjectProducer,
  ProjectProducerFormValueDto,
  ProjectRole,
  ProjectRoleFormValuesDto,
  ProjectRoleSearchItemsDto,
  ProjectSearchItemDto,
  ProjectSearchItemsDto,
  ProjectStatusFormValuesDto,
  ProjectTableItemDto,
  ProjectTableItemsDto,
  ProjectTotalModelFees,
  ProtocolEntryCreateRequestDto,
  ProtocolFormValuesDto,
  RemoveModelFromSelectionCommand,
  TravelCostListFormValuesDto,
  UseCaseResult,
} from '../models';
import {
    AddAdditionalParticipantRequestDtoFromJSON,
    AddAdditionalParticipantRequestDtoToJSON,
    AddExternalParticipantRequestDtoFromJSON,
    AddExternalParticipantRequestDtoToJSON,
    AddModelToProjectRoleResponseDtoFromJSON,
    AddModelToProjectRoleResponseDtoToJSON,
    AddModelToSelectionCommandFromJSON,
    AddModelToSelectionCommandToJSON,
    AddNewBuyOutPerModelRequestFromJSON,
    AddNewBuyOutPerModelRequestToJSON,
    AddProductionDetailsEventCommandFromJSON,
    AddProductionDetailsEventCommandToJSON,
    AddRoleRequestDtoFromJSON,
    AddRoleRequestDtoToJSON,
    AllActiveProjectsFromCustomerResponseDtoFromJSON,
    AllActiveProjectsFromCustomerResponseDtoToJSON,
    BookingProducerFormValuesDtoFromJSON,
    BookingProducerFormValuesDtoToJSON,
    BudgetFormValuesDtoFromJSON,
    BudgetFormValuesDtoToJSON,
    BuyOutPerModelFormValuesDtoFromJSON,
    BuyOutPerModelFormValuesDtoToJSON,
    BuyOutPerModelListFormValuesDtoFromJSON,
    BuyOutPerModelListFormValuesDtoToJSON,
    ChangeModelBookingInformationRequestDtoFromJSON,
    ChangeModelBookingInformationRequestDtoToJSON,
    ChangeProjectModelRequestDtoFromJSON,
    ChangeProjectModelRequestDtoToJSON,
    ChangeRoleDetailsRequestDtoFromJSON,
    ChangeRoleDetailsRequestDtoToJSON,
    ChangeUsageTypeRequestDtoFromJSON,
    ChangeUsageTypeRequestDtoToJSON,
    CreateProjectLocationRequestDtoFromJSON,
    CreateProjectLocationRequestDtoToJSON,
    CreateProjectMotifRequestDtoFromJSON,
    CreateProjectMotifRequestDtoToJSON,
    CustomerAndEmployeesListForProjectsDtoFromJSON,
    CustomerAndEmployeesListForProjectsDtoToJSON,
    CustomerEmployeeListForProjectFromJSON,
    CustomerEmployeeListForProjectToJSON,
    CustomerNameListForProjectFromJSON,
    CustomerNameListForProjectToJSON,
    DeleteProductionDetailsEventCommandFromJSON,
    DeleteProductionDetailsEventCommandToJSON,
    DeleteProjectRoleRequestDatesCommandFromJSON,
    DeleteProjectRoleRequestDatesCommandToJSON,
    DemoPdfRequestDtoFromJSON,
    DemoPdfRequestDtoToJSON,
    DetailsCastingFormValuesDtoListFromJSON,
    DetailsCastingFormValuesDtoListToJSON,
    EmployeeIdDtoFromJSON,
    EmployeeIdDtoToJSON,
    ExecutorFormValuesDtoFromJSON,
    ExecutorFormValuesDtoToJSON,
    ExternalParticipantFormValuesDtoFromJSON,
    ExternalParticipantFormValuesDtoToJSON,
    ExtraCostsListFormValuesDtoFromJSON,
    ExtraCostsListFormValuesDtoToJSON,
    FilenameDtoFromJSON,
    FilenameDtoToJSON,
    FinalCustomerFormValuesDtoFromJSON,
    FinalCustomerFormValuesDtoToJSON,
    GetProductionDetailsEventsCommandFromJSON,
    GetProductionDetailsEventsCommandToJSON,
    GetProjectStatusResponseFromJSON,
    GetProjectStatusResponseToJSON,
    ModelProductionDetailsEventFormValuesDtoFromJSON,
    ModelProductionDetailsEventFormValuesDtoToJSON,
    ProductionDetailsFormValuesDtoFromJSON,
    ProductionDetailsFormValuesDtoToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectAdditionalParticipantFromJSON,
    ProjectAdditionalParticipantToJSON,
    ProjectAdditionalParticipantFormValuesDtoFromJSON,
    ProjectAdditionalParticipantFormValuesDtoToJSON,
    ProjectAppointmentCreateRequestDtoFromJSON,
    ProjectAppointmentCreateRequestDtoToJSON,
    ProjectBillingAddresseeFormValuesDtoFromJSON,
    ProjectBillingAddresseeFormValuesDtoToJSON,
    ProjectBillingFormValuesDtoFromJSON,
    ProjectBillingFormValuesDtoToJSON,
    ProjectBillingInvoiceFormValuesDtoFromJSON,
    ProjectBillingInvoiceFormValuesDtoToJSON,
    ProjectBillingSumFormValuesDtoFromJSON,
    ProjectBillingSumFormValuesDtoToJSON,
    ProjectBuyOutFromValuesDtoFromJSON,
    ProjectBuyOutFromValuesDtoToJSON,
    ProjectCreateRequestDtoFromJSON,
    ProjectCreateRequestDtoToJSON,
    ProjectFormValuesDtoFromJSON,
    ProjectFormValuesDtoToJSON,
    ProjectLocationFromJSON,
    ProjectLocationToJSON,
    ProjectLocationFormValuesDtoFromJSON,
    ProjectLocationFormValuesDtoToJSON,
    ProjectLocationsAndMotifsFormValuesDtoFromJSON,
    ProjectLocationsAndMotifsFormValuesDtoToJSON,
    ProjectModelBillingFormValuesDtoFromJSON,
    ProjectModelBillingFormValuesDtoToJSON,
    ProjectModelCurrentSelectionFromJSON,
    ProjectModelCurrentSelectionToJSON,
    ProjectModelFormValuesDtoFromJSON,
    ProjectModelFormValuesDtoToJSON,
    ProjectModelListFormValuesDtoFromJSON,
    ProjectModelListFormValuesDtoToJSON,
    ProjectModelProductionDetailsEventFromJSON,
    ProjectModelProductionDetailsEventToJSON,
    ProjectMotifFromJSON,
    ProjectMotifToJSON,
    ProjectMotifFormValuesDtoFromJSON,
    ProjectMotifFormValuesDtoToJSON,
    ProjectNameFormValuesDtoFromJSON,
    ProjectNameFormValuesDtoToJSON,
    ProjectNamesForMessengerDtoFromJSON,
    ProjectNamesForMessengerDtoToJSON,
    ProjectParticipantDataForMessengerFromJSON,
    ProjectParticipantDataForMessengerToJSON,
    ProjectParticipantDataForMessengerGroupedFromJSON,
    ProjectParticipantDataForMessengerGroupedToJSON,
    ProjectProducerFromJSON,
    ProjectProducerToJSON,
    ProjectProducerFormValueDtoFromJSON,
    ProjectProducerFormValueDtoToJSON,
    ProjectRoleFromJSON,
    ProjectRoleToJSON,
    ProjectRoleFormValuesDtoFromJSON,
    ProjectRoleFormValuesDtoToJSON,
    ProjectRoleSearchItemsDtoFromJSON,
    ProjectRoleSearchItemsDtoToJSON,
    ProjectSearchItemDtoFromJSON,
    ProjectSearchItemDtoToJSON,
    ProjectSearchItemsDtoFromJSON,
    ProjectSearchItemsDtoToJSON,
    ProjectStatusFormValuesDtoFromJSON,
    ProjectStatusFormValuesDtoToJSON,
    ProjectTableItemDtoFromJSON,
    ProjectTableItemDtoToJSON,
    ProjectTableItemsDtoFromJSON,
    ProjectTableItemsDtoToJSON,
    ProjectTotalModelFeesFromJSON,
    ProjectTotalModelFeesToJSON,
    ProtocolEntryCreateRequestDtoFromJSON,
    ProtocolEntryCreateRequestDtoToJSON,
    ProtocolFormValuesDtoFromJSON,
    ProtocolFormValuesDtoToJSON,
    RemoveModelFromSelectionCommandFromJSON,
    RemoveModelFromSelectionCommandToJSON,
    TravelCostListFormValuesDtoFromJSON,
    TravelCostListFormValuesDtoToJSON,
    UseCaseResultFromJSON,
    UseCaseResultToJSON,
} from '../models';

export interface AddAdditionalParticipantRequest {
    projectId: string;
    addAdditionalParticipantRequestDto: AddAdditionalParticipantRequestDto;
}

export interface AddBuyOutPerModelRequest {
    projectId: string;
    addNewBuyOutPerModelRequest: AddNewBuyOutPerModelRequest;
}

export interface AddExternalParticipantRequest {
    projectId: string;
    addExternalParticipantRequestDto: AddExternalParticipantRequestDto;
}

export interface AddModelToCurrentSelectionRequest {
    addModelToSelectionCommand: AddModelToSelectionCommand;
}

export interface AddModelToProjectRoleRequest {
    projectId: string;
    lang: string;
    roleId: string;
    modelId: string;
}

export interface AddProductionDaysToProjectModelRequest {
    projectId: string;
    projectModelId: string;
    addProductionDetailsEventCommand: AddProductionDetailsEventCommand;
}

export interface AddRoleRequest {
    projectId: string;
    addRoleRequestDto: AddRoleRequestDto;
}

export interface ChangeAdminRightsAdditionalParticipantRequest {
    projectId: string;
    employeeIdDto: EmployeeIdDto;
}

export interface ChangeAdminRightsExternalParticipantRequest {
    projectId: string;
    employeeIdDto: EmployeeIdDto;
}

export interface ChangeBillingAddresseeRequest {
    projectId: string;
    projectBillingAddresseeFormValuesDto: ProjectBillingAddresseeFormValuesDto;
}

export interface ChangeBookingModelsRequest {
    projectId: string;
    changeModelBookingInformationRequestDto: ChangeModelBookingInformationRequestDto;
}

export interface ChangeBookingProducerRequest {
    projectId: string;
    bookingProducerFormValuesDto: BookingProducerFormValuesDto;
}

export interface ChangeBudgetRequest {
    projectId: string;
    budgetFormValuesDto: BudgetFormValuesDto;
}

export interface ChangeBuyOutRequest {
    projectId: string;
    projectBuyOutFromValuesDto: ProjectBuyOutFromValuesDto;
}

export interface ChangeBuyOutPerModelRequest {
    projectId: string;
    roleId: string;
    buyOutPerModelFormValuesDto: BuyOutPerModelFormValuesDto;
}

export interface ChangeExecutorRequest {
    projectId: string;
    executorFormValuesDto: ExecutorFormValuesDto;
}

export interface ChangeExtraCostsRequest {
    projectId: string;
    extraCostsListFormValuesDto: ExtraCostsListFormValuesDto;
}

export interface ChangeFinalCustomerRequest {
    projectId: string;
    finalCustomerFormValuesDto: FinalCustomerFormValuesDto;
}

export interface ChangeLocationRequest {
    projectId: string;
    projectLocationFormValuesDto: ProjectLocationFormValuesDto;
}

export interface ChangeMotifRequest {
    projectId: string;
    projectMotifFormValuesDto: ProjectMotifFormValuesDto;
}

export interface ChangeProductionDetailsRequest {
    projectId: string;
    productionDetailsFormValuesDto: ProductionDetailsFormValuesDto;
}

export interface ChangeProjectBillingRequest {
    projectId: string;
    projectBillingFormValuesDto: ProjectBillingFormValuesDto;
}

export interface ChangeProjectBillingSumRequest {
    projectId: string;
    projectBillingSumFormValuesDto: ProjectBillingSumFormValuesDto;
}

export interface ChangeProjectInvoiceRequest {
    projectId: string;
    projectBillingInvoiceFormValuesDto: ProjectBillingInvoiceFormValuesDto;
}

export interface ChangeProjectModelRequest {
    projectId: string;
    modelId: string;
    roleId: string;
    changeProjectModelRequestDto: ChangeProjectModelRequestDto;
}

export interface ChangeProjectModelBillingRequest {
    projectId: string;
    modelId: string;
    roleId: string;
    projectModelBillingFormValuesDto: ProjectModelBillingFormValuesDto;
}

export interface ChangeProjectModelTravelCostsRequest {
    projectId: string;
    modelId: string;
    roleId: string;
    travelCostListFormValuesDto: TravelCostListFormValuesDto;
}

export interface ChangeProjectNameRequest {
    projectId: string;
    projectNameFormValuesDto: ProjectNameFormValuesDto;
}

export interface ChangeProjectProducerRequest {
    projectId: string;
    projectProducerFormValueDto: ProjectProducerFormValueDto;
}

export interface ChangeProjectStatusRequest {
    projectId: string;
    projectStatusFormValuesDto: ProjectStatusFormValuesDto;
}

export interface ChangeRoleDetailsRequest {
    projectId: string;
    changeRoleDetailsRequestDto: ChangeRoleDetailsRequestDto;
}

export interface ChangeUsageTypeRequest {
    projectId: string;
    changeUsageTypeRequestDto: ChangeUsageTypeRequestDto;
}

export interface ConfirmBookingByModelRequest {
    projectId: string;
    projectModelId: string;
}

export interface CreateProjectRequest {
    projectCreateRequestDto: ProjectCreateRequestDto;
}

export interface CreateProjectBillRequest {
    projectId: string;
}

export interface CreateProjectLocationRequest {
    projectId: string;
    createProjectLocationRequestDto: CreateProjectLocationRequestDto;
}

export interface CreateProjectMotifRequest {
    projectId: string;
    createProjectMotifRequestDto: CreateProjectMotifRequestDto;
}

export interface CreateProtocolEntryRequest {
    protocolEntryCreateRequestDto: ProtocolEntryCreateRequestDto;
}

export interface CreateRequestTimeframeAppointmentRequest {
    language: string;
    projectId: string;
    roleId: string;
    projectAppointmentCreateRequestDto: ProjectAppointmentCreateRequestDto;
}

export interface DeleteAdditionalParticipantRequest {
    projectId: string;
    employeeIdDto: EmployeeIdDto;
}

export interface DeleteBuyOutPerModelRequest {
    projectId: string;
    modelId: string;
    roleId: string;
}

export interface DeleteExternalParticipantRequest {
    projectId: string;
    employeeIdDto: EmployeeIdDto;
}

export interface DeleteModelFromProjectRoleRequest {
    projectId: string;
    roleId: string;
    modelId: string;
}

export interface DeleteProductionDayEventFromProjectModelRequest {
    projectId: string;
    projectModelId: string;
    deleteProductionDetailsEventCommand: DeleteProductionDetailsEventCommand;
}

export interface DeleteProjectRequest {
    projectId: string;
}

export interface DeleteProjectLocationRequest {
    projectId: string;
    locationId: string;
}

export interface DeleteProjectMotifRequest {
    projectId: string;
    motifId: string;
}

export interface DeleteProjectRoleRequest {
    projectId: string;
    roleId: string;
}

export interface DeleteRequestTimeframeAppointmentRequest {
    deleteProjectRoleRequestDatesCommand: DeleteProjectRoleRequestDatesCommand;
}

export interface GenerateDocumentDemoRequest {
    demoPdfRequestDto: DemoPdfRequestDto;
}

export interface GeneratePdfFilenameBookingModelRequest {
    projectId: string;
    projectModelId: string;
    preview: boolean;
}

export interface GeneratePdfFilenameBookingProducerRequest {
    projectId: string;
    preview: boolean;
}

export interface GetAllActiveProjectsFromCustomerRequest {
    employeeReferenceId: string;
}

export interface GetAllProjectParticipantsRequest {
    projectId: string;
}

export interface GetAllProjectParticipantsGroupedRequest {
    projectId: string;
}

export interface GetBillPreviewRequest {
    projectId: string;
    language?: string;
}

export interface GetBookedModelsByProjectIdRequest {
    projectId: string;
    lang: string;
}

export interface GetBookingModelConfirmationPreviewRequest {
    projectId: string;
    modelId: string;
    roleId: string;
    language?: string;
}

export interface GetBookingModelConfirmationPreviewWithBuyoutRequest {
    projectId: string;
    modelId: string;
    roleId: string;
    language?: string;
}

export interface GetBookingProducerConfirmationPreviewRequest {
    projectId: string;
    language: string;
}

export interface GetBookingProducerConfirmationWithBuyoutsPreviewRequest {
    projectId: string;
    language: string;
}

export interface GetBuyOutPerModelByProjectIdRequest {
    projectId: string;
}

export interface GetCurrentModelSelectionRequest {
    projectId: string;
    language?: string;
}

export interface GetEmployeesByCustomerIdRequest {
    customerId: string;
}

export interface GetModelsForCastingRequest {
    projectId: string;
}

export interface GetMyActiveProjectAsTableItemListRequest {
    page: number;
    filter: string;
}

export interface GetProductionDaysForProjectModelRequest {
    projectId: string;
    projectModelId: string;
    getProductionDetailsEventsCommand: GetProductionDetailsEventsCommand;
}

export interface GetProjectAdditionalParticipantsRequest {
    projectId: string;
}

export interface GetProjectAsSearchItemRequest {
    projectId: string;
}

export interface GetProjectAsTableItemListRequest {
    start: number;
    filter: string;
    status: GetProjectAsTableItemListStatusEnum;
}

export interface GetProjectExternalParticipantsRequest {
    projectId: string;
}

export interface GetProjectFormValuesRequest {
    lang: string;
    projectId: string;
}

export interface GetProjectLocationsRequest {
    projectId: string;
}

export interface GetProjectModelProductionDayEventsRequest {
    projectId: string;
    projectModelId: string;
    productionDayDate: Date;
}

export interface GetProjectModelsByProjectIdRequest {
    projectId: string;
}

export interface GetProjectModelsByProjectIdAndRoleIdRequest {
    lang: string;
    projectId: string;
    roleId: string;
}

export interface GetProjectProducerRequest {
    projectId: string;
}

export interface GetProjectRoleAsSearchItemListRequest {
    projectId: string;
}

export interface GetProjectRoleFormValuesRequest {
    projectId: string;
    roleId: string;
    lang: string;
}

export interface GetProjectTotalModelFeesRequest {
    projectId: string;
}

export interface MarkProjectAsSeenByCustomerRequest {
    projectId: string;
}

export interface RemoveModelFromCurrentSelectionRequest {
    removeModelFromSelectionCommand: RemoveModelFromSelectionCommand;
}

export interface UpdateRoleRequest {
    lang: string;
    projectRoleFormValuesDto: ProjectRoleFormValuesDto;
}

/**
 * 
 */
export class ProjectControllerApi extends runtime.BaseAPI {

    /**
     */
    async addAdditionalParticipantRaw(requestParameters: AddAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectAdditionalParticipant>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addAdditionalParticipant.');
        }

        if (requestParameters.addAdditionalParticipantRequestDto === null || requestParameters.addAdditionalParticipantRequestDto === undefined) {
            throw new runtime.RequiredError('addAdditionalParticipantRequestDto','Required parameter requestParameters.addAdditionalParticipantRequestDto was null or undefined when calling addAdditionalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/project/{projectId}/add-customer-employee`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAdditionalParticipantRequestDtoToJSON(requestParameters.addAdditionalParticipantRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectAdditionalParticipantFromJSON(jsonValue));
    }

    /**
     */
    async addAdditionalParticipant(requestParameters: AddAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectAdditionalParticipant> {
        const response = await this.addAdditionalParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addBuyOutPerModelRaw(requestParameters: AddBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyOutPerModelFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addBuyOutPerModel.');
        }

        if (requestParameters.addNewBuyOutPerModelRequest === null || requestParameters.addNewBuyOutPerModelRequest === undefined) {
            throw new runtime.RequiredError('addNewBuyOutPerModelRequest','Required parameter requestParameters.addNewBuyOutPerModelRequest was null or undefined when calling addBuyOutPerModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/create/buyout-per-model/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNewBuyOutPerModelRequestToJSON(requestParameters.addNewBuyOutPerModelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyOutPerModelFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async addBuyOutPerModel(requestParameters: AddBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyOutPerModelFormValuesDto> {
        const response = await this.addBuyOutPerModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addExternalParticipantRaw(requestParameters: AddExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalParticipantFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addExternalParticipant.');
        }

        if (requestParameters.addExternalParticipantRequestDto === null || requestParameters.addExternalParticipantRequestDto === undefined) {
            throw new runtime.RequiredError('addExternalParticipantRequestDto','Required parameter requestParameters.addExternalParticipantRequestDto was null or undefined when calling addExternalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/project/{projectId}/add-external-participant`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddExternalParticipantRequestDtoToJSON(requestParameters.addExternalParticipantRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalParticipantFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async addExternalParticipant(requestParameters: AddExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalParticipantFormValuesDto> {
        const response = await this.addExternalParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addModelToCurrentSelectionRaw(requestParameters: AddModelToCurrentSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectModelCurrentSelection>> {
        if (requestParameters.addModelToSelectionCommand === null || requestParameters.addModelToSelectionCommand === undefined) {
            throw new runtime.RequiredError('addModelToSelectionCommand','Required parameter requestParameters.addModelToSelectionCommand was null or undefined when calling addModelToCurrentSelection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/add-to-current-selection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddModelToSelectionCommandToJSON(requestParameters.addModelToSelectionCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectModelCurrentSelectionFromJSON(jsonValue));
    }

    /**
     */
    async addModelToCurrentSelection(requestParameters: AddModelToCurrentSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectModelCurrentSelection> {
        const response = await this.addModelToCurrentSelectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addModelToProjectRoleRaw(requestParameters: AddModelToProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddModelToProjectRoleResponseDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addModelToProjectRole.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling addModelToProjectRole.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling addModelToProjectRole.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling addModelToProjectRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/{lang}/role/{roleId}/add-model/{modelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddModelToProjectRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async addModelToProjectRole(requestParameters: AddModelToProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddModelToProjectRoleResponseDto> {
        const response = await this.addModelToProjectRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addProductionDaysToProjectModelRaw(requestParameters: AddProductionDaysToProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectModelProductionDetailsEvent>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addProductionDaysToProjectModel.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling addProductionDaysToProjectModel.');
        }

        if (requestParameters.addProductionDetailsEventCommand === null || requestParameters.addProductionDetailsEventCommand === undefined) {
            throw new runtime.RequiredError('addProductionDetailsEventCommand','Required parameter requestParameters.addProductionDetailsEventCommand was null or undefined when calling addProductionDaysToProjectModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/project-model/{project-model-id}/add-production-details-event`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"project-model-id"}}`, encodeURIComponent(String(requestParameters.projectModelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductionDetailsEventCommandToJSON(requestParameters.addProductionDetailsEventCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectModelProductionDetailsEventFromJSON));
    }

    /**
     */
    async addProductionDaysToProjectModel(requestParameters: AddProductionDaysToProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectModelProductionDetailsEvent>> {
        const response = await this.addProductionDaysToProjectModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addRoleRaw(requestParameters: AddRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRole>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling addRole.');
        }

        if (requestParameters.addRoleRequestDto === null || requestParameters.addRoleRequestDto === undefined) {
            throw new runtime.RequiredError('addRoleRequestDto','Required parameter requestParameters.addRoleRequestDto was null or undefined when calling addRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/addRole`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRoleRequestDtoToJSON(requestParameters.addRoleRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectRoleFromJSON(jsonValue));
    }

    /**
     */
    async addRole(requestParameters: AddRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRole> {
        const response = await this.addRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAdminRightsAdditionalParticipantRaw(requestParameters: ChangeAdminRightsAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeAdminRightsAdditionalParticipant.');
        }

        if (requestParameters.employeeIdDto === null || requestParameters.employeeIdDto === undefined) {
            throw new runtime.RequiredError('employeeIdDto','Required parameter requestParameters.employeeIdDto was null or undefined when calling changeAdminRightsAdditionalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/adminRights/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeIdDtoToJSON(requestParameters.employeeIdDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeAdminRightsAdditionalParticipant(requestParameters: ChangeAdminRightsAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeAdminRightsAdditionalParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeAdminRightsExternalParticipantRaw(requestParameters: ChangeAdminRightsExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeAdminRightsExternalParticipant.');
        }

        if (requestParameters.employeeIdDto === null || requestParameters.employeeIdDto === undefined) {
            throw new runtime.RequiredError('employeeIdDto','Required parameter requestParameters.employeeIdDto was null or undefined when calling changeAdminRightsExternalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/adminRights/external-participant/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeIdDtoToJSON(requestParameters.employeeIdDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeAdminRightsExternalParticipant(requestParameters: ChangeAdminRightsExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeAdminRightsExternalParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBillingAddresseeRaw(requestParameters: ChangeBillingAddresseeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBillingAddressee.');
        }

        if (requestParameters.projectBillingAddresseeFormValuesDto === null || requestParameters.projectBillingAddresseeFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectBillingAddresseeFormValuesDto','Required parameter requestParameters.projectBillingAddresseeFormValuesDto was null or undefined when calling changeBillingAddressee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/change-project-billing-addressee/`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectBillingAddresseeFormValuesDtoToJSON(requestParameters.projectBillingAddresseeFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeBillingAddressee(requestParameters: ChangeBillingAddresseeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeBillingAddresseeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBookingModelsRaw(requestParameters: ChangeBookingModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBookingModels.');
        }

        if (requestParameters.changeModelBookingInformationRequestDto === null || requestParameters.changeModelBookingInformationRequestDto === undefined) {
            throw new runtime.RequiredError('changeModelBookingInformationRequestDto','Required parameter requestParameters.changeModelBookingInformationRequestDto was null or undefined when calling changeBookingModels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/booking-models/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeModelBookingInformationRequestDtoToJSON(requestParameters.changeModelBookingInformationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBookingModels(requestParameters: ChangeBookingModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBookingModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBookingProducerRaw(requestParameters: ChangeBookingProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBookingProducer.');
        }

        if (requestParameters.bookingProducerFormValuesDto === null || requestParameters.bookingProducerFormValuesDto === undefined) {
            throw new runtime.RequiredError('bookingProducerFormValuesDto','Required parameter requestParameters.bookingProducerFormValuesDto was null or undefined when calling changeBookingProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/booking-producer/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingProducerFormValuesDtoToJSON(requestParameters.bookingProducerFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBookingProducer(requestParameters: ChangeBookingProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBookingProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBudgetRaw(requestParameters: ChangeBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBudget.');
        }

        if (requestParameters.budgetFormValuesDto === null || requestParameters.budgetFormValuesDto === undefined) {
            throw new runtime.RequiredError('budgetFormValuesDto','Required parameter requestParameters.budgetFormValuesDto was null or undefined when calling changeBudget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/budget/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BudgetFormValuesDtoToJSON(requestParameters.budgetFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBudget(requestParameters: ChangeBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBudgetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBuyOutRaw(requestParameters: ChangeBuyOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBuyOut.');
        }

        if (requestParameters.projectBuyOutFromValuesDto === null || requestParameters.projectBuyOutFromValuesDto === undefined) {
            throw new runtime.RequiredError('projectBuyOutFromValuesDto','Required parameter requestParameters.projectBuyOutFromValuesDto was null or undefined when calling changeBuyOut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/buyOut/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectBuyOutFromValuesDtoToJSON(requestParameters.projectBuyOutFromValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeBuyOut(requestParameters: ChangeBuyOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeBuyOutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeBuyOutPerModelRaw(requestParameters: ChangeBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeBuyOutPerModel.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling changeBuyOutPerModel.');
        }

        if (requestParameters.buyOutPerModelFormValuesDto === null || requestParameters.buyOutPerModelFormValuesDto === undefined) {
            throw new runtime.RequiredError('buyOutPerModelFormValuesDto','Required parameter requestParameters.buyOutPerModelFormValuesDto was null or undefined when calling changeBuyOutPerModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/buyout-per-model/{projectId}/{roleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyOutPerModelFormValuesDtoToJSON(requestParameters.buyOutPerModelFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeBuyOutPerModel(requestParameters: ChangeBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeBuyOutPerModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeExecutorRaw(requestParameters: ChangeExecutorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeExecutor.');
        }

        if (requestParameters.executorFormValuesDto === null || requestParameters.executorFormValuesDto === undefined) {
            throw new runtime.RequiredError('executorFormValuesDto','Required parameter requestParameters.executorFormValuesDto was null or undefined when calling changeExecutor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/executor/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecutorFormValuesDtoToJSON(requestParameters.executorFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeExecutor(requestParameters: ChangeExecutorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeExecutorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeExtraCostsRaw(requestParameters: ChangeExtraCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeExtraCosts.');
        }

        if (requestParameters.extraCostsListFormValuesDto === null || requestParameters.extraCostsListFormValuesDto === undefined) {
            throw new runtime.RequiredError('extraCostsListFormValuesDto','Required parameter requestParameters.extraCostsListFormValuesDto was null or undefined when calling changeExtraCosts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/change-extra-costs/`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtraCostsListFormValuesDtoToJSON(requestParameters.extraCostsListFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeExtraCosts(requestParameters: ChangeExtraCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeExtraCostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeFinalCustomerRaw(requestParameters: ChangeFinalCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeFinalCustomer.');
        }

        if (requestParameters.finalCustomerFormValuesDto === null || requestParameters.finalCustomerFormValuesDto === undefined) {
            throw new runtime.RequiredError('finalCustomerFormValuesDto','Required parameter requestParameters.finalCustomerFormValuesDto was null or undefined when calling changeFinalCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/final-customer/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinalCustomerFormValuesDtoToJSON(requestParameters.finalCustomerFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeFinalCustomer(requestParameters: ChangeFinalCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeFinalCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeLocationRaw(requestParameters: ChangeLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectLocationFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeLocation.');
        }

        if (requestParameters.projectLocationFormValuesDto === null || requestParameters.projectLocationFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectLocationFormValuesDto','Required parameter requestParameters.projectLocationFormValuesDto was null or undefined when calling changeLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/locations/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectLocationFormValuesDtoToJSON(requestParameters.projectLocationFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeLocation(requestParameters: ChangeLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectLocationFormValuesDto> {
        const response = await this.changeLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeMotifRaw(requestParameters: ChangeMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectMotifFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeMotif.');
        }

        if (requestParameters.projectMotifFormValuesDto === null || requestParameters.projectMotifFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectMotifFormValuesDto','Required parameter requestParameters.projectMotifFormValuesDto was null or undefined when calling changeMotif.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/motifs/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectMotifFormValuesDtoToJSON(requestParameters.projectMotifFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMotifFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeMotif(requestParameters: ChangeMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectMotifFormValuesDto> {
        const response = await this.changeMotifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProductionDetailsRaw(requestParameters: ChangeProductionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProductionDetails.');
        }

        if (requestParameters.productionDetailsFormValuesDto === null || requestParameters.productionDetailsFormValuesDto === undefined) {
            throw new runtime.RequiredError('productionDetailsFormValuesDto','Required parameter requestParameters.productionDetailsFormValuesDto was null or undefined when calling changeProductionDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/project-production-details/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductionDetailsFormValuesDtoToJSON(requestParameters.productionDetailsFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeProductionDetails(requestParameters: ChangeProductionDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeProductionDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectBillingRaw(requestParameters: ChangeProjectBillingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectBilling.');
        }

        if (requestParameters.projectBillingFormValuesDto === null || requestParameters.projectBillingFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectBillingFormValuesDto','Required parameter requestParameters.projectBillingFormValuesDto was null or undefined when calling changeProjectBilling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/project-billing/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectBillingFormValuesDtoToJSON(requestParameters.projectBillingFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeProjectBilling(requestParameters: ChangeProjectBillingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeProjectBillingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectBillingSumRaw(requestParameters: ChangeProjectBillingSumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectBillingSum.');
        }

        if (requestParameters.projectBillingSumFormValuesDto === null || requestParameters.projectBillingSumFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectBillingSumFormValuesDto','Required parameter requestParameters.projectBillingSumFormValuesDto was null or undefined when calling changeProjectBillingSum.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/change-project-billing-sum/`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectBillingSumFormValuesDtoToJSON(requestParameters.projectBillingSumFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeProjectBillingSum(requestParameters: ChangeProjectBillingSumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeProjectBillingSumRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectInvoiceRaw(requestParameters: ChangeProjectInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectInvoice.');
        }

        if (requestParameters.projectBillingInvoiceFormValuesDto === null || requestParameters.projectBillingInvoiceFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectBillingInvoiceFormValuesDto','Required parameter requestParameters.projectBillingInvoiceFormValuesDto was null or undefined when calling changeProjectInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/change-project-invoice/`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectBillingInvoiceFormValuesDtoToJSON(requestParameters.projectBillingInvoiceFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeProjectInvoice(requestParameters: ChangeProjectInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeProjectInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectModelRaw(requestParameters: ChangeProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectModelFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectModel.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeProjectModel.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling changeProjectModel.');
        }

        if (requestParameters.changeProjectModelRequestDto === null || requestParameters.changeProjectModelRequestDto === undefined) {
            throw new runtime.RequiredError('changeProjectModelRequestDto','Required parameter requestParameters.changeProjectModelRequestDto was null or undefined when calling changeProjectModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/role/{roleId}/change-project-model/{modelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeProjectModelRequestDtoToJSON(requestParameters.changeProjectModelRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectModelFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async changeProjectModel(requestParameters: ChangeProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectModelFormValuesDto> {
        const response = await this.changeProjectModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectModelBillingRaw(requestParameters: ChangeProjectModelBillingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectModelBilling.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeProjectModelBilling.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling changeProjectModelBilling.');
        }

        if (requestParameters.projectModelBillingFormValuesDto === null || requestParameters.projectModelBillingFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectModelBillingFormValuesDto','Required parameter requestParameters.projectModelBillingFormValuesDto was null or undefined when calling changeProjectModelBilling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/role/{roleId}/change-project-model-billing/{modelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectModelBillingFormValuesDtoToJSON(requestParameters.projectModelBillingFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeProjectModelBilling(requestParameters: ChangeProjectModelBillingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeProjectModelBillingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectModelTravelCostsRaw(requestParameters: ChangeProjectModelTravelCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectModelTravelCosts.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling changeProjectModelTravelCosts.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling changeProjectModelTravelCosts.');
        }

        if (requestParameters.travelCostListFormValuesDto === null || requestParameters.travelCostListFormValuesDto === undefined) {
            throw new runtime.RequiredError('travelCostListFormValuesDto','Required parameter requestParameters.travelCostListFormValuesDto was null or undefined when calling changeProjectModelTravelCosts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/role/{roleId}/change-project-model-travel-costs/{modelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TravelCostListFormValuesDtoToJSON(requestParameters.travelCostListFormValuesDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeProjectModelTravelCosts(requestParameters: ChangeProjectModelTravelCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeProjectModelTravelCostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectNameRaw(requestParameters: ChangeProjectNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectName.');
        }

        if (requestParameters.projectNameFormValuesDto === null || requestParameters.projectNameFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectNameFormValuesDto','Required parameter requestParameters.projectNameFormValuesDto was null or undefined when calling changeProjectName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/project-name/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectNameFormValuesDtoToJSON(requestParameters.projectNameFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeProjectName(requestParameters: ChangeProjectNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeProjectNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectProducerRaw(requestParameters: ChangeProjectProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectProducer.');
        }

        if (requestParameters.projectProducerFormValueDto === null || requestParameters.projectProducerFormValueDto === undefined) {
            throw new runtime.RequiredError('projectProducerFormValueDto','Required parameter requestParameters.projectProducerFormValueDto was null or undefined when calling changeProjectProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/project-producer/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectProducerFormValueDtoToJSON(requestParameters.projectProducerFormValueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeProjectProducer(requestParameters: ChangeProjectProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeProjectProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeProjectStatusRaw(requestParameters: ChangeProjectStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeProjectStatus.');
        }

        if (requestParameters.projectStatusFormValuesDto === null || requestParameters.projectStatusFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectStatusFormValuesDto','Required parameter requestParameters.projectStatusFormValuesDto was null or undefined when calling changeProjectStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/status/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectStatusFormValuesDtoToJSON(requestParameters.projectStatusFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeProjectStatus(requestParameters: ChangeProjectStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeProjectStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeRoleDetailsRaw(requestParameters: ChangeRoleDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeRoleDetails.');
        }

        if (requestParameters.changeRoleDetailsRequestDto === null || requestParameters.changeRoleDetailsRequestDto === undefined) {
            throw new runtime.RequiredError('changeRoleDetailsRequestDto','Required parameter requestParameters.changeRoleDetailsRequestDto was null or undefined when calling changeRoleDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/role-details/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRoleDetailsRequestDtoToJSON(requestParameters.changeRoleDetailsRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeRoleDetails(requestParameters: ChangeRoleDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeRoleDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeUsageTypeRaw(requestParameters: ChangeUsageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling changeUsageType.');
        }

        if (requestParameters.changeUsageTypeRequestDto === null || requestParameters.changeUsageTypeRequestDto === undefined) {
            throw new runtime.RequiredError('changeUsageTypeRequestDto','Required parameter requestParameters.changeUsageTypeRequestDto was null or undefined when calling changeUsageType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/change/usageType/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUsageTypeRequestDtoToJSON(requestParameters.changeUsageTypeRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async changeUsageType(requestParameters: ChangeUsageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.changeUsageTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async confirmBookingByModelRaw(requestParameters: ConfirmBookingByModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling confirmBookingByModel.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling confirmBookingByModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/project-model/model/confirm-booking/{projectId}/{projectModelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"projectModelId"}}`, encodeURIComponent(String(requestParameters.projectModelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async confirmBookingByModel(requestParameters: ConfirmBookingByModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.confirmBookingByModelRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTableItemDto>> {
        if (requestParameters.projectCreateRequestDto === null || requestParameters.projectCreateRequestDto === undefined) {
            throw new runtime.RequiredError('projectCreateRequestDto','Required parameter requestParameters.projectCreateRequestDto was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/createProject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateRequestDtoToJSON(requestParameters.projectCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTableItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTableItemDto> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectBillRaw(requestParameters: CreateProjectBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/bill/create`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createProjectBill(requestParameters: CreateProjectBillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createProjectBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectLocationRaw(requestParameters: CreateProjectLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectLocation>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectLocation.');
        }

        if (requestParameters.createProjectLocationRequestDto === null || requestParameters.createProjectLocationRequestDto === undefined) {
            throw new runtime.RequiredError('createProjectLocationRequestDto','Required parameter requestParameters.createProjectLocationRequestDto was null or undefined when calling createProjectLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/project/project-location/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectLocationRequestDtoToJSON(requestParameters.createProjectLocationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationFromJSON(jsonValue));
    }

    /**
     */
    async createProjectLocation(requestParameters: CreateProjectLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectLocation> {
        const response = await this.createProjectLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectMotifRaw(requestParameters: CreateProjectMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectMotif>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createProjectMotif.');
        }

        if (requestParameters.createProjectMotifRequestDto === null || requestParameters.createProjectMotifRequestDto === undefined) {
            throw new runtime.RequiredError('createProjectMotifRequestDto','Required parameter requestParameters.createProjectMotifRequestDto was null or undefined when calling createProjectMotif.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/project/project-motif/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProjectMotifRequestDtoToJSON(requestParameters.createProjectMotifRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMotifFromJSON(jsonValue));
    }

    /**
     */
    async createProjectMotif(requestParameters: CreateProjectMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectMotif> {
        const response = await this.createProjectMotifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProtocolEntryRaw(requestParameters: CreateProtocolEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProtocolFormValuesDto>> {
        if (requestParameters.protocolEntryCreateRequestDto === null || requestParameters.protocolEntryCreateRequestDto === undefined) {
            throw new runtime.RequiredError('protocolEntryCreateRequestDto','Required parameter requestParameters.protocolEntryCreateRequestDto was null or undefined when calling createProtocolEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/create/protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProtocolEntryCreateRequestDtoToJSON(requestParameters.protocolEntryCreateRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProtocolFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async createProtocolEntry(requestParameters: CreateProtocolEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProtocolFormValuesDto> {
        const response = await this.createProtocolEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createRequestTimeframeAppointmentRaw(requestParameters: CreateRequestTimeframeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling createRequestTimeframeAppointment.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createRequestTimeframeAppointment.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling createRequestTimeframeAppointment.');
        }

        if (requestParameters.projectAppointmentCreateRequestDto === null || requestParameters.projectAppointmentCreateRequestDto === undefined) {
            throw new runtime.RequiredError('projectAppointmentCreateRequestDto','Required parameter requestParameters.projectAppointmentCreateRequestDto was null or undefined when calling createRequestTimeframeAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/appointment/{language}/{projectId}/{roleId}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectAppointmentCreateRequestDtoToJSON(requestParameters.projectAppointmentCreateRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createRequestTimeframeAppointment(requestParameters: CreateRequestTimeframeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRequestTimeframeAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteAdditionalParticipantRaw(requestParameters: DeleteAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteAdditionalParticipant.');
        }

        if (requestParameters.employeeIdDto === null || requestParameters.employeeIdDto === undefined) {
            throw new runtime.RequiredError('employeeIdDto','Required parameter requestParameters.employeeIdDto was null or undefined when calling deleteAdditionalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/delete/additionalParticipant/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeIdDtoToJSON(requestParameters.employeeIdDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteAdditionalParticipant(requestParameters: DeleteAdditionalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteAdditionalParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBuyOutPerModelRaw(requestParameters: DeleteBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteBuyOutPerModel.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteBuyOutPerModel.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteBuyOutPerModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/delete/buyout-per-model/{projectId}/{modelId}/{roleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteBuyOutPerModel(requestParameters: DeleteBuyOutPerModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteBuyOutPerModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteExternalParticipantRaw(requestParameters: DeleteExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteExternalParticipant.');
        }

        if (requestParameters.employeeIdDto === null || requestParameters.employeeIdDto === undefined) {
            throw new runtime.RequiredError('employeeIdDto','Required parameter requestParameters.employeeIdDto was null or undefined when calling deleteExternalParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/delete/externalParticipant/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmployeeIdDtoToJSON(requestParameters.employeeIdDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteExternalParticipant(requestParameters: DeleteExternalParticipantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteExternalParticipantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteModelFromProjectRoleRaw(requestParameters: DeleteModelFromProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UseCaseResult>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteModelFromProjectRole.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteModelFromProjectRole.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling deleteModelFromProjectRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/role/{roleId}/remove-model/{modelId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UseCaseResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteModelFromProjectRole(requestParameters: DeleteModelFromProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UseCaseResult> {
        const response = await this.deleteModelFromProjectRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProductionDayEventFromProjectModelRaw(requestParameters: DeleteProductionDayEventFromProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProductionDayEventFromProjectModel.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling deleteProductionDayEventFromProjectModel.');
        }

        if (requestParameters.deleteProductionDetailsEventCommand === null || requestParameters.deleteProductionDetailsEventCommand === undefined) {
            throw new runtime.RequiredError('deleteProductionDetailsEventCommand','Required parameter requestParameters.deleteProductionDetailsEventCommand was null or undefined when calling deleteProductionDayEventFromProjectModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/project-model/{project-model-id}/delete-production-details-event`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"project-model-id"}}`, encodeURIComponent(String(requestParameters.projectModelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteProductionDetailsEventCommandToJSON(requestParameters.deleteProductionDetailsEventCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProductionDayEventFromProjectModel(requestParameters: DeleteProductionDayEventFromProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductionDayEventFromProjectModelRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectLocationRaw(requestParameters: DeleteProjectLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling deleteProjectLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/delete/project-location/{projectId}/{locationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteProjectLocation(requestParameters: DeleteProjectLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteProjectLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectMotifRaw(requestParameters: DeleteProjectMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectMotif.');
        }

        if (requestParameters.motifId === null || requestParameters.motifId === undefined) {
            throw new runtime.RequiredError('motifId','Required parameter requestParameters.motifId was null or undefined when calling deleteProjectMotif.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/delete/project-motif/{projectId}/{motifId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"motifId"}}`, encodeURIComponent(String(requestParameters.motifId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteProjectMotif(requestParameters: DeleteProjectMotifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteProjectMotifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProjectRoleRaw(requestParameters: DeleteProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectRole.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteProjectRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/project-role/{projectId}/{roleId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProjectRole(requestParameters: DeleteProjectRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteRequestTimeframeAppointmentRaw(requestParameters: DeleteRequestTimeframeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteProjectRoleRequestDatesCommand === null || requestParameters.deleteProjectRoleRequestDatesCommand === undefined) {
            throw new runtime.RequiredError('deleteProjectRoleRequestDatesCommand','Required parameter requestParameters.deleteProjectRoleRequestDatesCommand was null or undefined when calling deleteRequestTimeframeAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/appointment/request-time-frame`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteProjectRoleRequestDatesCommandToJSON(requestParameters.deleteProjectRoleRequestDatesCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRequestTimeframeAppointment(requestParameters: DeleteRequestTimeframeAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRequestTimeframeAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async generateDocumentDemoRaw(requestParameters: GenerateDocumentDemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.demoPdfRequestDto === null || requestParameters.demoPdfRequestDto === undefined) {
            throw new runtime.RequiredError('demoPdfRequestDto','Required parameter requestParameters.demoPdfRequestDto was null or undefined when calling generateDocumentDemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/generate/document/demo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemoPdfRequestDtoToJSON(requestParameters.demoPdfRequestDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateDocumentDemo(requestParameters: GenerateDocumentDemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateDocumentDemoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generatePdfFilenameBookingModelRaw(requestParameters: GeneratePdfFilenameBookingModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilenameDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling generatePdfFilenameBookingModel.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling generatePdfFilenameBookingModel.');
        }

        if (requestParameters.preview === null || requestParameters.preview === undefined) {
            throw new runtime.RequiredError('preview','Required parameter requestParameters.preview was null or undefined when calling generatePdfFilenameBookingModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/generate/pdf-filename/booking-model/{projectId}/{projectModelId}/{preview}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"projectModelId"}}`, encodeURIComponent(String(requestParameters.projectModelId))).replace(`{${"preview"}}`, encodeURIComponent(String(requestParameters.preview))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilenameDtoFromJSON(jsonValue));
    }

    /**
     */
    async generatePdfFilenameBookingModel(requestParameters: GeneratePdfFilenameBookingModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilenameDto> {
        const response = await this.generatePdfFilenameBookingModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generatePdfFilenameBookingProducerRaw(requestParameters: GeneratePdfFilenameBookingProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilenameDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling generatePdfFilenameBookingProducer.');
        }

        if (requestParameters.preview === null || requestParameters.preview === undefined) {
            throw new runtime.RequiredError('preview','Required parameter requestParameters.preview was null or undefined when calling generatePdfFilenameBookingProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/generate/pdf-filename/booking-producer/{projectId}/{preview}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"preview"}}`, encodeURIComponent(String(requestParameters.preview))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilenameDtoFromJSON(jsonValue));
    }

    /**
     */
    async generatePdfFilenameBookingProducer(requestParameters: GeneratePdfFilenameBookingProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilenameDto> {
        const response = await this.generatePdfFilenameBookingProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllActiveProjectsFromCustomerRaw(requestParameters: GetAllActiveProjectsFromCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AllActiveProjectsFromCustomerResponseDto>>> {
        if (requestParameters.employeeReferenceId === null || requestParameters.employeeReferenceId === undefined) {
            throw new runtime.RequiredError('employeeReferenceId','Required parameter requestParameters.employeeReferenceId was null or undefined when calling getAllActiveProjectsFromCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeReferenceId !== undefined) {
            queryParameters['employeeReferenceId'] = requestParameters.employeeReferenceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/projects/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AllActiveProjectsFromCustomerResponseDtoFromJSON));
    }

    /**
     */
    async getAllActiveProjectsFromCustomer(requestParameters: GetAllActiveProjectsFromCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AllActiveProjectsFromCustomerResponseDto>> {
        const response = await this.getAllActiveProjectsFromCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllCustomerNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerNameListForProject>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/all-customer-names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerNameListForProjectFromJSON(jsonValue));
    }

    /**
     */
    async getAllCustomerNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerNameListForProject> {
        const response = await this.getAllCustomerNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllProjectNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectNamesForMessengerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/all/project-names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectNamesForMessengerDtoFromJSON));
    }

    /**
     */
    async getAllProjectNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectNamesForMessengerDto>> {
        const response = await this.getAllProjectNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllProjectParticipantsRaw(requestParameters: GetAllProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectParticipantDataForMessenger>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getAllProjectParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/messenger/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectParticipantDataForMessengerFromJSON));
    }

    /**
     */
    async getAllProjectParticipants(requestParameters: GetAllProjectParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectParticipantDataForMessenger>> {
        const response = await this.getAllProjectParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllProjectParticipantsGroupedRaw(requestParameters: GetAllProjectParticipantsGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectParticipantDataForMessengerGrouped>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getAllProjectParticipantsGrouped.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/messenger/{projectId}/grouped`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectParticipantDataForMessengerGroupedFromJSON(jsonValue));
    }

    /**
     */
    async getAllProjectParticipantsGrouped(requestParameters: GetAllProjectParticipantsGroupedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectParticipantDataForMessengerGrouped> {
        const response = await this.getAllProjectParticipantsGroupedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBillPreviewRaw(requestParameters: GetBillPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBillPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/bill/preview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBillPreview(requestParameters: GetBillPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBillPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookedModelsByProjectIdRaw(requestParameters: GetBookedModelsByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectModelListFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBookedModelsByProjectId.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getBookedModelsByProjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/bookedModels/{lang}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectModelListFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBookedModelsByProjectId(requestParameters: GetBookedModelsByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectModelListFormValuesDto> {
        const response = await this.getBookedModelsByProjectIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookingModelConfirmationPreviewRaw(requestParameters: GetBookingModelConfirmationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBookingModelConfirmationPreview.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getBookingModelConfirmationPreview.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getBookingModelConfirmationPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/{modelId}/{roleId}/booking-model-confirmation/preview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBookingModelConfirmationPreview(requestParameters: GetBookingModelConfirmationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBookingModelConfirmationPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookingModelConfirmationPreviewWithBuyoutRaw(requestParameters: GetBookingModelConfirmationPreviewWithBuyoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBookingModelConfirmationPreviewWithBuyout.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getBookingModelConfirmationPreviewWithBuyout.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getBookingModelConfirmationPreviewWithBuyout.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/{modelId}/{roleId}/booking-model-confirmation-with-buyout/preview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBookingModelConfirmationPreviewWithBuyout(requestParameters: GetBookingModelConfirmationPreviewWithBuyoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBookingModelConfirmationPreviewWithBuyoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookingProducerConfirmationPreviewRaw(requestParameters: GetBookingProducerConfirmationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBookingProducerConfirmationPreview.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getBookingProducerConfirmationPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/booking-producer-confirmation/preview/{language}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBookingProducerConfirmationPreview(requestParameters: GetBookingProducerConfirmationPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBookingProducerConfirmationPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBookingProducerConfirmationWithBuyoutsPreviewRaw(requestParameters: GetBookingProducerConfirmationWithBuyoutsPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBookingProducerConfirmationWithBuyoutsPreview.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getBookingProducerConfirmationWithBuyoutsPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/booking-producer-confirmation-with-buyouts/preview/{language}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getBookingProducerConfirmationWithBuyoutsPreview(requestParameters: GetBookingProducerConfirmationWithBuyoutsPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getBookingProducerConfirmationWithBuyoutsPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBuyOutPerModelByProjectIdRaw(requestParameters: GetBuyOutPerModelByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuyOutPerModelListFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getBuyOutPerModelByProjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/buyout-per-model/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuyOutPerModelListFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBuyOutPerModelByProjectId(requestParameters: GetBuyOutPerModelByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuyOutPerModelListFormValuesDto> {
        const response = await this.getBuyOutPerModelByProjectIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentModelSelectionRaw(requestParameters: GetCurrentModelSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getCurrentModelSelection.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/current-model-selection/preview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getCurrentModelSelection(requestParameters: GetCurrentModelSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getCurrentModelSelectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomersWithEmployeesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerAndEmployeesListForProjectsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/customer-with-employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerAndEmployeesListForProjectsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getCustomersWithEmployees(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerAndEmployeesListForProjectsDto> {
        const response = await this.getCustomersWithEmployeesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmployeesByCustomerIdRaw(requestParameters: GetEmployeesByCustomerIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerEmployeeListForProject>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getEmployeesByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/project/search/{customerId}/employees`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEmployeeListForProjectFromJSON(jsonValue));
    }

    /**
     */
    async getEmployeesByCustomerId(requestParameters: GetEmployeesByCustomerIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerEmployeeListForProject> {
        const response = await this.getEmployeesByCustomerIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModelsForCastingRaw(requestParameters: GetModelsForCastingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DetailsCastingFormValuesDtoList>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getModelsForCasting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/detailsCasting`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailsCastingFormValuesDtoListFromJSON(jsonValue));
    }

    /**
     */
    async getModelsForCasting(requestParameters: GetModelsForCastingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DetailsCastingFormValuesDtoList> {
        const response = await this.getModelsForCastingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyActiveProjectAsTableItemListRaw(requestParameters: GetMyActiveProjectAsTableItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTableItemsDto>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getMyActiveProjectAsTableItemList.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling getMyActiveProjectAsTableItemList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/active/mine/table-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTableItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMyActiveProjectAsTableItemList(requestParameters: GetMyActiveProjectAsTableItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTableItemsDto> {
        const response = await this.getMyActiveProjectAsTableItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductionDaysForProjectModelRaw(requestParameters: GetProductionDaysForProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelProductionDetailsEventFormValuesDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProductionDaysForProjectModel.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling getProductionDaysForProjectModel.');
        }

        if (requestParameters.getProductionDetailsEventsCommand === null || requestParameters.getProductionDetailsEventsCommand === undefined) {
            throw new runtime.RequiredError('getProductionDetailsEventsCommand','Required parameter requestParameters.getProductionDetailsEventsCommand was null or undefined when calling getProductionDaysForProjectModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/{projectId}/project-model/{project-model-id}/production-details`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"project-model-id"}}`, encodeURIComponent(String(requestParameters.projectModelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProductionDetailsEventsCommandToJSON(requestParameters.getProductionDetailsEventsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelProductionDetailsEventFormValuesDtoFromJSON));
    }

    /**
     */
    async getProductionDaysForProjectModel(requestParameters: GetProductionDaysForProjectModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelProductionDetailsEventFormValuesDto>> {
        const response = await this.getProductionDaysForProjectModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectAdditionalParticipantsRaw(requestParameters: GetProjectAdditionalParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectAdditionalParticipantFormValuesDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectAdditionalParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/additional-participants`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectAdditionalParticipantFormValuesDtoFromJSON));
    }

    /**
     */
    async getProjectAdditionalParticipants(requestParameters: GetProjectAdditionalParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectAdditionalParticipantFormValuesDto>> {
        const response = await this.getProjectAdditionalParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectAsSearchItemRaw(requestParameters: GetProjectAsSearchItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectSearchItemDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectAsSearchItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/search`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSearchItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectAsSearchItem(requestParameters: GetProjectAsSearchItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectSearchItemDto> {
        const response = await this.getProjectAsSearchItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectAsSearchItemListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectSearchItemsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/all/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSearchItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectAsSearchItemList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectSearchItemsDto> {
        const response = await this.getProjectAsSearchItemListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectAsTableItemListRaw(requestParameters: GetProjectAsTableItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTableItemsDto>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError('start','Required parameter requestParameters.start was null or undefined when calling getProjectAsTableItemList.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling getProjectAsTableItemList.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getProjectAsTableItemList.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/all/table-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTableItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectAsTableItemList(requestParameters: GetProjectAsTableItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTableItemsDto> {
        const response = await this.getProjectAsTableItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectExternalParticipantsRaw(requestParameters: GetProjectExternalParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExternalParticipantFormValuesDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectExternalParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/external-participants`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalParticipantFormValuesDtoFromJSON));
    }

    /**
     */
    async getProjectExternalParticipants(requestParameters: GetProjectExternalParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExternalParticipantFormValuesDto>> {
        const response = await this.getProjectExternalParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectFormValuesRaw(requestParameters: GetProjectFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getProjectFormValues.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectFormValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/formValues/{lang}/{projectId}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectFormValues(requestParameters: GetProjectFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectFormValuesDto> {
        const response = await this.getProjectFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectLocationsRaw(requestParameters: GetProjectLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectLocationsAndMotifsFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/locations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLocationsAndMotifsFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectLocations(requestParameters: GetProjectLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectLocationsAndMotifsFormValuesDto> {
        const response = await this.getProjectLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectModelProductionDayEventsRaw(requestParameters: GetProjectModelProductionDayEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModelProductionDetailsEventFormValuesDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectModelProductionDayEvents.');
        }

        if (requestParameters.projectModelId === null || requestParameters.projectModelId === undefined) {
            throw new runtime.RequiredError('projectModelId','Required parameter requestParameters.projectModelId was null or undefined when calling getProjectModelProductionDayEvents.');
        }

        if (requestParameters.productionDayDate === null || requestParameters.productionDayDate === undefined) {
            throw new runtime.RequiredError('productionDayDate','Required parameter requestParameters.productionDayDate was null or undefined when calling getProjectModelProductionDayEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/project-model/{projectModelId}/production-day-events/{productionDayDate}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"projectModelId"}}`, encodeURIComponent(String(requestParameters.projectModelId))).replace(`{${"productionDayDate"}}`, encodeURIComponent(String(requestParameters.productionDayDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelProductionDetailsEventFormValuesDtoFromJSON));
    }

    /**
     */
    async getProjectModelProductionDayEvents(requestParameters: GetProjectModelProductionDayEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModelProductionDetailsEventFormValuesDto>> {
        const response = await this.getProjectModelProductionDayEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectModelsByProjectIdRaw(requestParameters: GetProjectModelsByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectModelListFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectModelsByProjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/project-models/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectModelListFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectModelsByProjectId(requestParameters: GetProjectModelsByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectModelListFormValuesDto> {
        const response = await this.getProjectModelsByProjectIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectModelsByProjectIdAndRoleIdRaw(requestParameters: GetProjectModelsByProjectIdAndRoleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectModelListFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getProjectModelsByProjectIdAndRoleId.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectModelsByProjectIdAndRoleId.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getProjectModelsByProjectIdAndRoleId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/project-models/{lang}/{projectId}/{roleId}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectModelListFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectModelsByProjectIdAndRoleId(requestParameters: GetProjectModelsByProjectIdAndRoleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectModelListFormValuesDto> {
        const response = await this.getProjectModelsByProjectIdAndRoleIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectProducerRaw(requestParameters: GetProjectProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectProducer>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/search/messenger/{projectId}/customer-id`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectProducerFromJSON(jsonValue));
    }

    /**
     */
    async getProjectProducer(requestParameters: GetProjectProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectProducer> {
        const response = await this.getProjectProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRoleAsSearchItemListRaw(requestParameters: GetProjectRoleAsSearchItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRoleSearchItemsDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectRoleAsSearchItemList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/roles/search`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectRoleSearchItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectRoleAsSearchItemList(requestParameters: GetProjectRoleAsSearchItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRoleSearchItemsDto> {
        const response = await this.getProjectRoleAsSearchItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRoleFormValuesRaw(requestParameters: GetProjectRoleFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRoleFormValuesDto>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectRoleFormValues.');
        }

        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getProjectRoleFormValues.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getProjectRoleFormValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/{projectId}/{roleId}/{lang}/role-form-values`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))).replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectRoleFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async getProjectRoleFormValues(requestParameters: GetProjectRoleFormValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRoleFormValuesDto> {
        const response = await this.getProjectRoleFormValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectStatusOfMyProjectsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/status-my-projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProjectStatusOfMyProjects(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectStatusResponse> {
        const response = await this.getProjectStatusOfMyProjectsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectTotalModelFeesRaw(requestParameters: GetProjectTotalModelFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTotalModelFees>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTotalModelFees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/totalModelFees/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTotalModelFeesFromJSON(jsonValue));
    }

    /**
     */
    async getProjectTotalModelFees(requestParameters: GetProjectTotalModelFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTotalModelFees> {
        const response = await this.getProjectTotalModelFeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markProjectAsSeenByCustomerRaw(requestParameters: MarkProjectAsSeenByCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling markProjectAsSeenByCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/project/mark-project-as-seen-by-customer/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markProjectAsSeenByCustomer(requestParameters: MarkProjectAsSeenByCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markProjectAsSeenByCustomerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async removeModelFromCurrentSelectionRaw(requestParameters: RemoveModelFromCurrentSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.removeModelFromSelectionCommand === null || requestParameters.removeModelFromSelectionCommand === undefined) {
            throw new runtime.RequiredError('removeModelFromSelectionCommand','Required parameter requestParameters.removeModelFromSelectionCommand was null or undefined when calling removeModelFromCurrentSelection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/remove-from-current-selection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveModelFromSelectionCommandToJSON(requestParameters.removeModelFromSelectionCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     */
    async removeModelFromCurrentSelection(requestParameters: RemoveModelFromCurrentSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.removeModelFromCurrentSelectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRoleFormValuesDto>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling updateRole.');
        }

        if (requestParameters.projectRoleFormValuesDto === null || requestParameters.projectRoleFormValuesDto === undefined) {
            throw new runtime.RequiredError('projectRoleFormValuesDto','Required parameter requestParameters.projectRoleFormValuesDto was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/project/role/update/{lang}`.replace(`{${"lang"}}`, encodeURIComponent(String(requestParameters.lang))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectRoleFormValuesDtoToJSON(requestParameters.projectRoleFormValuesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectRoleFormValuesDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRoleFormValuesDto> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetProjectAsTableItemListStatusEnum = {
    Active: 'ACTIVE',
    Stopped: 'STOPPED',
    Finished: 'FINISHED'
} as const;
export type GetProjectAsTableItemListStatusEnum = typeof GetProjectAsTableItemListStatusEnum[keyof typeof GetProjectAsTableItemListStatusEnum];
