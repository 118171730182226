import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginComponent } from './layout/app.login.component';
import { AuthGuardService } from './layout/service/app.authguard.service';
import { AccessErrorComponent } from './demo/components/shared/access-error/access-error.component';

@NgModule({
    imports: [
        RouterModule.forRoot([

            {
                path: '', component: AppLayoutComponent,
                canActivate: [AuthGuardService],
                data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                children: [
                    {
                        path: '', redirectTo: 'taskmanager/projects', pathMatch: 'full' // Add this line
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'admin',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/admin/admin.module').then(m => m.AdminModule)
                    },
                    {
                        path: 'models',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import ('./demo/components/models/models.module').then(m => m.ModelsModule)
                    },
                    // {
                    //     path: 'models/:id',
                    //     canActivate: [AuthGuardService],
                    //     data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                    //     loadChildren: () => import ('./demo/components/models/models.module').then(m => m.ModelsModule)
                    // },
                    {
                        path: 'projects',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/projects/projects.module').then(m => m.ProjectsModule)
                    },
                    {
                        path: 'project',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/projects/edit/project.edit.module').then(m => m.ProjectEditModule)
                    },
                    {
                        path: 'customers',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/customers/customers.module').then(m => m.CustomersModule)
                    },
                    {
                        path: 'agencys',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/agencys/agencys.module').then(m => m.AgencysModule)
                    },
                    {
                        path: 'service-providers',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/serviceProviders/service-providers.module').then(m => m.ServiceProvidersModule)
                    },
                    {
                        path: 'taskmanager',
                        canActivate: [AuthGuardService],
                        data: {role: ["Admin", "BookerAdmin", "Booker", "JuniorBooker"]},
                        loadChildren: () => import('./demo/components/taskManager/task-manager.module').then(m => m.TaskManagerModule)
                    },

                ]
            },
            {
                path: 'landing',
                loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule)
            },
            { path: 'unauthenticated', component: AccessErrorComponent },
            {path: 'notfound', component: NotfoundComponent},
            {path: 'login', component: LoginComponent},

            { path: 'models/personal/:id', redirectTo: 'models/:id'},
            { path: 'project/overview/:id', redirectTo: 'projects/in-progress/:id'},

            {path: '**', redirectTo: '/notfound'},
        ], {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            useHash: false
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
