import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageNotificationService {
  private newMessagesCountSubject = new BehaviorSubject<number>(0);
  newMessagesCount$ = this.newMessagesCountSubject.asObservable();

  private checkInBackgroundSubject = new BehaviorSubject<boolean>(true);
  checkInBackground$ = this.checkInBackgroundSubject.asObservable();

  updateNewMessagesCount(count: number) {
    this.newMessagesCountSubject.next(count);
  }

  updateCheckInBackground(checkInBackground: boolean) {
    this.checkInBackgroundSubject.next(checkInBackground);
  }
}
