<div class="flex">
    <div class="container-list">
        <h3>Direktnachrichten</h3>
        <!-- Updated search input field -->
        <div class="search-container">
            <input type="text" pInputText [(ngModel)]="searchQuery" (input)="onSearch()"
                placeholder="Suche nach Gesprächen oder Benutzern...">
        </div>
        <!-- Updated search results (always shown when searching) -->
        <ul *ngIf="searchQuery && searchQuery.length > 0" class="search-results">
            <li *ngFor="let result of searchResults" (click)="selectSearchResult(result)">
                <span [ngStyle]="{'color': isExistingConversation(result) ? 'blue' : 'black'}">
                    {{ result.name }}
                </span>
            </li>
        </ul>
        <!-- Skeleton loader for direct conversations -->
        <ul *ngIf="isLoading" class="conversation-list">
            <li *ngFor="let i of [1,2,3,4,5]" class="skeleton-item">
                <div class="skeleton-name"></div>
                <div class="skeleton-message"></div>
                <div class="skeleton-timestamp"></div>
            </li>
        </ul>
        <!-- Conversation list (shows filtered results when searching) -->
        <ul *ngIf="!isLoading" class="conversation-list">
            <li *ngFor="let conversation of filteredDirectConversations" (click)="selectConversation(conversation)">
                <div class="conversation-item"
                    [ngClass]="{
                        'selected': conversation.conversationId === selectedConversationId,
                        'has-new-messages': conversation.numberOfNewMessages > 0
                    }">
                    <span class="name">{{ conversation.otherParticipantName }}</span>
                    <span class="last-message">{{ conversation.lastMessage ? ((conversation.lastMessage | slice:0:30) +
                        (conversation.lastMessage.length > 30 ? '...' : '')) : 'Keine Nachrichten' }}</span>
                    <span class="timestamp">{{ conversation.lastMessageTimeStamp ? (conversation.lastMessageTimeStamp |
                        date:'dd.MM.yyyy HH:mm') : 'Kein Datum' }}</span>
                    <span class="new-message" *ngIf="conversation.numberOfNewMessages > 0">{{
                        conversation.numberOfNewMessages }}</span>
                </div>
            </li>
        </ul>
        <h3>Projektgespräche</h3>
        <!-- Add this new search input for project conversations -->
        <div class="search-container">
            <input type="text" pInputText [(ngModel)]="projectSearchQuery" (input)="onProjectSearch()"
                placeholder="Suche nach Projekten...">
        </div>
        <!-- Skeleton loader for project conversations -->
        <ul *ngIf="isLoading" class="conversation-list">
            <li *ngFor="let i of [1,2,3]" class="skeleton-item">
                <div class="skeleton-name"></div>
                <div class="skeleton-participants"></div>
            </li>
        </ul>
        <ul *ngIf="!isLoading" class="conversation-list">
            <li *ngFor="let conversation of filteredProjectConversations">
                <div class="project-conversation-item" (click)="toggleProjectConversation(conversation)"
                    [ngClass]="{'has-new-messages': getProjectNewMessageCount(conversation) > 0}">
                    <span class="name" [title]="conversation.producerName">{{ conversation.producerName }}</span>
                    <span class="participants">{{ getTotalParticipants(conversation) }} Teilnehmer</span>
                    <span class="new-message" *ngIf="getProjectNewMessageCount(conversation) > 0">{{
                        getProjectNewMessageCount(conversation) }}</span>
                    <i class="pi"
                        [ngClass]="{'pi-chevron-down': !conversation.isExpanded, 'pi-chevron-up': conversation.isExpanded}"></i>
                </div>
                <div *ngIf="conversation.isExpanded" class="project-conversation-details">
                    <div class="broadcast-all-section">
                        <div class="participant-item toggle-all">
                            <div class="participant-info">
                                <i class="pi pi-users"></i>
                                <span>Nachricht an alle Teilnehmer</span>
                            </div>
                            <p-checkbox [(ngModel)]="conversation.broadcastSelected"
                                (onChange)="toggleProjectBroadcast(conversation)" [binary]="true">
                            </p-checkbox>
                        </div>
                    </div>
                    <h4>Rollen</h4>
                    <ul class="roles-list">
                        <li *ngFor="let role of conversation.roles" class="role-item">
                            <div class="role-header" (click)="toggleRole(role)" style="cursor: pointer;">
                                <i class="pi pi-users"></i>
                                <span class="role-name">{{ role.name }}</span>
                                <i class="pi"
                                    [ngClass]="{'pi-chevron-down': !role.isExpanded, 'pi-chevron-up': role.isExpanded}"
                                    style="margin-left: 5px; position: relative; top: 1px;"></i>
                                <span class="new-message" *ngIf="getRoleNewMessageCount(role) > 0">{{
                                    getRoleNewMessageCount(role) }}</span>
                            </div>
                            <ul *ngIf="role.isExpanded" class="participants-list">
                                <!-- Toggle All Checkbox -->
                                <li class="participant-item toggle-all">
                                    <div class="participant-info">
                                        <span>Alle Models auswählen</span>
                                    </div>
                                    <p-checkbox [(ngModel)]="role.allSelected" (onChange)="toggleAllModels(role, conversation.projectId)"
                                        [binary]="true">
                                    </p-checkbox>
                                </li>
                                <!-- Participant list -->
                                <li *ngFor="let participant of role.participants" class="participant-item" [ngClass]="{
                                        'no-account': !participant.oauthId,
                                        'selected': participant.oauthId && 
                                                   selectedParticipant?.oauthId === participant.oauthId && 
                                                   selectedParticipant?.roleId === role.roleId,
                                        'has-new-messages': participant.numberOfNewMessages > 0
                                    }">
                                    <div class="participant-info"
                                        (click)="selectParticipant(participant, conversation.projectId, role.roleId)"
                                        [pTooltip]="!participant.oauthId ? 'Benutzerkonto erforderlich' : ''">
                                        <i class="pi"
                                            [ngClass]="{'pi-user': participant.oauthId, 'pi-user-minus': !participant.oauthId}"></i>
                                        <span>{{ participant.name || 'Unbenannter Benutzer' }}</span>
                                        <i *ngIf="!participant.oauthId" class="pi pi-exclamation-circle"
                                            style="color: orange; margin-left: 5px;"></i>
                                        <span class="new-message" *ngIf="participant.numberOfNewMessages > 0">{{
                                            participant.numberOfNewMessages }}</span>
                                    </div>
                                    <p-checkbox *ngIf="participant.oauthId" 
                                                [(ngModel)]="participant.isSelected"
                                                (onChange)="onModelSelectionChange(role, conversation.projectId, participant)"
                                                [binary]="true"
                                                (click)="$event.stopPropagation()">
                                    </p-checkbox>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div *ngIf="conversation.additionalParticipants.length > 0">
                        <h4>Zusätzliche Teilnehmer</h4>
                        <ul class="participants-list">
                            <li *ngFor="let participant of conversation.additionalParticipants" 
                                class="participant-item"
                                [ngClass]="{
                                    'no-account': !participant.oauthId, 
                                    'selected': participant.oauthId && 
                                               selectedParticipant?.oauthId === participant.oauthId && 
                                               !selectedParticipant?.roleId,
                                    'has-new-messages': participant.numberOfNewMessages > 0
                                }" 
                                [pTooltip]="!participant.oauthId ? 'Benutzerkonto erforderlich' : ''"
                                (click)="selectParticipant(participant, conversation.projectId)">
                                <div class="participant-info">
                                    <i class="pi"
                                        [ngClass]="{'pi-user': participant.oauthId, 'pi-user-minus': !participant.oauthId}"></i>
                                    <span>{{ participant.name || 'Unbenannter Benutzer' }}</span>
                                    <i *ngIf="!participant.oauthId" class="pi pi-exclamation-circle"
                                        style="color: orange; margin-left: 5px; position: relative; top: 2px;"></i>
                                </div>
                                <p-checkbox *ngIf="participant.oauthId" 
                                            [(ngModel)]="participant.isSelected"
                                            (onChange)="onModelSelectionChange(null, conversation.projectId, participant)"
                                            [binary]="true"
                                            (click)="$event.stopPropagation()">
                                </p-checkbox>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="container-chat">
        <app-conversation-chat
            *ngIf="selectedConversation !== null && !showNoAccountMessage && !showBroadcastInterface && !selectedModels.length"
            [isVisible]="true" [conversationId]="selectedReceiverConversationId" [messageList]="messageList"
            [currentUserOauthId]="currentUserOauthId" [isGroupChat]="false" [shouldScrollToBottom]="true"
            [onSendMessage]="onSendMessage.bind(this)" [onDeleteMessage]="onDeleteMessage.bind(this)"
            [onCameraButtonClick]="onCameraButtonClick.bind(this)" [onRefreshMessages]="onRefreshMessages.bind(this)"
            [projectId]="selectedProjectId" [onForwardMessage]="forwardMessage.bind(this)">
        </app-conversation-chat>

        <!-- Neue Position für das Nachrichteninterface -->
        <div *ngIf="selectedModels.length > 0" class="model-message-interface">
            <div class="header">
                <h3>{{ selectedRole?.roleId === 'broadcast' ? 'Nachricht an alle Teammitglieder' : 'Nachricht an
                    ausgewählte Models' }}</h3>
                <p>{{ selectedModels.length }} {{ selectedRole?.roleId === 'broadcast' ? 'Teammitglieder' : 'Models' }}
                    ausgewählt</p>
                <div class="selected-models-list">
                    <span *ngFor="let model of selectedModels" class="selected-model-chip">
                        {{ model.name }}
                        <i class="pi pi-times" (click)="removeModel(model)"></i>
                    </span>
                </div>
            </div>

            <div class="message-input">
                <textarea pInputTextarea [(ngModel)]="modelMessage"
                    placeholder="Ihre Nachricht an die ausgewählten Models..." rows="5">
                </textarea>
            </div>

            <div class="button-container">
                <button pButton type="button" label="Auswahl aufheben" (click)="clearModelSelection()"
                    class="p-button-text">
                </button>
                <button pButton type="button" label="Nachricht senden" (click)="sendModelMessage()"
                    [disabled]="!modelMessage.trim()" class="p-button-primary">
                </button>
            </div>
        </div>

        <div *ngIf="showNoAccountMessage" class="no-account-message">
            <h3>Gespräch kann nicht gestartet werden</h3>
            <p>Der ausgewählte Teilnehmer hat noch kein Benutzerkonto. Er muss ein Konto erstellen, bevor Sie ihm eine
                Nachricht senden können.</p>
            <p>Bitte wählen Sie einen anderen Teilnehmer aus oder warten Sie, bis dieser Benutzer ein Konto erstellt
                hat.</p>
        </div>
        <!-- <div *ngIf="showBroadcastInterface" class="broadcast-interface">
            <h3>Nachricht an alle senden</h3>
            <p>Rolle: {{ selectedRole?.name }}</p>
            <div class="p-fluid">
                <div class="p-field">
                    <label for="broadcastMessage">Nachricht</label>
                    <textarea id="broadcastMessage" pInputTextarea [(ngModel)]="broadcastMessage" rows="5" style="width: 100%;"></textarea>
                </div>
            </div>
            <div class="button-container">
                <button pButton type="button" label="Abbrechen" (click)="showBroadcastInterface = false" class="p-button-text"></button>
                <button pButton type="button" label="Senden" (click)="sendBroadcastMessage()" class="p-button-text"></button>
            </div>
        </div> -->
    </div>
</div>
<p-toast></p-toast>
<p-dialog header="Nachricht weiterleiten" 
          [(visible)]="showForwardDialog" 
          [style]="{width: '800px', maxWidth: '90vw'}" 
          [modal]="true">
    <div class="forward-dialog-content">
        <!-- Suchfeld -->
        <div class="search-container">
            <input type="text" pInputText [(ngModel)]="forwardSearchQuery" 
                   (input)="onForwardSearch()"
                   placeholder="Suche nach Gesprächen oder Benutzern...">
        </div>
        
        <!-- Suchergebnisse -->
        <ul *ngIf="forwardSearchQuery && forwardSearchQuery.length > 0" class="search-results">
            <li *ngFor="let result of forwardSearchResults" 
                (click)="selectForwardSearchResult(result)">
                <span [ngStyle]="{'color': isExistingConversation(result) ? 'blue' : 'black'}">
                    {{ result.name }}
                </span>
            </li>
        </ul>

        <!-- Ausgewählte Empfänger -->
        <div class="selected-recipients" *ngIf="selectedForwardRecipients.length > 0">
            <h4>Ausgewählte Empfänger:</h4>
            <div class="recipient-chips">
                <span *ngFor="let recipient of selectedForwardRecipients" class="recipient-chip">
                    {{ recipient.name || recipient.otherParticipantName }}
                    <i class="pi pi-times" (click)="removeForwardRecipient(recipient)"></i>
                </span>
            </div>
        </div>

        <p>Wählen Sie weitere Empfänger aus:</p>
        
        <!-- Direct Conversations -->
        <h4>Direkte Kontakte</h4>
        <div class="forward-recipients">
            <p-checkbox *ngFor="let conv of directConversations" 
                       [value]="conv" 
                       [label]="conv.otherParticipantName"
                       [binary]="true"
                       [inputId]="'conv_' + conv.conversationId"
                       [(ngModel)]="conv.isSelected"
                       (onChange)="toggleRecipientSelection(conv)">
            </p-checkbox>
        </div>

        <!-- Project Conversations -->
        <h4>Projektkontakte</h4>
        <div *ngFor="let project of getValidProjects(projectConversations)" class="project-forward-section">
            <h5>{{project.producerName}}</h5>
            <div *ngFor="let role of getValidRoles(project.roles)">
                <h6>{{role.name}}</h6>
                <div class="forward-recipients">
                    <p-checkbox *ngFor="let participant of getValidParticipants(role.participants)" 
                              [value]="participant" 
                              [(ngModel)]="selectedForwardRecipients"
                              [label]="participant.name || 'Unbenannter Benutzer'">
                    </p-checkbox>
                </div>
            </div>
            <div *ngIf="getValidParticipants(project.additionalParticipants).length > 0">
                <h6>Zusätzliche Teilnehmer</h6>
                <div class="forward-recipients">
                    <p-checkbox *ngFor="let participant of getValidParticipants(project.additionalParticipants)" 
                              [value]="participant" 
                              [(ngModel)]="selectedForwardRecipients"
                              [label]="participant.name || 'Unbenannter Benutzer'">
                    </p-checkbox>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Abbrechen" (click)="closeForwardDialog()" class="p-button-text"></button>
        <button pButton label="Weiterleiten" (click)="sendForwardedMessage()" 
                [disabled]="selectedForwardRecipients.length === 0"></button>
    </ng-template>
</p-dialog>