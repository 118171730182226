/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilenameDto
 */
export interface FilenameDto {
    /**
     * 
     * @type {string}
     * @memberof FilenameDto
     */
    filename?: string;
}

/**
 * Check if a given object implements the FilenameDto interface.
 */
export function instanceOfFilenameDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilenameDtoFromJSON(json: any): FilenameDto {
    return FilenameDtoFromJSONTyped(json, false);
}

export function FilenameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilenameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
    };
}

export function FilenameDtoToJSON(value?: FilenameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
    };
}

