import { ProductionDetailsFormValuesDto } from 'libs/api-typescript-angular/src/model/productionDetailsFormValuesDto';
import { ProjectBuyOutFromValuesDto } from 'libs/api-typescript-angular/src/model/projectBuyOutFromValuesDto';
import { FinalCustomerFormValuesDto } from 'libs/api-typescript-angular/src/model/finalCustomerFormValuesDto';
import { ProjectNameFormValuesDto } from 'libs/api-typescript-angular/src/model/projectNameFormValuesDto';
import { ExecutorFormValuesDto } from 'libs/api-typescript-angular/src/model/executorFormValuesDto';
import { ProjectStatusFormValuesDto } from 'libs/api-typescript-angular/src/model/projectStatusFormValuesDto';

export namespace ProjectEditProjectActions {
    export class UpdateName {
        static readonly type = '[ProjectEdit] Update Name';
        constructor(public name: ProjectNameFormValuesDto) { }
    }

    export class UpdateFinalCustomer {
        static readonly type = '[ProjectEdit] Update Final Customer';
        constructor(public finalCustomer: FinalCustomerFormValuesDto) { }
    }

    export class UpdateProductionDetails {
        static readonly type = '[ProjectEdit] Update Production Details';
        constructor(public productionDetails: ProductionDetailsFormValuesDto) { }
    }

    export class UpdateBuyOut {
        static readonly type = '[ProjectEdit] Update Buy Out';
        constructor(public buyOut: ProjectBuyOutFromValuesDto) { }
    }

    export class UpdateExecutor {
        static readonly type = '[ProjectEdit] Update Executor';
        constructor(public executor: ExecutorFormValuesDto) { }
    }

    export class UpdateStatus {
        static readonly type = '[ProjectEdit] Update Status';
        constructor(public status: ProjectStatusFormValuesDto) { }
    }
}