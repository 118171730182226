import {Injectable} from "@angular/core";
import {
    ConversationControllerApi,
    ConversationDto,
    ConversationResponseDto,
    CreateConversationRequest,
    CreateConversationRequestDto,
    CustomerNamesForMessengerDto,
    MessageDto,
    ParticipantResponseDto,
    ProjectNamesForMessengerDto
} from "@brody-bookings/api";
import { ConversationControllerService } from '@brody-bookings/api-v2';
import {BehaviorSubject, Observable, firstValueFrom, retry, timer} from "rxjs";
import {LanguageService} from "./language.service";

@Injectable()
export class ConversationService {
    private conversationItemListSubject: BehaviorSubject<ConversationDto[]> = new BehaviorSubject<ConversationDto[]>([])
    private conversationItemList$ = this.conversationItemListSubject.asObservable();

    private messageListSubject: BehaviorSubject<MessageDto[]> = new BehaviorSubject<MessageDto[]>([])
    private messageList$ = this.messageListSubject.asObservable();

    private currentUserSubject: BehaviorSubject<string> = new BehaviorSubject<string>("")
    private currentUser$ = this.currentUserSubject.asObservable()

    private modelParticipantListSubject: BehaviorSubject<ParticipantResponseDto[]> = new BehaviorSubject<ParticipantResponseDto[]>([])
    private modelParticipantList$ = this.modelParticipantListSubject.asObservable();

    private customerNameListSubject: BehaviorSubject<CustomerNamesForMessengerDto[]> = new BehaviorSubject<CustomerNamesForMessengerDto[]>([])
    private customerNameList$ = this.customerNameListSubject.asObservable();

    private customerParticipantListSubject: BehaviorSubject<ParticipantResponseDto[]> = new BehaviorSubject<ParticipantResponseDto[]>([])
    private customerParticipantList$ = this.customerParticipantListSubject.asObservable();

    private ProjectNameListSubject: BehaviorSubject<ProjectNamesForMessengerDto[]> = new BehaviorSubject<ProjectNamesForMessengerDto[]>([])
    private ProjectNameList$ = this.ProjectNameListSubject.asObservable();

    private ProjectParticipantListSubject: BehaviorSubject<ParticipantResponseDto[]> = new BehaviorSubject<ParticipantResponseDto[]>([])
    private ProjectParticipantList$ = this.ProjectParticipantListSubject.asObservable();

    constructor(
        private conversationControllerApi: ConversationControllerApi,
        private conversationControllerService: ConversationControllerService,
        private languageService: LanguageService
    ) {
    }

    public getConversationList(): Observable<ConversationDto[]> {
        this.languageService.getLanguage$().subscribe((currentLanguage) => {
            this.conversationControllerApi.getConversations({ language: currentLanguage }).then((result) => {
                const conversationArray: ConversationDto[] = Array.isArray(result) ? result : Object.values(result);
                this.conversationItemListSubject.next(conversationArray);
            });
        });
        return this.conversationItemList$;
    }

    public fetchConversations(){
        return this.conversationItemList$
    }

    public fetchMessages(){
        return this.messageList$
    }

    public getMessagesByConversationId(conversationId: string){
        this.conversationControllerApi.getMessagesByConversationId({ conversationId }).then((result) => {
            const updatedMessages = [...result];
            this.messageListSubject.next(updatedMessages);
        });
        return this.messageList$;
    }

    public sendMessage(conversationId: string, message: string, images: Array<Blob>){
        let currentValue = this.messageListSubject.getValue()
        return this.conversationControllerApi.addMessage({
            conversationId,
            message,
            images,
        }).then((result) => {
            if (result.messages && result.messages.length > 0) {
                this.messageListSubject.next([...currentValue, ...result.messages]);
            }

            return this.messageList$
        })
    }

    public getModelsAsParticipants(){
        this.languageService.getLanguage$().subscribe((currentLanguage) => {
            this.conversationControllerApi.getModels1({ language: currentLanguage }).then((result) => {
                const participantArray: ParticipantResponseDto[] = Array.isArray(result) ? result : Object.values(result);
                this.modelParticipantListSubject.next(participantArray);
            });
        })
    }

    public fetchModelParticipants(){
        return this.modelParticipantList$
    }

    public getCustomerNames(){
        this.conversationControllerApi.getCustomerNames().then((result) => {
            const customerNameArray: CustomerNamesForMessengerDto[] = Array.isArray(result) ? result : Object.values(result);
            this.customerNameListSubject.next(customerNameArray);
        })
    }

    public fetchCustomerNames(){
        return this.customerNameList$
    }

    public getProjectNames(){
        this.conversationControllerApi.getProjects().then((result) => {
            const ProjectNameArray: CustomerNamesForMessengerDto[] = Array.isArray(result) ? result : Object.values(result);
            this.customerNameListSubject.next(ProjectNameArray);
        })
    }

    public fetchProjectNames(){
        return this.ProjectNameList$
    }

    public getProjectParticipants(projectId : string){
        this.conversationControllerApi.getProjectParticipants({projectId}).then((result) => {
            const ProjectParticipantArray: ParticipantResponseDto[] = Array.isArray(result) ? result : Object.values(result);
            this.ProjectParticipantListSubject.next(ProjectParticipantArray);
        })
    }

    public fetchProjectParticipants(){
        return this.ProjectParticipantList$
    }

    public getCustomerParticipants(customerId : string){
        this.conversationControllerApi.getCustomerEmployees({customerId}).then((result) => {
            const customerParticipantArray: ParticipantResponseDto[] = Array.isArray(result) ? result : Object.values(result);
            this.customerParticipantListSubject.next(customerParticipantArray);
        })
    }

    public fetchCustomerParticipants(){
        return this.customerParticipantList$
    }

    public getModelsBySearchTerm(searchTerm: string){
        this.languageService.getLanguage$().subscribe((currentLanguage) => {
            this.conversationControllerApi.getModelBySearchTerm({ language: currentLanguage, searchterm:searchTerm }).then((result) => {
                const participantArray: ParticipantResponseDto[] = Array.isArray(result) ? result : Object.values(result);
                this.modelParticipantListSubject.next(participantArray);
            })
        })
    }
    public getCurrentUser(){
        this.conversationControllerApi.getCurrentUser().then((result) => {
            this.currentUserSubject.next(result)
        })
        return this.currentUser$
    }

    public deleteConversation(conversationId:string){
        let currentValue = this.conversationItemListSubject.getValue();
        this.conversationControllerApi.deleteConversation({conversationId}).then(() => {
            this.conversationItemListSubject.next(
                currentValue.filter(v => v.conversationId != conversationId)
            )
        })
    }

    public deleteMessage(conversationId:string,messageId:string){
        let currentValue = this.messageListSubject.getValue();
        this.conversationControllerApi.deleteMessage({conversationId,messageId}).then(() => {
            this.messageListSubject.next(
                currentValue.filter(v => v.messageId != messageId)
            )
        })
    }

    public createConversation(createConversationRequestDto:CreateConversationRequestDto){
         this.languageService.getLanguage$().subscribe((currentLanguage) => {
            this.conversationControllerApi.createConversation({ createConversationRequestDto:createConversationRequestDto, language: currentLanguage }).then((result) => {
                let currentValue = this.conversationItemListSubject.getValue();
                this.conversationItemListSubject.next(
                     [{
                        conversationId: result.conversationId,
                        conversationTitle: result.conversationTitle,
                        participantResponseDtoList: result.participants
                    }, ...currentValue]
                )
            })
        })
    }

    public changeConversationTitle(conversationId: string, newTitle: string){
        this.conversationControllerApi.changeConversationTitle({conversationId: conversationId, title: newTitle}).then(() => {
            let currentValue = this.conversationItemListSubject.getValue();
            this.conversationItemListSubject.next(
                currentValue.map(v => v.conversationId == conversationId ? {...v, conversationTitle: newTitle} : v)
            )
        })
    }

    public updateMessageTracker(conversationId: string): void {
        this.conversationControllerApi.autoUpdateTracking({conversationId: conversationId}).then(() => {
            let currentValue = this.conversationItemListSubject.getValue();

            // Find the conversation index in the list
            const conversationIndex = currentValue.findIndex(v => v.conversationId === conversationId);

            if (conversationIndex !== -1) {
                // Update the conversation's hasNewMessage attribute to false
                currentValue[conversationIndex] = {
                    ...currentValue[conversationIndex],
                    hasNewMessage: false
                };

                // Update the subject with the updated list
                this.conversationItemListSubject.next([...currentValue]);
            }
        });
    }

    getNumberOfNewMessages(participantId: string): Promise<number> {
        return firstValueFrom(
            this.conversationControllerService.getNumberOfNewMessages(participantId).pipe(
                retry({
                    count: 3,
                    delay: (error, retryCount) => timer(1000 * retryCount) // Exponentielles Backoff: 1s, 2s, 3s
                })
            )
        );
    }

}
