import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LanguageService } from '../../../../../service/language.service';
import { ConversationControllerService } from '@brody-bookings/api-v2';

@Injectable()
export class WidgetConversationService {

    constructor(
        private languageService: LanguageService,
        private conversationControllerService: ConversationControllerService
    ) {}

    public async sendMessage(participantIds: string, message: string, isBroadcast: boolean, images: Array<Blob>, conversationId?: string, projectId?: string, roleId?: string) {
        const _isBroadcast = isBroadcast ? "true" : "false";

        return firstValueFrom(this.conversationControllerService.addMessageNew(
            images,
            message,
            _isBroadcast,
            participantIds,
            projectId,
            roleId,
            conversationId
        ));
    }

    deleteMessage(conversationId: string, messageId: string) {
        return firstValueFrom(this.conversationControllerService.deleteMessage(conversationId, messageId));
    }

    refreshMessages(conversationId: string) {
        return firstValueFrom(this.conversationControllerService.getMessagesByConversationId(conversationId));
    }
}
