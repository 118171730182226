/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetConversationOfParticipantsByProjectIdCommand
 */
export interface GetConversationOfParticipantsByProjectIdCommand {
    /**
     * 
     * @type {string}
     * @memberof GetConversationOfParticipantsByProjectIdCommand
     */
    senderId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConversationOfParticipantsByProjectIdCommand
     */
    participantIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetConversationOfParticipantsByProjectIdCommand
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetConversationOfParticipantsByProjectIdCommand
     */
    roleId?: string;
}

/**
 * Check if a given object implements the GetConversationOfParticipantsByProjectIdCommand interface.
 */
export function instanceOfGetConversationOfParticipantsByProjectIdCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetConversationOfParticipantsByProjectIdCommandFromJSON(json: any): GetConversationOfParticipantsByProjectIdCommand {
    return GetConversationOfParticipantsByProjectIdCommandFromJSONTyped(json, false);
}

export function GetConversationOfParticipantsByProjectIdCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetConversationOfParticipantsByProjectIdCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'senderId': !exists(json, 'senderId') ? undefined : json['senderId'],
        'participantIds': !exists(json, 'participantIds') ? undefined : json['participantIds'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
    };
}

export function GetConversationOfParticipantsByProjectIdCommandToJSON(value?: GetConversationOfParticipantsByProjectIdCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'senderId': value.senderId,
        'participantIds': value.participantIds,
        'projectId': value.projectId,
        'roleId': value.roleId,
    };
}

