/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface ModelMasterDataSearchFormValuesDto
 */
export interface ModelMasterDataSearchFormValuesDto {
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    eyeColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    sexes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    hairColors?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    hairLengths?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    hairStyles?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    confectionSizes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    braCupSizes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    skinTypes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    shoesSizes?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    shoesSizesUS?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    modelCategories?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    nationalities?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    ethnicities?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    driverLicenses?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    professions?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    specials?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    piercings?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    tattoos?: Array<AdminValue>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    sports?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    sportLevels?: Array<string>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    dances?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    danceLevels?: Array<string>;
    /**
     * 
     * @type {Array<AdminValue>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    spokenLanguages?: Array<AdminValue>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchFormValuesDto
     */
    spokenLanguageLevels?: Array<string>;
}

/**
 * Check if a given object implements the ModelMasterDataSearchFormValuesDto interface.
 */
export function instanceOfModelMasterDataSearchFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelMasterDataSearchFormValuesDtoFromJSON(json: any): ModelMasterDataSearchFormValuesDto {
    return ModelMasterDataSearchFormValuesDtoFromJSONTyped(json, false);
}

export function ModelMasterDataSearchFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataSearchFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eyeColors': !exists(json, 'eye_colors') ? undefined : ((json['eye_colors'] as Array<any>).map(AdminValueFromJSON)),
        'sexes': !exists(json, 'sexes') ? undefined : ((json['sexes'] as Array<any>).map(AdminValueFromJSON)),
        'hairColors': !exists(json, 'hair_colors') ? undefined : ((json['hair_colors'] as Array<any>).map(AdminValueFromJSON)),
        'hairLengths': !exists(json, 'hair_lengths') ? undefined : ((json['hair_lengths'] as Array<any>).map(AdminValueFromJSON)),
        'hairStyles': !exists(json, 'hair_styles') ? undefined : ((json['hair_styles'] as Array<any>).map(AdminValueFromJSON)),
        'confectionSizes': !exists(json, 'confectionSizes') ? undefined : ((json['confectionSizes'] as Array<any>).map(AdminValueFromJSON)),
        'braCupSizes': !exists(json, 'braCupSizes') ? undefined : ((json['braCupSizes'] as Array<any>).map(AdminValueFromJSON)),
        'skinTypes': !exists(json, 'skinTypes') ? undefined : ((json['skinTypes'] as Array<any>).map(AdminValueFromJSON)),
        'shoesSizes': !exists(json, 'shoesSizes') ? undefined : ((json['shoesSizes'] as Array<any>).map(AdminValueFromJSON)),
        'shoesSizesUS': !exists(json, 'shoesSizesUS') ? undefined : ((json['shoesSizesUS'] as Array<any>).map(AdminValueFromJSON)),
        'modelCategories': !exists(json, 'modelCategories') ? undefined : ((json['modelCategories'] as Array<any>).map(AdminValueFromJSON)),
        'nationalities': !exists(json, 'nationalities') ? undefined : ((json['nationalities'] as Array<any>).map(AdminValueFromJSON)),
        'ethnicities': !exists(json, 'ethnicities') ? undefined : ((json['ethnicities'] as Array<any>).map(AdminValueFromJSON)),
        'driverLicenses': !exists(json, 'driverLicenses') ? undefined : ((json['driverLicenses'] as Array<any>).map(AdminValueFromJSON)),
        'professions': !exists(json, 'professions') ? undefined : ((json['professions'] as Array<any>).map(AdminValueFromJSON)),
        'specials': !exists(json, 'specials') ? undefined : ((json['specials'] as Array<any>).map(AdminValueFromJSON)),
        'piercings': !exists(json, 'piercings') ? undefined : ((json['piercings'] as Array<any>).map(AdminValueFromJSON)),
        'tattoos': !exists(json, 'tattoos') ? undefined : ((json['tattoos'] as Array<any>).map(AdminValueFromJSON)),
        'sports': !exists(json, 'sports') ? undefined : ((json['sports'] as Array<any>).map(AdminValueFromJSON)),
        'sportLevels': !exists(json, 'sportLevels') ? undefined : json['sportLevels'],
        'dances': !exists(json, 'dances') ? undefined : ((json['dances'] as Array<any>).map(AdminValueFromJSON)),
        'danceLevels': !exists(json, 'danceLevels') ? undefined : json['danceLevels'],
        'spokenLanguages': !exists(json, 'spokenLanguages') ? undefined : ((json['spokenLanguages'] as Array<any>).map(AdminValueFromJSON)),
        'spokenLanguageLevels': !exists(json, 'spokenLanguageLevels') ? undefined : json['spokenLanguageLevels'],
    };
}

export function ModelMasterDataSearchFormValuesDtoToJSON(value?: ModelMasterDataSearchFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eye_colors': value.eyeColors === undefined ? undefined : ((value.eyeColors as Array<any>).map(AdminValueToJSON)),
        'sexes': value.sexes === undefined ? undefined : ((value.sexes as Array<any>).map(AdminValueToJSON)),
        'hair_colors': value.hairColors === undefined ? undefined : ((value.hairColors as Array<any>).map(AdminValueToJSON)),
        'hair_lengths': value.hairLengths === undefined ? undefined : ((value.hairLengths as Array<any>).map(AdminValueToJSON)),
        'hair_styles': value.hairStyles === undefined ? undefined : ((value.hairStyles as Array<any>).map(AdminValueToJSON)),
        'confectionSizes': value.confectionSizes === undefined ? undefined : ((value.confectionSizes as Array<any>).map(AdminValueToJSON)),
        'braCupSizes': value.braCupSizes === undefined ? undefined : ((value.braCupSizes as Array<any>).map(AdminValueToJSON)),
        'skinTypes': value.skinTypes === undefined ? undefined : ((value.skinTypes as Array<any>).map(AdminValueToJSON)),
        'shoesSizes': value.shoesSizes === undefined ? undefined : ((value.shoesSizes as Array<any>).map(AdminValueToJSON)),
        'shoesSizesUS': value.shoesSizesUS === undefined ? undefined : ((value.shoesSizesUS as Array<any>).map(AdminValueToJSON)),
        'modelCategories': value.modelCategories === undefined ? undefined : ((value.modelCategories as Array<any>).map(AdminValueToJSON)),
        'nationalities': value.nationalities === undefined ? undefined : ((value.nationalities as Array<any>).map(AdminValueToJSON)),
        'ethnicities': value.ethnicities === undefined ? undefined : ((value.ethnicities as Array<any>).map(AdminValueToJSON)),
        'driverLicenses': value.driverLicenses === undefined ? undefined : ((value.driverLicenses as Array<any>).map(AdminValueToJSON)),
        'professions': value.professions === undefined ? undefined : ((value.professions as Array<any>).map(AdminValueToJSON)),
        'specials': value.specials === undefined ? undefined : ((value.specials as Array<any>).map(AdminValueToJSON)),
        'piercings': value.piercings === undefined ? undefined : ((value.piercings as Array<any>).map(AdminValueToJSON)),
        'tattoos': value.tattoos === undefined ? undefined : ((value.tattoos as Array<any>).map(AdminValueToJSON)),
        'sports': value.sports === undefined ? undefined : ((value.sports as Array<any>).map(AdminValueToJSON)),
        'sportLevels': value.sportLevels,
        'dances': value.dances === undefined ? undefined : ((value.dances as Array<any>).map(AdminValueToJSON)),
        'danceLevels': value.danceLevels,
        'spokenLanguages': value.spokenLanguages === undefined ? undefined : ((value.spokenLanguages as Array<any>).map(AdminValueToJSON)),
        'spokenLanguageLevels': value.spokenLanguageLevels,
    };
}

