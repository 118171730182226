import { Component, OnInit } from '@angular/core';
import { StatisticsControllerService, StatisticsDTO } from '../../../../../../../libs/api-typescript-angular/src';
import { retry, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  // Inject the service
  constructor(private statisticsService: StatisticsControllerService) { }

  ngOnInit(): void {
    // Fetch statistics data on component initialization
    setTimeout(() => {
      this.statisticsService.getStatistics().pipe(
        retry(3), // Retry failed requests up to 3 times
        catchError(error => {
          console.error('Error fetching statistics:', error);
          return of({ activeProjects: 0, activeModels: 0, externalModels: 0 });
        })
      ).subscribe((data: StatisticsDTO) => {
        this.statistics = {
          runningProjects: data.activeProjects || 0,
          models: data.activeModels || 0,
          externalModels: data.externalModels || 0
        }
      });
    }, 1000);
  }

  // Initial empty statistics, will be replaced with actual data from the service
  statistics = {
    runningProjects: 0,
    models: 0,
    externalModels: 0
  };

}
