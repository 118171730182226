import { ProjectRoleFormValuesDto } from "libs/api-typescript-angular/src/model/projectRoleFormValuesDto";
import { ChangeRoleDetailsRequestDto } from "libs/api-typescript-angular/src/model/changeRoleDetailsRequestDto";

export namespace ProjectEditRolesActions {
    export class LoadRolesAsOptions {
        static readonly type = '[ProjectEdit] Load Roles As Options';
        constructor(public projectId: string) { }
    }

    export class ChangeRole {
        static readonly type = '[ProjectEdit] Change Role';
        constructor(public projectRoleFormValuesDto: ProjectRoleFormValuesDto) { }
    }

    export class GetProjectRoleFormValues {
        static readonly type = '[ProjectEdit] Get Project Role Form Values';
        constructor(
            public projectId: string,
            public projectRoleId: string
        ) { }
    }

    export class AddProjectRole {
        static readonly type = '[ProjectEdit] Add Project Role';
        constructor(
            public projectId: string,
            public name: string
        ) { }
    }

    export class DeleteProjectRole {
        static readonly type = '[ProjectEdit] Delete Project Role';
        constructor(
            public projectId: string,
            public projectRoleId: string
        ) { }
    }

    export class SetSelectedRole {
        static readonly type = '[ProjectEdit] Set Selected Role';
        constructor(public projectRoleFormValuesDto: ProjectRoleFormValuesDto) { }
    }

    export class SetSelectedRoleInProductionDetails {
        static readonly type = '[ProjectEdit] Set Selected Role In Production Details';
        constructor(public projectRoleForSelectionDto: ProjectRoleFormValuesDto) { }
    }

    export class ChangeRoleDetailsInProductionDetails {
        static readonly type = '[Project Edit Role] Change Role Details In Production Details';
        constructor(
            public projectId: string,
            public payload: ChangeRoleDetailsRequestDto
        ) {}
    }
}
