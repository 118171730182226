import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TranslationService {

    constructor(
        private translate: TranslateService
    ) {
    }

    getMenuTranslations(): Promise<MenuTranslations> {
        return this.translate.get('MENU').toPromise()
    }

    getLoginTranslation(): Promise<LoginTranslations> {
        return this.translate.get('LOGIN').toPromise()
    }

    async getMenuTabsTranslations(): Promise<MenuTabsTranslations> {
        return await firstValueFrom(this.translate.get('MENU_TABS'));
    }

    async getCalendarTranslations(): Promise<CalendarTranslations> {
        return await firstValueFrom(this.translate.get('SHARED.CALENDAR'));
    }

    async getTabHeadersTranslations(): Promise<TabsModelsTranslations> {
        return await firstValueFrom(this.translate.get('TAB_HEADERS'));
    }

}

export interface MenuTranslations {
    HOME: HomeTranslations;
    ADMIN: AdminTranslations;
    PROJECTS: ProjectsTranslations;
    ADDRESS_BOOK: AddressBookTranslations;
    TASK_MANAGER: TaskManagerTranslations;
    STANDING_DATA: StandingDataTranslations;
    PROJECT_LOCATIONS: ProjectLocationsTranslations;
    CONVERSATIONS: ConversationsTranslations;
}

export interface LoginTranslations{
    labelLogIn:string,
    labelSignUp: string,
    labelMessage:string
}

export interface HomeTranslations {
    label: string;
    dashboard: {
        label: string;
    };
}

export interface AdminTranslations {
    labelInstruments:string;
    labelCategories:string;
    labelLanguages: string;
    label: string;
    labelCountries: string;
    labelCities: string;
    labelEthnicities: string;
    labelDanceStyles: string;
    labelDiets: string;
    labelDriverLicenses: string;
    labelEyeColors: string;
    labelHairColorNuances: string;
    labelHairColors: string;
    labelHairLengths: string;
    labelHairStyles: string;
    labelPiercings: string;
    labelSex: string;
    labelSkinColors: string;
    labelSkinTypes: string;
    labelSportTypes: string;
    labelTattoos: string;
    labelSpecials:string;
    labelAttaches:string;
    labelIndustryFields:string;
    labelActivityField:string;
    labelPositions:string;
    labelCostReasons:string;
    labelTransportationMeans:string;
    labelExtraExpenses:string;
}

export interface ProjectsTranslations {
    label: string;
    labelDiscarded: string;
    labelFinished: string;
    labelInProgress: string;
}

export interface AddressBookTranslations {
    label: string;
    "customer": {
        "label": string;
    };
    "agency": {
        "label": string;
    },
    "model": {
        "label": string
    },
    "service_provider": {
        "label": string
    }
}

export interface TaskManagerTranslations {
  label: string;
  "projects": {
    "label": string;
  };
  "bills": {
    "label": string;
  },
  "update_portfolio": {
    "label": string
  },
  "commissions": {
    "label": string
  }
}

export interface StandingDataTranslations {
    label: string;
    "models": {
        "label": string;
    }
}

export interface ProjectLocationsTranslations {
    label: string;
    collections: {
        label: string;
    };
    add_location: {
        label: string;
    };
}

export interface ConversationsTranslations {
    label: string;
    conversations: {
        label: string;
    }
}

export interface MenuTabsTranslations {
    PROJECTS: TabsProjectsTranslations;
    CUSTOMER: TabsCustomerTranslations;
    AGENCY: TabsAgencyTranslations;
    MODELS: TabsModelsTranslations;
}

export interface TabsProjectsTranslations {
    labelProject: string;
    labelModelSelection: string;
    labelDetailsCasting: string;
    labelDetailsProduction: string;
    labelBuyout: string;
    labelBooking: string;
    labelInvoice: string;
    labelProtocol: string;
}

export interface TabsCustomerTranslations {
    labelShortInfo: string;
    labelAddresses: string;
    labelEmployees: string;
    labelManagement: string;
    labelIndustryfields: string;
    labelProtocol: string;
    labelJobHistory: string;
}

export interface TabsAgencyTranslations {
    labelShortInfo: string;
    labelAddresses: string;
    labelBookers: string;
    labelManagement: string;
    labelActivityFields: string;
    labelProtocol: string;
}

export interface TabsModelsTranslations {
    labelPersonalInfo: string;
    labelPortfolio: string;
    labelCalender: string;
    labelAddresses: string;
    labelManagment: string;
    labelBody: string;
    labelSpecials: string;
    labelAgency: string;
    labelCosts: string;
    labelBilling: string;
    labelProtocol: string;
    labelJobHistory: string;
}

export interface CalendarTranslations {
  APPOINTMENT_FORM: {
    showTimeLabel: string;
  };
  CONTEXT_MENU: {
    NEW_EVENT: {
      label: string;
    };
    NEW_STAY_ABROAD: {
      label: string;
    };
    EDIT_EVENT: {
      label: string;
    };
    DELETE_EVENT: {
      label: string;
    };
  };
}
