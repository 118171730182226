export namespace ProjectEditAppointmentActions {
    export class LoadAppointments {
        static readonly type = '[ProjectEdit] Load Appointments';
        constructor(
            public projectId: string, 
            public roleId: string, 
            public from: Date, 
            public to: Date
        ) { }
    }


    export class LoadAppointmentsOfModel {
        static readonly type = '[ProjectEdit] Load Appointments Of Model';
        constructor(
            public projectId: string, 
            public roleId: string, 
            public modelId: string,
            public from: Date, 
            public to: Date
        ) { }
    }

    export class ChangeAppointment {
        static readonly type = '[ProjectEdit] Change Appointment';
        constructor(
            public modelId: string,
            public appointmentId: string,
            public roleId: string,
            public appointment: {
                from: string,
                to: string,
                type: string,
                title: string,
                description: string
            }
        ) { }
    }

    export class AddProjectRelatedAppointment {
        static readonly type = '[ProjectEdit] Add Project Related Appointment';
        constructor(
            public projectId: string,
            public appointment: {
                modelId: string,
                type: string,
                title: string,
                description: string,
                from: string,
                to: string,
                language: string
            }
        ) { }
    }

    export class DeleteAppointment {
        static readonly type = '[ProjectEdit] Delete Appointment';
        constructor(
            public modelId: string,
            public appointmentId: string,
            public roleId: string
        ) { }
    }

    export class AddDeclinedAppointment {
        static readonly type = '[ProjectEdit] Add Declined Appointment';
        constructor(
            public projectId: string,
            public appointment: {
                modelId: string,
                type: string,
                title: string,
                description: string,
                from: string,
                to: string,
                language: string
            }
        ) { }
    }

    export class AddRequestTimeframe {
        static readonly type = '[ProjectEdit] Add Request Timeframe';
        constructor(
            public projectId: string,
            public appointment: {
                modelId: string,
                type: string,
                title: string,
                description: string,
                from: string,
                to: string,
                language: string
            }
        ) { }
    }

    export class CreateRoleRequestTimeFrame {
        static readonly type = '[ProjectEdit] Create Role Request Timeframe';
        constructor(
            public projectId: string,
            public roleId: string,
            public appointment: {
                title: string,
                description: string,
                language: string,
                date: string,
                wdh: number,
                projectRoleId: string
            }
        ) { }
    }

    export class DeleteRoleRequestTimeFrame {
        static readonly type = '[ProjectEdit] Delete Role Request Timeframe';
        constructor(
            public command: {
                projectId: string,
                roleId: string,
                date: string
            }
        ) { }
    }

}