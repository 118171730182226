import { ProjectProducerFormValueDto } from "libs/api-typescript-angular/src/model/projectProducerFormValueDto";



export namespace ProjectEditProducerActions {
    export class UpdateProducerSelectedEmployee {
        static readonly type = '[ProjectEdit] Update Producer Selected Employee';
        constructor(public employeeName: string) { }
    }

    export class UpdateProducer {
        static readonly type = '[ProjectEdit] Update Producer';
        constructor(public producer: ProjectProducerFormValueDto) { }
    }
}