/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentCreator } from './AppointmentCreator';
import {
    AppointmentCreatorFromJSON,
    AppointmentCreatorFromJSONTyped,
    AppointmentCreatorToJSON,
} from './AppointmentCreator';

/**
 * 
 * @export
 * @interface AppointmentDto
 */
export interface AppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    appointmentId: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    statusEnum: AppointmentDtoStatusEnumEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    typeEnum: AppointmentDtoTypeEnumEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    to: string;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    projectId?: string;
    /**
     * 
     * @type {AppointmentCreator}
     * @memberof AppointmentDto
     */
    creator?: AppointmentCreator;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentDto
     */
    answeredAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    agencyId?: string;
}


/**
 * @export
 */
export const AppointmentDtoStatusEnumEnum = {
    Requested: 'REQUESTED',
    Maybe: 'MAYBE',
    Confirmed: 'CONFIRMED',
    Canceled: 'CANCELED'
} as const;
export type AppointmentDtoStatusEnumEnum = typeof AppointmentDtoStatusEnumEnum[keyof typeof AppointmentDtoStatusEnumEnum];

/**
 * @export
 */
export const AppointmentDtoTypeEnumEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED',
    StayAbroad: 'STAY_ABROAD'
} as const;
export type AppointmentDtoTypeEnumEnum = typeof AppointmentDtoTypeEnumEnum[keyof typeof AppointmentDtoTypeEnumEnum];


/**
 * Check if a given object implements the AppointmentDto interface.
 */
export function instanceOfAppointmentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "appointmentId" in value;
    isInstance = isInstance && "modelId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "statusEnum" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "typeEnum" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function AppointmentDtoFromJSON(json: any): AppointmentDto {
    return AppointmentDtoFromJSONTyped(json, false);
}

export function AppointmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': json['appointmentId'],
        'modelId': json['modelId'],
        'type': json['type'],
        'statusEnum': json['statusEnum'],
        'status': json['status'],
        'typeEnum': json['typeEnum'],
        'title': json['title'],
        'description': json['description'],
        'from': json['from'],
        'to': json['to'],
        'createdAt': (new Date(json['createdAt'])),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'creator': !exists(json, 'creator') ? undefined : AppointmentCreatorFromJSON(json['creator']),
        'answeredAt': !exists(json, 'answeredAt') ? undefined : (new Date(json['answeredAt'])),
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
    };
}

export function AppointmentDtoToJSON(value?: AppointmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'modelId': value.modelId,
        'type': value.type,
        'statusEnum': value.statusEnum,
        'status': value.status,
        'typeEnum': value.typeEnum,
        'title': value.title,
        'description': value.description,
        'from': value.from,
        'to': value.to,
        'createdAt': (value.createdAt.toISOString()),
        'projectId': value.projectId,
        'creator': AppointmentCreatorToJSON(value.creator),
        'answeredAt': value.answeredAt === undefined ? undefined : (value.answeredAt.toISOString()),
        'agencyId': value.agencyId,
    };
}

