/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleWithModels } from './ProjectRoleWithModels';
import {
    ProjectRoleWithModelsFromJSON,
    ProjectRoleWithModelsFromJSONTyped,
    ProjectRoleWithModelsToJSON,
} from './ProjectRoleWithModels';

/**
 * 
 * @export
 * @interface ProjectRolesWithModelsDto
 */
export interface ProjectRolesWithModelsDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRolesWithModelsDto
     */
    selectedRoleId?: string;
    /**
     * 
     * @type {Array<ProjectRoleWithModels>}
     * @memberof ProjectRolesWithModelsDto
     */
    projectRoleWithModels?: Array<ProjectRoleWithModels>;
}

/**
 * Check if a given object implements the ProjectRolesWithModelsDto interface.
 */
export function instanceOfProjectRolesWithModelsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectRolesWithModelsDtoFromJSON(json: any): ProjectRolesWithModelsDto {
    return ProjectRolesWithModelsDtoFromJSONTyped(json, false);
}

export function ProjectRolesWithModelsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRolesWithModelsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectedRoleId': !exists(json, 'selectedRoleId') ? undefined : json['selectedRoleId'],
        'projectRoleWithModels': !exists(json, 'projectRoleWithModels') ? undefined : ((json['projectRoleWithModels'] as Array<any>).map(ProjectRoleWithModelsFromJSON)),
    };
}

export function ProjectRolesWithModelsDtoToJSON(value?: ProjectRolesWithModelsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectedRoleId': value.selectedRoleId,
        'projectRoleWithModels': value.projectRoleWithModels === undefined ? undefined : ((value.projectRoleWithModels as Array<any>).map(ProjectRoleWithModelsToJSON)),
    };
}

