/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminValue } from './AdminValue';
import {
    AdminValueFromJSON,
    AdminValueFromJSONTyped,
    AdminValueToJSON,
} from './AdminValue';

/**
 * 
 * @export
 * @interface AddOptionsToRequestedTimeframesResponseDtoItem
 */
export interface AddOptionsToRequestedTimeframesResponseDtoItem {
    /**
     * 
     * @type {string}
     * @memberof AddOptionsToRequestedTimeframesResponseDtoItem
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOptionsToRequestedTimeframesResponseDtoItem
     */
    appointmentType?: AddOptionsToRequestedTimeframesResponseDtoItemAppointmentTypeEnum;
    /**
     * 
     * @type {AdminValue}
     * @memberof AddOptionsToRequestedTimeframesResponseDtoItem
     */
    requestTimeFrameOption?: AdminValue;
    /**
     * 
     * @type {string}
     * @memberof AddOptionsToRequestedTimeframesResponseDtoItem
     */
    status?: AddOptionsToRequestedTimeframesResponseDtoItemStatusEnum;
}


/**
 * @export
 */
export const AddOptionsToRequestedTimeframesResponseDtoItemAppointmentTypeEnum = {
    FirstOption: 'FIRST_OPTION',
    SecondOption: 'SECOND_OPTION',
    ThirdOption: 'THIRD_OPTION',
    OtherFirstOption: 'OTHER_FIRST_OPTION',
    OtherSecondOption: 'OTHER_SECOND_OPTION',
    OtherThirdOption: 'OTHER_THIRD_OPTION',
    Production: 'PRODUCTION',
    OtherProduction: 'OTHER_PRODUCTION',
    FirstForeignOption: 'FIRST_FOREIGN_OPTION',
    SecondForeignOption: 'SECOND_FOREIGN_OPTION',
    ThirdForeignOption: 'THIRD_FOREIGN_OPTION',
    ArrivalDeparture: 'ARRIVAL_DEPARTURE',
    Sick: 'SICK',
    ExternalBooked: 'EXTERNAL_BOOKED',
    Holiday: 'HOLIDAY',
    Different: 'DIFFERENT',
    Pregnancy: 'PREGNANCY',
    RequestTimeframe: 'REQUEST_TIMEFRAME',
    ModelDeclined: 'MODEL_DECLINED',
    StayAbroad: 'STAY_ABROAD'
} as const;
export type AddOptionsToRequestedTimeframesResponseDtoItemAppointmentTypeEnum = typeof AddOptionsToRequestedTimeframesResponseDtoItemAppointmentTypeEnum[keyof typeof AddOptionsToRequestedTimeframesResponseDtoItemAppointmentTypeEnum];

/**
 * @export
 */
export const AddOptionsToRequestedTimeframesResponseDtoItemStatusEnum = {
    Requested: 'REQUESTED',
    Maybe: 'MAYBE',
    Confirmed: 'CONFIRMED',
    Canceled: 'CANCELED'
} as const;
export type AddOptionsToRequestedTimeframesResponseDtoItemStatusEnum = typeof AddOptionsToRequestedTimeframesResponseDtoItemStatusEnum[keyof typeof AddOptionsToRequestedTimeframesResponseDtoItemStatusEnum];


/**
 * Check if a given object implements the AddOptionsToRequestedTimeframesResponseDtoItem interface.
 */
export function instanceOfAddOptionsToRequestedTimeframesResponseDtoItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddOptionsToRequestedTimeframesResponseDtoItemFromJSON(json: any): AddOptionsToRequestedTimeframesResponseDtoItem {
    return AddOptionsToRequestedTimeframesResponseDtoItemFromJSONTyped(json, false);
}

export function AddOptionsToRequestedTimeframesResponseDtoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOptionsToRequestedTimeframesResponseDtoItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': !exists(json, 'appointmentId') ? undefined : json['appointmentId'],
        'appointmentType': !exists(json, 'appointmentType') ? undefined : json['appointmentType'],
        'requestTimeFrameOption': !exists(json, 'requestTimeFrameOption') ? undefined : AdminValueFromJSON(json['requestTimeFrameOption']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function AddOptionsToRequestedTimeframesResponseDtoItemToJSON(value?: AddOptionsToRequestedTimeframesResponseDtoItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'appointmentType': value.appointmentType,
        'requestTimeFrameOption': AdminValueToJSON(value.requestTimeFrameOption),
        'status': value.status,
    };
}

