/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelAgencyFormValuesBookerDto } from './ModelAgencyFormValuesBookerDto';
import {
    ModelAgencyFormValuesBookerDtoFromJSON,
    ModelAgencyFormValuesBookerDtoFromJSONTyped,
    ModelAgencyFormValuesBookerDtoToJSON,
} from './ModelAgencyFormValuesBookerDto';

/**
 * 
 * @export
 * @interface ModelAgencyFormValuesDto
 */
export interface ModelAgencyFormValuesDto {
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    agencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    bookerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    bookerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    bookerLastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAgencyFormValuesDto
     */
    onStay?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAgencyFormValuesDto
     */
    contactPerson?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgencyFormValuesDto
     */
    endDate?: string;
    /**
     * 
     * @type {Array<ModelAgencyFormValuesBookerDto>}
     * @memberof ModelAgencyFormValuesDto
     */
    modelAgencyFormValuesBookersDto?: Array<ModelAgencyFormValuesBookerDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAgencyFormValuesDto
     */
    isCommission?: boolean;
}

/**
 * Check if a given object implements the ModelAgencyFormValuesDto interface.
 */
export function instanceOfModelAgencyFormValuesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelAgencyFormValuesDtoFromJSON(json: any): ModelAgencyFormValuesDto {
    return ModelAgencyFormValuesDtoFromJSONTyped(json, false);
}

export function ModelAgencyFormValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelAgencyFormValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
        'agencyName': !exists(json, 'agencyName') ? undefined : json['agencyName'],
        'bookerId': !exists(json, 'bookerId') ? undefined : json['bookerId'],
        'bookerFirstName': !exists(json, 'bookerFirstName') ? undefined : json['bookerFirstName'],
        'bookerLastName': !exists(json, 'bookerLastName') ? undefined : json['bookerLastName'],
        'onStay': !exists(json, 'onStay') ? undefined : json['onStay'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'beginDate': !exists(json, 'beginDate') ? undefined : json['beginDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'modelAgencyFormValuesBookersDto': !exists(json, 'modelAgencyFormValuesBookersDto') ? undefined : ((json['modelAgencyFormValuesBookersDto'] as Array<any>).map(ModelAgencyFormValuesBookerDtoFromJSON)),
        'isCommission': !exists(json, 'isCommission') ? undefined : json['isCommission'],
    };
}

export function ModelAgencyFormValuesDtoToJSON(value?: ModelAgencyFormValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': value.agencyId,
        'agencyName': value.agencyName,
        'bookerId': value.bookerId,
        'bookerFirstName': value.bookerFirstName,
        'bookerLastName': value.bookerLastName,
        'onStay': value.onStay,
        'contactPerson': value.contactPerson,
        'status': value.status,
        'beginDate': value.beginDate,
        'endDate': value.endDate,
        'modelAgencyFormValuesBookersDto': value.modelAgencyFormValuesBookersDto === undefined ? undefined : ((value.modelAgencyFormValuesBookersDto as Array<any>).map(ModelAgencyFormValuesBookerDtoToJSON)),
        'isCommission': value.isCommission,
    };
}

