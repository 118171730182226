/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface ConversationMessagesDto
 */
export interface ConversationMessagesDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    conversationId?: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ConversationMessagesDto
     */
    newMessages?: Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof ConversationMessagesDto
     */
    totalNumberOfMessages?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    participantOauthId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessagesDto
     */
    otherParticipantName?: string;
}

/**
 * Check if a given object implements the ConversationMessagesDto interface.
 */
export function instanceOfConversationMessagesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationMessagesDtoFromJSON(json: any): ConversationMessagesDto {
    return ConversationMessagesDtoFromJSONTyped(json, false);
}

export function ConversationMessagesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationMessagesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversationId': !exists(json, 'conversationId') ? undefined : json['conversationId'],
        'newMessages': !exists(json, 'newMessages') ? undefined : ((json['newMessages'] as Array<any>).map(MessageFromJSON)),
        'totalNumberOfMessages': !exists(json, 'totalNumberOfMessages') ? undefined : json['totalNumberOfMessages'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'participantOauthId': !exists(json, 'participantOauthId') ? undefined : json['participantOauthId'],
        'otherParticipantName': !exists(json, 'otherParticipantName') ? undefined : json['otherParticipantName'],
    };
}

export function ConversationMessagesDtoToJSON(value?: ConversationMessagesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversationId': value.conversationId,
        'newMessages': value.newMessages === undefined ? undefined : ((value.newMessages as Array<any>).map(MessageToJSON)),
        'totalNumberOfMessages': value.totalNumberOfMessages,
        'projectId': value.projectId,
        'roleId': value.roleId,
        'title': value.title,
        'participantOauthId': value.participantOauthId,
        'otherParticipantName': value.otherParticipantName,
    };
}

