import { ProjectAdditionalParticipantFormValuesDto } from 'libs/api-typescript-angular/src/model/projectAdditionalParticipantFormValuesDto';
import { AddAdditionalParticipantRequestDto } from 'libs/api-typescript-angular/src/model/addAdditionalParticipantRequestDto';

export namespace ProjectEditAdditionalParticipantsActions {
    export class LoadAdditionalParticipants {
        static readonly type = '[ProjectEdit] Load Additional Participants';
        constructor() {}
    }

    export class AddAdditionalParticipant {
        static readonly type = '[ProjectEdit] Add Additional Participant';
        constructor(public payload: {
            projectId: string,
            participant: AddAdditionalParticipantRequestDto
        }) {}
    }

    export class RemoveAdditionalParticipant {
        static readonly type = '[ProjectEdit] Remove Additional Participant';
        constructor(public participantId: string) {}
    }

    // export class UpdateAdditionalParticipant {
    //     static readonly type = '[ProjectEdit] Update Additional Participant';
    //     constructor(
    //         public participantId: string, 
    //         public participant: ProjectAdditionalParticipantFormValuesDto
    //     ) {}
    // }

    export class ChangeAdminRights {
        static readonly type = '[ProjectEdit] Change Additional Participant Admin Rights';
        constructor(
            public projectId: string,
            public employeeId: string
        ) {}
    }

    export class DeleteAdditionalParticipant {
        static readonly type = '[ProjectEdit] Delete Additional Participant';
        constructor(
            public projectId: string,
            public employeeId: string
        ) {}
    }
} 