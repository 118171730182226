import { AddExternalParticipantRequestDto } from "libs/api-typescript-angular/src/model/addExternalParticipantRequestDto";

export namespace ProjectEditExternalParticipantsActions {
    export class LoadExternalParticipants {
      static readonly type = '[ProjectEdit] Load External Participants';
      constructor() {}
    }
  
    export class AddExternalParticipant {
      static readonly type = '[ProjectEdit] Add External Participant';
      constructor(
        public projectId: string, 
        public participant: AddExternalParticipantRequestDto
      ) {}
    }
  
    export class DeleteExternalParticipant {
      static readonly type = '[ProjectEdit] Delete External Participant';
      constructor(
        public projectId: string,
        public participantId: string
      ) {}
    }
  
    export class ChangeAdminRights {
      static readonly type = '[ProjectEdit] Change External Participant Admin Rights';
      constructor(
        public projectId: string,
        public participantId: string
      ) {}
    }
  }