import { Injectable } from '@angular/core';
import {ErrorModalComponent} from "../components/errorModal/error.modal.component";
import {ErrorModalService} from "./errorModal.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(private errorModalService: ErrorModalService) {
  }

  public handleError(message: string): void {
    // Show error modal
    this.errorModalService.showErrorModal(message);
  }
}
