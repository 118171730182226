/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookerForMessagesDto } from './BookerForMessagesDto';
import {
    BookerForMessagesDtoFromJSON,
    BookerForMessagesDtoFromJSONTyped,
    BookerForMessagesDtoToJSON,
} from './BookerForMessagesDto';
import type { ConversationInfo } from './ConversationInfo';
import {
    ConversationInfoFromJSON,
    ConversationInfoFromJSONTyped,
    ConversationInfoToJSON,
} from './ConversationInfo';
import type { ModelProjectDataForConversations } from './ModelProjectDataForConversations';
import {
    ModelProjectDataForConversationsFromJSON,
    ModelProjectDataForConversationsFromJSONTyped,
    ModelProjectDataForConversationsToJSON,
} from './ModelProjectDataForConversations';

/**
 * 
 * @export
 * @interface ConversationsResponseModelsDto
 */
export interface ConversationsResponseModelsDto {
    /**
     * 
     * @type {Array<ConversationInfo>}
     * @memberof ConversationsResponseModelsDto
     */
    directConversations?: Array<ConversationInfo>;
    /**
     * 
     * @type {Array<ModelProjectDataForConversations>}
     * @memberof ConversationsResponseModelsDto
     */
    projectConversations?: Array<ModelProjectDataForConversations>;
    /**
     * 
     * @type {Array<BookerForMessagesDto>}
     * @memberof ConversationsResponseModelsDto
     */
    availableBookers?: Array<BookerForMessagesDto>;
}

/**
 * Check if a given object implements the ConversationsResponseModelsDto interface.
 */
export function instanceOfConversationsResponseModelsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConversationsResponseModelsDtoFromJSON(json: any): ConversationsResponseModelsDto {
    return ConversationsResponseModelsDtoFromJSONTyped(json, false);
}

export function ConversationsResponseModelsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationsResponseModelsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directConversations': !exists(json, 'directConversations') ? undefined : ((json['directConversations'] as Array<any>).map(ConversationInfoFromJSON)),
        'projectConversations': !exists(json, 'projectConversations') ? undefined : ((json['projectConversations'] as Array<any>).map(ModelProjectDataForConversationsFromJSON)),
        'availableBookers': !exists(json, 'availableBookers') ? undefined : ((json['availableBookers'] as Array<any>).map(BookerForMessagesDtoFromJSON)),
    };
}

export function ConversationsResponseModelsDtoToJSON(value?: ConversationsResponseModelsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directConversations': value.directConversations === undefined ? undefined : ((value.directConversations as Array<any>).map(ConversationInfoToJSON)),
        'projectConversations': value.projectConversations === undefined ? undefined : ((value.projectConversations as Array<any>).map(ModelProjectDataForConversationsToJSON)),
        'availableBookers': value.availableBookers === undefined ? undefined : ((value.availableBookers as Array<any>).map(BookerForMessagesDtoToJSON)),
    };
}

