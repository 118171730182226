/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelMasterDataSearchItemDto
 */
export interface ModelMasterDataSearchItemDto {
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchItemDto
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchItemDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchItemDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMasterDataSearchItemDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelMasterDataSearchItemDto
     */
    additionalImageUrls?: Array<string>;
}

/**
 * Check if a given object implements the ModelMasterDataSearchItemDto interface.
 */
export function instanceOfModelMasterDataSearchItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelId" in value;

    return isInstance;
}

export function ModelMasterDataSearchItemDtoFromJSON(json: any): ModelMasterDataSearchItemDto {
    return ModelMasterDataSearchItemDtoFromJSONTyped(json, false);
}

export function ModelMasterDataSearchItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMasterDataSearchItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelId': json['modelId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'additionalImageUrls': !exists(json, 'additionalImageUrls') ? undefined : json['additionalImageUrls'],
    };
}

export function ModelMasterDataSearchItemDtoToJSON(value?: ModelMasterDataSearchItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelId': value.modelId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'imageUrl': value.imageUrl,
        'additionalImageUrls': value.additionalImageUrls,
    };
}

